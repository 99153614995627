import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";
import Redacted from "../../Redacted";

export const ProfilesTableTop10 = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	const [ProfilesData, setProfilesData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get styles data
				const profilesDataResponse = await axios.get(`${api_url}/profiles/`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
					    limit: 10,
					}
				});

				setProfilesData(profilesDataResponse.data.data);

				setWidgetState('done');
				
			} catch (e) {
        		console.error('error', e);

        		setProfilesData([]);
        		
        		setWidgetState('error');
      		}

		})();		

	}, [
		getAccessTokenSilently, 
	]);


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">
							{/*
							!!!Single profile icon - for use somewhere else!!!
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
    							<path d="M474.93 422.7c-.43-4.39-1.18-8.74-2-11.49-19.95-92.52-79.61-163.08-154.19-188.91 33.12-20.34 55.21-56.48 55.21-97.59 0-63.44-52.55-115.04-117.14-115.04-64.6 0-117.15 51.6-117.15 115.04 0 40.77 21.72 76.66 54.4 97.09-74.8 25.37-134.67 95.95-154.35 187.93-10.61 38.83 12.33 79.07 51.23 89.75 4.19 1.15 8.45 1.9 15.05 2.39l300.34.13c.72 0 1.44-.03 2.19-.1 40.11-3.47 69.91-38.96 66.41-79.2zM189.95 124.71c0-35.71 29.99-64.75 66.86-64.75 36.85 0 66.84 29.04 66.84 64.75 0 35.69-29.99 64.73-66.84 64.73-36.86 0-66.86-29.04-66.86-64.73zm215.1 327-296.78-.03c-1.42-.13-2.83-.36-4.11-.72-12.1-3.31-19.24-15.85-15.6-29.3 20.15-94.07 88.81-159.77 166.97-159.77 78.76 0 147.95 65.76 168.61 161.47.36 1.28.59 2.62.69 3.83 1.05 12.17-7.76 23.01-19.78 24.52z" />
  							</svg>
							*/}
  							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
  							   <path d="M254.74 235.73c36.81 0 66.74-29.37 66.74-65.45s-29.93-65.44-66.74-65.44c-36.81 0-66.74 29.36-66.74 65.44s29.93 65.45 66.74 65.45zm0-109.16c24.81 0 45 19.6 45 43.71s-20.19 43.72-45 43.72-45-19.61-45-43.72 20.18-43.71 45-43.71z" />
  							   <path d="M493.09 342.67c-11.66-28.36-36.48-48.87-66-55.51 24.57-5.09 43.08-26.61 43.08-52.33 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 23.69 15.71 43.82 37.41 50.82a84.468 84.468 0 0 0-39.84 16.22c-24.34-38.38-62.91-62.37-105.31-62.37-40.98 0-78.4 22.75-102.65 59.2-11.48-7.31-24.59-11.87-38.22-13.28 21.33-7.2 36.71-27.15 36.71-50.59 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 26 18.91 47.71 43.89 52.48-29.23 6.72-54.18 27.1-66.24 55.31-4.48 10.72-4.52 22.54-.11 33.28s12.76 19.11 23.5 23.59c5.35 2.22 10.97 3.34 16.43 3.34.51 0 1.04-.01 1.55-.03h80.62c1.65 0 3.2-.38 4.6-1.03 2.93 1.7 6.08 3.03 9.41 3.96 2.38.66 4.82 1.1 8.19 1.35l182.34.07c.32 0 .64-.01.93-.04 6.89-.6 13.22-2.92 18.61-6.5 1.82 1.37 4.07 2.19 6.53 2.19h81.36c5.71 0 11.48-1.15 16.73-3.34 22.09-9.21 32.59-34.72 23.4-56.79zM383.43 234.84c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73c-17.91 0-32.5-14.23-32.5-31.73zm-320.37 0c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73-32.5-14.23-32.5-31.73zm-3.12 146.25c-3.16.19-6.28-.44-9.23-1.66-5.35-2.24-9.53-6.42-11.74-11.79-2.21-5.37-2.19-11.28.02-16.55 11.14-26.1 36.7-43.53 64.81-44.42 12.77.08 25.85 3.81 36.72 10.94-5.81 12.01-10.34 25.07-13.33 38.97-2.28 8.18-1.89 16.68 1 24.49l-68.25.02zm285.03 4.34-180.89-.03c-1.1-.1-2.19-.29-3.27-.59-4.69-1.29-8.6-4.34-10.97-8.57-2.4-4.23-3.01-9.15-1.57-14.46 12.78-59.2 56.23-100.54 105.69-100.54 49.9 0 93.67 41.37 106.6 101.28.3 1.04.51 2.11.59 3.22.84 9.87-6.37 18.62-16.18 19.69zm116.32-6.02c-2.74 1.14-5.79 1.58-8.87 1.67h-71.93a39.601 39.601 0 0 0 2.31-17.18c-.21-2.46-.64-4.91-1.15-6.63-2.75-12.77-6.78-24.82-11.9-36 11.28-9.36 25.32-14.53 39.68-14.61 27.96 1.02 52.9 18.39 63.55 44.33 4.62 11.05-.63 23.81-11.69 28.42z" />
  							 </svg>
						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Profiles top 10</h2>	
		        		<div className="widget-v3-subtitle">By key metrics</div>
		        	</div>
		            
		            
		        	<div className="widget-v3-header-actions">
		        		
		        	</div>
		        	
		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="widget-style-table">

								<div className="widget-style-table-inner">

									<table className="style-table-table">
										<thead>
											<tr className="style-table-header">
												<th colSpan="2">Name</th>
												<th>Email</th>
												<th>Spent</th>
												<th>Total Discount</th>
												<th>Orders</th>
												{/*<th>Created date</th>*/}
												<th></th>
											</tr>
										</thead>
										<tbody>
											
										  	{
										  		ProfilesData.map((profile, i) => {
										  			
										  			const spent = profile.spent / 100 || 0;
										  			const subtotal_spent = profile.subtotal_spent / 100 || 0;
										  			const total_discount = (profile.subtotal_spent - profile.spent) / 100 || 0;

										  			return (
									  					<tr key={i}>
									  				    	<td className="style-table-field style-table-field-icon-field">
									  				    		
									  				    		<div className="style-table-field-icon">
									  					    		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
									  					    		    <g id="Layer_2">
									  					    		      <path d="M281.51 254.88c29.21-10.49 50.15-38.46 50.15-71.24 0-41.73-33.94-75.68-75.67-75.68s-75.69 33.95-75.69 75.68c0 32.96 21.2 61.06 50.67 71.41-46.7 8.89-85.96 42.3-101.77 89.16-7.21 24.74 7.02 50.75 31.76 57.97 4.29 1.26 8.72 1.89 12.68 1.89.53 0 1.07-.01 1.58-.03H337.6c.49.01.97.02 1.48.02 24.9 0 45.65-19.75 46.67-44.92.21-5.1-.42-10.12-2.04-15.64-15.76-46.66-55.24-80.01-102.2-88.62zm-65.59-71.24c0-22.09 17.99-40.07 40.08-40.07s40.06 17.98 40.06 40.07-17.97 40.07-40.06 40.07-40.08-17.98-40.08-40.07zm123.14 184.81c-.37 0-.81-.02-1.16-.02l-163.39.01c-1.18.09-2.39-.09-3.57-.43-5.89-1.73-9.27-7.93-7.77-13.11 13.45-39.85 50.77-66.62 93.01-66.62.32 0 .67-.01 1.02-.01 42.08 0 79.28 26.66 92.54 65.92.32 1.15.49 2.36.44 3.57-.24 5.98-5.18 10.69-11.12 10.69z" />
									  					    		      <path d="M445.91.81H66.09C30.09.81.81 30.09.81 66.09v379.82c0 36 29.28 65.28 65.28 65.28h379.82c36 0 65.28-29.28 65.28-65.28V66.09c0-36-29.28-65.28-65.28-65.28zm29.68 445.1c0 16.37-13.31 29.67-29.67 29.67H66.09c-16.37 0-29.67-13.31-29.67-29.67V66.09c0-16.37 13.31-29.67 29.67-29.67h379.82c16.37 0 29.67 13.31 29.67 29.67v379.82z" />
									  					    		    </g>
									  					    		</svg>
									  					    	</div>
									  				    	
									  				    	</td>
									  				    	<td className="style-table-field">
									  				    		<div className="style-table-field-label">
									  				    			{
									  				    				profile.first_name === 'REDACTED' ? (
									  				    					<Redacted/>
									  				    				) : (
									  				    					<div>{profile.first_name} {profile.last_name}</div>
									  				    				)
									  				    			}

									  				    		</div>
									  				    	</td>
									  				    	<td className="style-table-field">
									  				    		<div className="style-table-field-label">
									  				    			{
									  				    				profile.email === 'REDACTED' ? (
									  				    					<Redacted type="email"/>
									  				    				) : (
									  				    					<div>{profile.email}</div>
									  				    				)
									  				    			}
									  				    		</div>
									  				    	</td>
									  				    	<td className="style-table-field">
									  				    		<div className="style-table-field-label">{spent.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</div>
									  				    	</td>
									  				    	<td className="style-table-field">
									  				    		<div className="style-table-field-label">{total_discount.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</div>
									  				    	</td>
									  				    	<td className="style-table-field">
									  				    		<div className="style-table-field-label">{profile.orders}</div>
									  				    	</td>										    	

									  				    {/*
									  				    	<td className="style-table-field">
									  				    		<div className="style-table-field-label">22 April 2021</div>
									  				    	</td>
									  				    */}

									  				    	<td className="style-table-field no-stretch">
									  				    		<Link to={`/marketing/profiles/${profile._id}`} className="style-table-button">Go to profile</Link>
									  				    	</td>
									  				  	</tr>
										  			)
										  		})
										  	}

										  		
										  

										  	{/*	
										  	<tr key="SAMPLE-ROW">
										    	<td className="style-table-field style-table-field-icon-field">
										    		
										    		<div className="style-table-field-icon">
											    		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
											    		    <g id="Layer_2">
											    		      <path d="M281.51 254.88c29.21-10.49 50.15-38.46 50.15-71.24 0-41.73-33.94-75.68-75.67-75.68s-75.69 33.95-75.69 75.68c0 32.96 21.2 61.06 50.67 71.41-46.7 8.89-85.96 42.3-101.77 89.16-7.21 24.74 7.02 50.75 31.76 57.97 4.29 1.26 8.72 1.89 12.68 1.89.53 0 1.07-.01 1.58-.03H337.6c.49.01.97.02 1.48.02 24.9 0 45.65-19.75 46.67-44.92.21-5.1-.42-10.12-2.04-15.64-15.76-46.66-55.24-80.01-102.2-88.62zm-65.59-71.24c0-22.09 17.99-40.07 40.08-40.07s40.06 17.98 40.06 40.07-17.97 40.07-40.06 40.07-40.08-17.98-40.08-40.07zm123.14 184.81c-.37 0-.81-.02-1.16-.02l-163.39.01c-1.18.09-2.39-.09-3.57-.43-5.89-1.73-9.27-7.93-7.77-13.11 13.45-39.85 50.77-66.62 93.01-66.62.32 0 .67-.01 1.02-.01 42.08 0 79.28 26.66 92.54 65.92.32 1.15.49 2.36.44 3.57-.24 5.98-5.18 10.69-11.12 10.69z" />
											    		      <path d="M445.91.81H66.09C30.09.81.81 30.09.81 66.09v379.82c0 36 29.28 65.28 65.28 65.28h379.82c36 0 65.28-29.28 65.28-65.28V66.09c0-36-29.28-65.28-65.28-65.28zm29.68 445.1c0 16.37-13.31 29.67-29.67 29.67H66.09c-16.37 0-29.67-13.31-29.67-29.67V66.09c0-16.37 13.31-29.67 29.67-29.67h379.82c16.37 0 29.67 13.31 29.67 29.67v379.82z" />
											    		    </g>
											    		</svg>
											    	</div>
										    	
										    	</td>
										    	<td className="style-table-field">
										    		<div className="style-table-field-label">Malthe M</div>
										    	</td>
										    	<td className="style-table-field">
										    		<div className="style-table-field-label">malthe@milthers.dk</div>
										    	</td>
										    	<td className="style-table-field">
										    		<div className="style-table-field-label">4.804 DKK</div>
										    	</td>

										    	<td className="style-table-field">
										    		<div className="style-table-field-main-label-container">
											    		<div className="style-table-field-main-label">180.114 DKK</div>
											    		<div className="style-table-tag style-table-tag--positive">+23%</div>
											    	</div>
											    	<div className="style-table-field-secondary-label-container">
											    		<div className="style-table-field-secondary-label">Up from 113.201,21 DKK</div>
											    	</div>
										    	</td>  

										    	<td className="style-table-field">
										    		<div className="style-table-field-label">22 April 2021</div>
										    	</td>

										    	<td className="style-table-field">
										    		<div className="style-table-button">Go to profile</div>
										    	</td>
										  	</tr>
										  	*/}	
										</tbody>
									</table>

								</div>


							</div>

						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
			        	
			        	<div></div>
						{/*
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All channels</li>
									<li className="widget-v3-footer-filter">OnlinePOS</li>
									<li className="widget-v3-footer-filter">HeapsGO</li>
					        	</ul>
							) 
						}

						*/}


						<Link to="/marketing/profiles">
			        		<div className="widget-v3-footer-link">
				        		<div className="widget-v3-footer-link-label">All Profiles</div>
				        		<div className="widget-v3-footer-link-icon">
					        		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
					        		    <path d="m166.48 502.4-67.32-67.32L278.2 255.99 99.16 76.92 166.48 9.6l246.36 246.39z" />
					        		</svg>
				        		</div>
			        		</div>
			        	</Link>			

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesTableTop10;