import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

export const RolfStats = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({		
		product_stats: [],
		waste_count: 0,
		orderyoyo_count: 0,
		togoodtogo_count: 0,
		staff_food_count: 0,
		staff_food_cost: 0,

		// Revenue metrics
		orderCountOrderYOYO: 0,
		orderCountWolt: 0,
	});

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);


	useEffect(() => {
		calculateWasteCount();
	}, [widgetState.product_stats]);

	
	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		const aggregatedProductsPromise = loadAggregatedProducts();

		const aggregatedRevenuePromise = loadAggregatedRevenue();

		Promise.all([aggregatedProductsPromise, aggregatedRevenuePromise])
			.then( values => {

				setWidgetState({
					...widgetState,
					product_stats: values[0].data.products,
					orderCountOrderYOYO: !isNaN(values[1].data.totals.order_count_orderyoyo) ? values[1].data.totals.order_count_orderyoyo : 0,
					orderCountWolt: !isNaN(values[1].data.totals.order_count_wolt) ? values[1].data.totals.order_count_wolt : 0,
					
				})

				setIsLoading(false);

			})
			.catch(error => {
				setWidgetState({ 
					...widgetState,
					product_stats: [],
					waste_count: 0,
					orderyoyo_count: 0,
					togoodtogo_count: 0,
					staff_food_count: 0,
					staff_food_cost: 0,

					// Revenue metrics
					orderCountOrderYOYO: 0,
					orderCountWolt: 0,
				})
				setIsLoading(true);
				console.log(error);
			}
		);

	}


	/**
	 * Load sales data
	 */
	const loadAggregatedProducts = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/products`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;
	}

	/**
	 * Load sales data
	 */
	const loadAggregatedRevenue = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// const frequency = getFrequency();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/revenue/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: 'year', // hour, day, month or year
				compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
                compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;		
	}


	/**
	 * Calculate total waste count
	 */
	const calculateWasteCount = () => {
	

		const product_stats = widgetState.product_stats;

		// Waste count
		const waste = product_stats.find(product => product.product_name === 'SPILD'); 
		const waste_count = waste ? waste.item_count : 0;


		// "Medarbejdermad"
		const staff_food = product_stats.find(product => product.product_name === 'Medarbejdermad');
		console.log('staff', staff_food);

		const staff_food_count = staff_food ? staff_food.item_count : 0;

		// "OrderYOYO"
		const orderyoyo = product_stats.find(product => product.product_name === '*OrderYOYO');
		const orderyoyo_count = orderyoyo ? orderyoyo.item_count : 0;

		// "OrderYOYO"
		const wolt = product_stats.find(product => product.product_name === '*Wolt');
		const wolt_count = wolt ? wolt.item_count : 0;

		// "TooGoodToGo"
		const tgtg = product_stats.find(product => product.product_name === '*TooGoodToGo');
		const tgtg_count = tgtg ? tgtg.item_count : 0;

		setWidgetState({
			...widgetState,
			waste_count: waste_count,
			staff_food_count: staff_food_count,
			orderyoyo_count: orderyoyo_count,
			togoodtogo_count: tgtg_count,
			wolt_count: wolt_count,
		});		

	}


	return (
		<div className="widget-waste-stats">
			{
				isLoading ? (
					<div className="waste-stats">
						<div className="spinner-centered">
							<div className="spinner"></div>
						</div>
					</div>
				) : (


					<div className="waste-stats">
						<div className="secondary-stats">
							<div className="stat-line-secondary">
								<div className="stat-line-label">Medarbejdermad</div>
								<div className="stat-line-value">{widgetState.staff_food_count}</div>
							</div>

							<div className="stat-line-secondary">
								<div className="stat-line-label">Antal spild</div>
								<div className="stat-line-value">{widgetState.waste_count}</div>
							</div>
							
						</div>
						

					</div>
				)
			}

		</div>		
	); 
};

export default RolfStats;
