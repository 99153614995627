import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "./Spinner";

export const Drawer = (props) => {

	const [drawerState, setDrawerState] = useState({
		state: 'closed',
		stateClass: 'drawer--closed',
	});

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		console.log(props.drawerState);
		if(props.drawerState == 'open') {
			open();
		}

		if(props.drawerState == 'closed') {
			close();
		}

	}, [
		props.drawerState, 
	]);

	// Stop click events inside drawer
	function elClick(e){
		e.stopPropagation();
	}


	// Close drawer
	function close(){
		
		// 
		setDrawerState({
			state: 'closing',
			stateClass: 'drawer--closing',
		})

		const timer = setTimeout(() => {
			setDrawerState({
				state: 'closed',
				stateClass: 'drawer--closed',
			})
			clearTimeout(timer);
		}, 300);

		props.setDrawerState('closed');

	}

	// Close drawer
	function open(){
		
		setDrawerState({
			state: 'opening',
			stateClass: 'drawer--opening',
		})

		const timer = setTimeout(() => {
			console.log('Initial timeout!');
			setDrawerState({
				state: 'open',
				stateClass: 'drawer--open',
			})
			clearTimeout(timer);
		}, 15);

		props.setDrawerState('open');

	}

	return (
		<div className={`drawer-wrapper ${drawerState.stateClass}`} onClick={ (e) => close(e) }>
			<div className="drawer" onClick={ (e) => elClick(e) }>
			    <div className={`drawer-content`}>
			       {props.children}
			    </div>
			</div>
		</div>
	); 
};

export default Drawer;