import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import Select from 'react-select';
import { ResponsiveHeatMap } from '@nivo/heatmap'


export const HeatmapRevenue = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,

		source_filter: 'all',
		revenue_by_weekday_and_hour: [],

	});


	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * Automatically detect what frequency to use
	 */
	const getFrequency = () => {

		let frequency = '';
		if (props.frequency.value) {
			frequency = props.frequency.value.value;
		}

		return frequency;
	}

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setWidgetState({
			...widgetState,
			source_filter: new_mode
		});
	}

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(false);


		const averageRevenueByWeekdayAndHour = loadAverageRevenueByWeekdayAndHour();

		Promise.all([averageRevenueByWeekdayAndHour])
			.then( values => {

				setWidgetState({ 
					...widgetState,
					error: false,
					revenue_by_weekday_and_hour: values[0].data.data,
				});
				setIsLoading(false);
			})
			.catch(error => {
				setWidgetState({
					...widgetState, 
					error: true,
					revenue_by_weekday_and_hour: [],
				});
				setIsLoading(false);
				console.log(error);
			}
		);

	}

	/**
	 * Load sales data
	 */
	const loadAverageRevenueByWeekdayAndHour = async () => {
	
		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/heatmap/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}


	/**
	 * Prepare the sales data for the timeline
	 */
	const prepareData = () => {

		// const start_date = this.props.start_date;
		// const end_date = this.props.end_date;
		const revenue_by_hour = widgetState.revenue_by_weekday_and_hour;

		// Build all weekdays and hours based on start and end date with revenue at 0.
		let data = [
			{
				"weekday": "Mandag",
				"weekday_number": 1,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
			{
				"weekday": "Tirsdag",
				"weekday_number": 2,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
			{
				"weekday": "Onsdag",
				"weekday_number": 3,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
			{
				"weekday": "Torsdag",
				"weekday_number": 4,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
			{
				"weekday": "Fredag",
				"weekday_number": 5,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
			{
				"weekday": "Lørdag",
				"weekday_number": 6,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
			{
				"weekday": "Søndag",
				"weekday_number": 7,
				"07.00": 0, "08.00": 0, "09.00": 0, "10.00": 0, "11.00": 0, "12.00": 0, "13.00": 0, "14.00": 0, "15.00": 0, "16.00": 0, "17.00": 0, "18.00": 0, "19.00": 0, "20.00": 0, "21.00": 0, "22.00": 0,
				"total": 0,
				"day_count": 0,
			},
		];


		// Loop through data
		for (const entry of revenue_by_hour) {
				
			console.log(entry);

			// Find correct weekday
			const weekday_index = data.findIndex(data_entry => data_entry.weekday_number === entry.weekday_number);

			const hour = entry.hour + ".00";
			
			if (weekday_index !== -1) {
				
				if (widgetState.source_filter === 'all') {
					data[weekday_index][hour] = entry.average_revenue_excl_vat;
				}

				else {
					// Find source
					const source_index = entry.by_source.findIndex(s => s.source.toLowerCase() === widgetState.source_filter );

					if (source_index !== -1) {
						const source_revenue = entry.by_source[source_index].average_revenue_excl_vat;
						data[weekday_index][hour] = source_revenue;
					} else {
						data[weekday_index][hour] = 0;
					}
					
				}
			}
		}


		// Format money value
		data = data.map( entry => {
			return {
				weekday: entry.weekday, 
				weekday_number: entry.weekday_number,  
				"07.00": parseInt(entry["07.00"] / 100), "08.00": parseInt(entry["08.00"] / 100), "09.00": parseInt(entry["09.00"] / 100), "10.00": parseInt(entry["10.00"] / 100), "11.00": parseInt(entry["11.00"] / 100), "12.00": parseInt(entry["12.00"] / 100), "13.00": parseInt(entry["13.00"] / 100), "14.00": parseInt(entry["14.00"] / 100), "15.00": parseInt(entry["15.00"] / 100), "16.00": parseInt(entry["16.00"] / 100), "17.00": parseInt(entry["17.00"] / 100), "18.00": parseInt(entry["18.00"] / 100), "19.00": parseInt(entry["19.00"] / 100), "20.00": parseInt(entry["20.00"] / 100), "21.00": parseInt(entry["21.00"] / 100), "22.00": parseInt(entry["22.00"] / 100)
			}
		})

		console.log('heat', JSON.stringify(data));

		return data;
	}

	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className={`widget-v2 ${loadingClass}`}>
	        <div className="widget-v2-header">
	        				      
      			<Select 
              		className="timeline-selector" 
              		options={props.select.options} 
              		value={props.select.value}
              		onChange={props.select.onChange}
              	/>

	        </div>
			
			<div className="widget-v2-body">
					{
						widgetState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot<span role="img">❤️</span></p>
						)
					}

					<div className="responsive-line-container">
						
						{
							isLoading === true ? (
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							) : (
								<ResponsiveHeatMap
							        data={prepareData()}
							        keys={[
							            '07.00',
							            '08.00',
							            '09.00',
							            '10.00',
							            '11.00',
							            '12.00',
							            '13.00',
							            '14.00',
							            '15.00',
							            '16.00',
							            '17.00',
							            '18.00',
							            '19.00',
							            '20.00',
							            '21.00',
							        ]}
							        colors="blues"
							        indexBy="weekday"
							        margin={{ top: 20, right: 60, bottom: 0, left: 80 }}
							        forceSquare={true}
							        axisTop={{ orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: -45, legend: '', legendOffset: 36 }}
							        axisRight={null}
							        axisBottom={null}
							        axisLeft={{
							            orient: 'left',
							            tickSize: 5,
							            tickPadding: 5,
							            tickRotation: 0,
							            legend: '',
							            legendPosition: 'middle',
							            legendOffset: -40
							        }}
							        cellOpacity={1}
							        cellBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.4 ] ] }}
							        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.8 ] ] }}
							        defs={[
							            {
							                id: 'lines',
							                type: 'patternLines',
							                background: 'inherit',
							                color: 'rgba(0, 0, 0, 0.1)',
							                rotation: -45,
							                lineWidth: 4,
							                spacing: 7
							            }
							        ]}
							        fill={[ { id: 'lines' } ]}
							        animate={true}
							        motionStiffness={80}
							        motionDamping={9}
							        hoverTarget="cell"
							        cellHoverOthersOpacity={0.25}
							    />
							)

						}

						
					</div>	
					
			</div>						        


	        <div className="widget-v2-footer">
	        	<ul className="widget-footer-filters">
	        		{
						widgetState.source_filter === 'all' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('all')}>Samlet</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('all')}>Samlet</li>
						)
					}

					{
						widgetState.source_filter === 'onlinepos' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						)
					}

					{
						widgetState.source_filter === 'heapsgo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						)
					}

					{
						widgetState.source_filter === 'orderyoyo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						)
					}

					{
						widgetState.source_filter === 'wolt' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						)
					}
	        	</ul>
	        	
	        </div>

	    </div>
	); 
};

export default HeatmapRevenue;
