import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

/**
 * Load sales data
 */
const loadAudits = async (access_token, department_ids, start_date, end_date ) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_URL}/qa/audits/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: {
			department_ids: department_ids,
            start_date: moment(start_date).format('YYYY-MM-DD 00:00'),
            end_date: moment(end_date).format('YYYY-MM-DD 23:59'),
		}
	});

	return axiosPromise;		
}

/**
 * RolfStats
 */
export const RolfStats = (props) => {

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		audits: [],
		rolfScore: 0,

	});

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {

		const loadAllData = async () => {
			
			setIsLoading(true);
			
			// Get access token
			const access_token = await getAccessTokenSilently();

			const AuditsPromise = loadAudits(access_token, props.department_ids, props.start_date, props.end_date);

			Promise.all([AuditsPromise])
				.then( values => {

					setWidgetState(prevState => ({
						...prevState,
						audits: values[0].data.audits,
						rolfScore: 0,
						
					}));

					setIsLoading(false);

				})
				.catch(error => {
					setWidgetState(prevState => ({ 
						...prevState,
						audits: [],
						rolfScore: 0,
					}));
					setIsLoading(true);
					console.log(error);
				}
			);

		}

		loadAllData();
	}, [
		getAccessTokenSilently,
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * Calculate ROLF Score
	 **/
	useEffect(() => {
		const audits = widgetState.audits;
		let rolfScore = 0;

		if (audits.length > 0) {

			const totalScore = audits.reduce((accumulator, currentValue) => {
				return accumulator + currentValue.audit_score_percentage;
			}, 0);

		 	rolfScore = parseFloat(totalScore / audits.length);
		}

		setWidgetState(prevState => ({
			...prevState,
			rolfScore: rolfScore,
		}));
	}, [widgetState.audits]);
	

	/**
	 * Render
	 */
	return (
		<div className="widget-rolf-stats">
			{
				isLoading ? (
					<div className="rolf-stats">
						<div className="spinner-height-wrapper">
							<div className="spinner-centered">
								<div className="spinner"></div>
							</div>
						</div>
					</div>
				) : (

					<div className="rolf-stats">
					
						
						<div className="rolf-primary-stats">

							<div className="rolf-stat-line-primary">
								<div className="rolf-stat-line-label">Rolf Score</div>
								<div className="rolf-stat-line-value">{widgetState.rolfScore.toFixed(2)}%</div>
								Baseret på sidste <span>{widgetState.audits.length}</span> inspections
							</div>

						</div>

						<div className="rolf-audits-list">

							{
								widgetState.audits.map((audit, i) => {
									const date = moment(audit.date_completed);
									return (
										<a key={i} href={audit.web_report_link} target="_blank" rel="noopener noreferrer">
											<div className="rolf-audit-item">
												
												<div className="rolf-audit-item-main">
													<div className="rolf-audit-item-icon">
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M215.2 186.5h141.1v40H215.2zM154.9 186.5h39.9v40h-39.9zM215.2 254.6h141.1v40H215.2zM154.9 254.6h39.9v40h-39.9zM215.2 322.7h141.1v40H215.2zM154.9 322.7h39.9v40h-39.9z"/><path d="M406.5 48.9H356v-8.3c0-7.6-4.3-17.2-16.5-21.2-3.8-1.2-8.2-1.9-12.9-1.9H182.9c-4.2 0-8.3.6-11.8 1.8-11.9 3.9-16.1 13.3-16.1 20.8v8.8h-53.6c-20.3 0-36.7 16.5-36.7 36.7v372.9c0 20.3 16.5 36.7 36.7 36.7h308.3c20.3 0 36.7-16.5 36.7-36.7V88.8c0-22-17.9-39.9-39.9-39.9zM195 58.5c0-.5 2.7-.9 5.9-.9H309c3.9 0 7 .5 7 1.1V79.5c0 .5-2.7.9-5.9.9H200.9c-3.3 0-5.9-.4-5.9-.9v-21zm211.4 396.8H104.6V88.9H155v8.8c0 7.5 4.2 16.9 16.1 20.8 3.5 1.1 7.6 1.8 11.8 1.8h145.3c4.2 0 8.3-.6 11.8-1.8 11.9-3.9 16.1-13.3 16.1-20.8v-8.8h50.4v366.4z"/></svg>
													</div>
													<div className="rolf-audit-item-title-block">
														<div className="rolf-audit-item-title">{audit.location_name}, {date.format('DD.MM.YYYY')}</div>
													</div>
												</div>
												<div className="rolf-audit-percentage">{ parseFloat(audit.audit_score_percentage).toFixed(2) }%</div>
											</div>
										</a>
									)
								})
							}

						</div>
						
					</div>
				)
			}

		</div>		
	); 
};

export default RolfStats;
