import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

export const ProfilesFunnel = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	//const [ProfilesData, setProfilesData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			// setWidgetState('loading');

			// try {
			// 	// Get access token
			// 	const access_token = await getAccessTokenSilently();

			// 	// Get styles data
			// 	const profilesDataResponse = await axios.get(`${api_url}/profiles/`, { 
			// 		headers: { 'Authorization': `Bearer ${access_token}`},
			// 		params: {
			// 		    //start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
			// 		}
			// 	});

			// 	setProfilesData(profilesDataResponse.data.data);

			// 	setWidgetState('done');
				
			// } catch (e) {
        		// console.error('error', e);

        		// setProfilesData([]);
        		
        		// setWidgetState('error');
      		//}

		})();		

	}, [
		getAccessTokenSilently, 
	]);


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">4-step Funnel</h2>	
		        	</div>

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="widget-v3-error">
								<div>Coming soon</div>
							</div>

						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
						<div></div>

						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesFunnel;