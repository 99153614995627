import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";
// import Filters from "../components/Filters";
// import QuickLink from "../components/QuickLink";

// // Charts
// import DepartmentTable from '../components/widgets/DepartmentTable';
// import TimelineMain from '../components/widgets/TimelineMain';
// import MainStats from '../components/widgets/MainStats';
// import RolfStats from '../components/widgets/RolfStats';
// import SalesByChannel from '../components/widgets/SalesByChannel';
// import Targets from '../components/widgets/Targets';
// import Reviews from '../components/widgets/Reviews';
// //import AppInsights from '../components/widgets/AppInsights';
// import ProfilesKPIsSidebar from "../components/widgets/profiles/ProfilesKPIsSidebar";
// import ProfilesAcquisition from "../components/widgets/profiles/ProfilesAcquisition";

import ROLFProgressTable from "../components/widgets/qa/ROLFProgressTable";

export const DashboardComponent = (props) => {

	// //STATE
	// const [filterData, setFilterData] = useState({
	// 	active_department_ids: [],
	// 	active_department_labels: ['Alle afdelinger'],
	// 	active_start_date: new Date(),
	// 	active_end_date: new Date(),
	// 	active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	// 	active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	// });


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">ROLF</h1>

									<div className="page-description">
										<p>See ROLF progress over the last 5 inspections</p>
									</div>
								</div>

							</div>
							


							<div className="page-charts">

								<ROLFProgressTable />
		
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(DashboardComponent, {
  onRedirecting: () => <Loading />,
});
