import React, {useState, useEffect } from "react";
import moment from 'moment';

import post_data from '../../../data/instagram_posts.json';

export const SocialMediaTopPosts = (props) => {


	// Loading state
	const [widgetState, setWidgetState] = useState({
		posts: []
	});


	/**
	 * update frequency
	 */
	useEffect(() => {
	
		// Filter posts (this would be equivalent to a database query)
		const posts = post_data.posts.filter(post => {
			const post_date = moment(post.date);
			console.log(post_date);
			const start_date = moment(props.start_date);
			const end_date = moment(props.end_date);

			// Filter by profile and date
			if (post.profile === props.profile && post_date.format('YYYY-MM-DD') >= start_date.format('YYYY-MM-DD') && post_date.format('YYYY-MM-DD') <= end_date.format('YYYY-MM-DD')) {
				return post;
			}			
		})

		// Sort by reach
		posts.sort((a,b) => (b.insights.reach > a.insights.reach ) ? 1 : -1);

		// Update state
		setWidgetState(prevState => ({
			...prevState,
			posts: posts,
		}))

	}, []);


	return (
		<div className="widget-v2 widget-social-media-channelse">
	        <div className="widget-v2-header">
	        	<div className="widget-v2-header-title-block">
	            	<h2 className="widget-v2-title">Top posts</h2>
	            	<h3 className="widget-v2-subtitle">Based on reach</h3>
	            </div>
	        </div>
			
			<div className="widget-v2-body">

				<div className="top-posts">
					
						{
							widgetState.posts.map((post, i) => {

								const post_date = moment(post.date);

								return (
									<div key={i} className="top-posts-item">
										<a href={`https://www.instagram.com/p/${post.post_id}/`} target="_blank" className="top-posts-item-image" style={ { backgroundImage: `url('/images/instagram_posts${process.env.PUBLIC_URL}/${post.post_id}.jpg')` } }>
											
											
											<div className="top-posts-item-image-type">											
												{
													post.type === 'video' && (
														<div className="top-posts-item-image-overlay">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M196.5 446.6H70.9c-38.6 0-65.3-26.6-65.3-65.3-.1-83.5-.1-167.1 0-250.6 0-38.3 26.4-65.1 64.8-65.1 84-.2 168-.2 251.9 0 37.5.1 64.2 26.9 64.2 64.3.1 84.2.1 168.4 0 252.6 0 37.3-26.9 64-64.4 64.1-41.8 0-83.7-.1-125.6 0zm123.9-190.2c.7-67.9-55-124.1-123.4-124.7-68.4-.6-125.6 54.9-125.6 123.4 0 71.3 56.6 124.6 123 125.3 69.6.7 125.4-54.2 126-124zM506.2 256.1V379c0 12.7-8.3 20.5-19.4 18.3-2.8-.5-5.5-1.9-8.1-3.4-19.8-11-39.5-22.1-59.3-33-7.6-4.2-10.7-10.2-10.7-18.8.2-57.2.2-114.5 0-171.7 0-9.2 3.4-15.3 11.5-19.7 19.5-10.5 39.1-21 57.9-32.6 14.4-8.9 28.7 0 28.3 16.5-.9 40.5-.2 81-.2 121.5z"/><path d="M272.3 256.4c1.5 38.5-32.6 77.2-77.9 76.2-42.4-1-76-36-75.1-78.6.9-41.5 36.4-75.5 77.9-74.6 42.4.9 75.9 35.3 75.1 77z"/></svg>
														</div>
													)
												}

												{
													post.type === 'reels' && (
														<div className="top-posts-item-image-overlay">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256.7 505.3H147.5c-68.9-.1-125.9-48-137.6-115.8-1.3-7.3-1.8-14.8-1.8-22.2-.1-73.7-.2-147.3-.1-221 .2-68 49.4-125.6 116.5-136.4 7.2-1.2 14.6-1.7 21.9-1.7 73.5-.1 147-.2 220.6-.1 68 .1 125.6 49.1 136.5 116.2 1.2 7.2 1.7 14.6 1.7 21.9.1 73.7.2 147.3.1 221-.1 68-49.3 125.6-116.4 136.4-7.4 1.2-15.1 1.7-22.6 1.7-36.5.1-73 0-109.6 0zM466 171.9H47.6c-.1 1.3-.1 2.3-.1 3.3 0 64.2-.2 128.4.1 192.5.2 54.2 44.3 98.1 98.8 98.2 73.6.2 147.3.1 220.9-.1 7.4 0 14.9-.9 22.1-2.6 45.4-10.7 76.5-50.1 76.6-96.8.1-63.5 0-127.1 0-190.6v-3.9zM331.7 132c-.5-1.1-.7-1.8-1-2.4-14.8-26.6-29.5-53.2-44.2-79.8-1.1-2-2.5-2.4-4.6-2.4H176.6c-.9 0-1.8.2-3.1.3.8 1.6 1.4 2.8 2.1 4 14.2 25.6 28.5 51.2 42.6 76.8 1.5 2.7 3.1 3.7 6.1 3.6 34.3-.1 68.7-.1 103-.1 1.4.2 2.7.1 4.4 0zm132.9 0c-.1-1.3-.1-2.2-.2-3.1-6.4-39.8-40.7-74.5-80.9-79.7-17.2-2.2-34.7-1.2-52.1-1.6-.2 0-.3.3-.6.6.3.7.7 1.5 1.1 2.3 14.6 26.4 29.3 52.7 43.9 79.1 1.2 2.1 2.6 2.6 4.8 2.6 26.8-.1 53.5 0 80.3 0 1.1 0 2.2-.1 3.7-.2zm-289.8 0c-.6-1.2-.8-1.9-1.2-2.5-14.4-25.9-28.9-51.8-43.1-77.8-1.6-2.9-3.4-2.4-5.6-1.8C85.1 60.2 60 85.4 49.8 125.3c-.5 2.1-.7 4.3-1.1 6.7h126.1z"/><path d="M198.1 314.7v-55c0-19 16.9-28.7 33.4-19.1 31.5 18.2 63 36.4 94.4 54.6 16.5 9.5 16.7 29 .3 38.5l-95.4 55.2c-15.6 9-32.6-.8-32.7-18.8-.1-18.5 0-37 0-55.4z"/></svg>
														</div>
													)
												}
											</div>

											<div className="top-posts-item-image-stat">
												{post.insights.reach.toLocaleString("da-DK")}
											</div>
										</a>
										<div className="top-posts-item-date">
											{post_date.format('DD. MMM')}
										</div>
									</div>
								)
							})
						}
					

				</div>

			</div>
		</div>

	);
}

export default SocialMediaTopPosts;
