import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../../components/Loading";
import Navigation from "../../components/Navigation";
import BrandBar from "../../components/BrandBar";
import Filters from "../../components/Filters";
import Drawer from "../../components/Drawer";
import OrderDetails from "../../components/widgets/orders/OrderDetails";

// Widgets
import OrdersTable from "../../components/widgets/orders/OrdersTable";

export const Orders = (props) => {

	//STATE
	const [filterData, setFilterData] = useState({
		active_department_ids: [],
		active_department_labels: ['Alle afdelinger'],
		active_start_date: new Date(),
		active_end_date: new Date(),
		// active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		// active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	});

	// Loading state
	const [drawerOrder, setDrawerOrder] = useState({});

	// Drawer state
	const [drawerState, setDrawerState] = useState('closed'); // loading, error, done	


	return (
		<div className="app-container">
			<Drawer drawerState={drawerState} setDrawerState={setDrawerState}>
				<OrderDetails order={drawerOrder}/>
			</Drawer>

			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Profiles"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Orders</h1>

									<div className="page-description">
										<p>This is a list of all app orders (All other orders coming later)</p>
									</div>
								</div>
								<div className="page-filters-container">
									<Filters filterData={filterData} setFilterData={setFilterData} departments={props.globalData.departments}/>
								</div>
							</div>
							


							<div className="page-charts">

								<OrdersTable 
									setDrawerOrder={setDrawerOrder} 
									setDrawerState={setDrawerState}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
									department_ids={filterData.active_department_ids}
									
									{...props}
								/>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Orders, {
  onRedirecting: () => <Loading />,
});
