import React from "react";

/**
 * RolfStats
 */
export const WebsiteContentStats = (props) => {

	/**
	 * Render
	 */
	return (
		<div className="widget-rolf-stats">
			{
				

					<div className="rolf-stats">
					
						
						<div className="rolf-primary-stats">

							<div className="rolf-stat-line-primary">
								<div className="rolf-stat-line-label">Page views</div>
								<div className="rolf-stat-line-value">26.502</div>

							</div>

						</div>

						<div className="rolf-audits-list">
							
							<div className="rolf-audit-item">
								<div className="rolf-audit-item-main">
									<div className="rolf-audit-item-title-block">
										<div className="rolf-audit-item-title"><strong>TOP 5 PAGES</strong></div>
									</div>
								</div>
							</div>

							<a  href="https://www.groed.com/menu" target="_blank" rel="noopener noreferrer">
								<div className="rolf-audit-item">
									<div className="rolf-audit-item-main">
										<div className="rolf-audit-item-title-block">
											<div className="rolf-audit-item-title">/menu</div>
										</div>
									</div>
									<div className="rolf-audit-percentage">6.407</div>
								</div>
							</a>
							
							
							<a  href="https://www.groed.com/" target="_blank" rel="noopener noreferrer">
								<div className="rolf-audit-item">
									<div className="rolf-audit-item-main">
										<div className="rolf-audit-item-title-block">
											<div className="rolf-audit-item-title">/</div>
										</div>
									</div>
									<div className="rolf-audit-percentage">5.250</div>
								</div>
							</a>
							
							<a  href="https://www.groed.com/en/menu" target="_blank" rel="noopener noreferrer">
								<div className="rolf-audit-item">
									<div className="rolf-audit-item-main">
										<div className="rolf-audit-item-title-block">
											<div className="rolf-audit-item-title">/en/menu</div>
										</div>
									</div>
									<div className="rolf-audit-percentage">1.760</div>
								</div>
							</a>

							<a  href="https://www.groed.com/groed-opskrifter" target="_blank" rel="noopener noreferrer">
								<div className="rolf-audit-item">
									<div className="rolf-audit-item-main">
										<div className="rolf-audit-item-title-block">
											<div className="rolf-audit-item-title">/groed-opskrifter</div>
										</div>
									</div>
									<div className="rolf-audit-percentage">1.336</div>
								</div>
							</a>

							<a  href="https://www.groed.com/restauranter" target="_blank" rel="noopener noreferrer">
								<div className="rolf-audit-item">
									<div className="rolf-audit-item-main">
										<div className="rolf-audit-item-title-block">
											<div className="rolf-audit-item-title">/restauranter</div>
										</div>
									</div>
									<div className="rolf-audit-percentage">963</div>
								</div>
							</a>

							

							
								
						</div>
						
					</div>
				
			}

		</div>		
	); 
};

export default WebsiteContentStats;
