import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";
import Filters from "../components/Filters";
import QuickLink from "../components/QuickLink";

// Charts
import DepartmentTable from '../components/widgets/DepartmentTable';
import TimelineMain from '../components/widgets/TimelineMain';
import MainStats from '../components/widgets/MainStats';
import RolfStats from '../components/widgets/RolfStats';
import SalesByChannel from '../components/widgets/SalesByChannel';
import SalesByPaymentType from '../components/widgets/SalesByPaymentType';
import Targets from '../components/widgets/Targets';
import Reviews from '../components/widgets/Reviews';
//import AppInsights from '../components/widgets/AppInsights';
import ProfilesKPIsSidebar from "../components/widgets/profiles/ProfilesKPIsSidebar";
import ProfilesAcquisition from "../components/widgets/profiles/ProfilesAcquisition";


export const DashboardComponent = (props) => {

	//STATE
	const [filterData, setFilterData] = useState({
		active_department_ids: [],
		active_department_labels: ['Alle afdelinger'],
		active_start_date: new Date(),
		active_end_date: new Date(),
		active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	});


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Dashboard</h1>

									<div className="page-description">
										<p>Her kan du se et overblik over butikkernes performance</p>
									</div>
								</div>

								<div className="page-filters-container">
									<Filters filterData={filterData} setFilterData={setFilterData} departments={props.globalData.departments}/>
								</div>
							</div>
							


							<div className="page-charts">

								<div className="page-chart-with-sidebar-element">
									
									<div className="page-chart-with-sidebar-element-sidebar">
										<MainStats
											department_ids={filterData.active_department_ids}
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
											compared_start_date={filterData.active_compared_start_date}
											compared_end_date={filterData.active_compared_end_date}
										/>

										{/*
											<QuickLink title="Kogebogs-konkurrence" to="/performance/competition"/>
										*/}
										
										<RolfStats
											department_ids={filterData.active_department_ids}
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
										/>
										
									</div>

									<div className="page-chart-with-sidebar-element-main">
				
										<TimelineMain 
											hasLoaded={false}
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
											department_ids={filterData.active_department_ids}
											/>
						

									</div>

								</div>
								
								
							
								<Reviews
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>
								
							
					
								<DepartmentTable 
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
									compared_start_date={filterData.active_compared_start_date}
									compared_end_date={filterData.active_compared_end_date}
								/>

								{/*
								<div className="split-chart-container-1-3">
									<div className="split-chart-container-widget">
										<ProfilesKPIsSidebar />		
									</div>

									<div className="split-chart-container-widget">
										<ProfilesAcquisition 
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
											show_footer_link={true}
										/>
									</div>
								</div>
								*/}


								<SalesByChannel
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>

							{/*
								<Targets
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>
							*/}
		
								<SalesByPaymentType
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(DashboardComponent, {
  onRedirecting: () => <Loading />,
});
