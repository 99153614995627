import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";
import Filters from "../components/Filters";

// Charts
import WasteStats from '../components/widgets/WasteStats';
import WasteTable from '../components/widgets/WasteTable';
import EmployeeFoodTable from '../components/widgets/EmloyeeFoodTable';


export const Waste = (props) => {

	//STATE
	const [filterData, setFilterData] = useState({
		active_department_ids: [],
		active_department_labels: ['Alle afdelinger'],
		active_start_date: new Date(),
		active_end_date: new Date(),
		active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	});

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Indtastninger</h1>

									<div className="page-description">
										<p>Her kan lave kontrol på diverse indtastninger</p>
									</div>
								</div>

								<div className="page-filters-container">
									<Filters filterData={filterData} setFilterData={setFilterData} departments={props.globalData.departments}/>
								</div>
							</div>
						
							<div className="page-charts">
						
								<div className="page-chart-with-sidebar-element">
									
									<div className="page-chart-with-sidebar-element-sidebar">
										<WasteStats
											department_ids={filterData.active_department_ids}
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
										/>

									</div>

									<div className="page-chart-with-sidebar-element-main">

										<WasteTable
											department_ids={filterData.active_department_ids}
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
										/>

										<EmployeeFoodTable
											department_ids={filterData.active_department_ids}
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
										/>

									</div>

								</div>

							
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Waste, {
  onRedirecting: () => <Loading />,
});
