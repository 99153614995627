import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import ReviewStars from '../ReviewStars';

export const Reviews = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(false);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,
		source_filter: 'wolt',
		reviews: [], 
		active_reviews: [],
		total_score: 0,
		reviews_per_page: 3,
		current_page: 1,
		total_pages: 1,
	});


	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setIsLoading(true);
			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get departments data
				const reviewsResponse = await axios.get(`${api_url}/aggregation/reviews/`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
						department_ids: props.department_ids,
	                    start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
	                    end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
					}
				});

				setWidgetState(prevState => ({ 
					...prevState,
					reviews: reviewsResponse.data.reviews,
					active_reviews: reviewsResponse.data.reviews.slice(0,widgetState.reviews_per_page),
					total_pages: Math.ceil(reviewsResponse.data.reviews.length / widgetState.reviews_per_page)
				}));
				

				setIsLoading(false);
				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [
		getAccessTokenSilently, 
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);


	useEffect(() => {
		calculateTotalScore();
	}, [
		widgetState.reviews,
	]);


	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const calculateTotalScore = () =>  {

		const reviews = widgetState.reviews;
		let sum = 0;
		let count = 0;

		for (const review of reviews) {
			count++;
			sum += review.review_score;			
		}

		let score = sum / count;
		if (!score) {
			score = 0;
		}
	
		setWidgetState(prevState => ({
			...prevState,
			total_score: score.toFixed(1),
		}));	

		
	}

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {

		setWidgetState(prevState => ({
			...prevState,
			source_filter: new_mode
		}));
	}


	/**
	 * Handle paging
	 */
	const pageActiveReviews = (direction) =>  {

		const reviews = widgetState.reviews;
		const reviews_per_page = widgetState.reviews_per_page;
		const total_pages = Math.ceil(reviews.length / reviews_per_page);
		let current_page = widgetState.current_page;
		let active_reviews = widgetState.active_reviews;

		if (direction === 'next' && current_page < total_pages) {
			current_page++;			
			let end = reviews_per_page * current_page;
			let start = end - reviews_per_page;
			active_reviews = reviews.slice(start, end);	
		}

		if (direction === 'prev' && current_page > 1) {
			current_page--;			
			let end = reviews_per_page * current_page;
			let start = end - reviews_per_page;
			active_reviews = reviews.slice(start, end);	
		}

		setWidgetState(prevState => ({
			...prevState,
			total_pages: total_pages,
			active_reviews: active_reviews,
			current_page: current_page
		}));
	}


	return (
		<div className="widget-v2-container">
				
				<div className="widget-v2">
			        <div className="widget-v2-header">
			            <h2 className="widget-v2-title">Anmeldelser</h2>
			      	
      		        	<div className="widget-timeline-v2-footer-settings">
      		        		<div className="pagination">
      			        		<div className="pagination-arrow pagination-arrow-prev" onClick={() => pageActiveReviews('prev')}>
      			        			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M346.5 505.2l68-68.1-180.9-180.9L414.5 75.3l-68-68-249 248.9z"/></svg>	
      			        		</div>
      			        		<div className="pagination-pages">
      			        			{widgetState.current_page} / {widgetState.total_pages}
      			        		</div>
      			        		<div className="pagination-arrow pagination-arrow-next" onClick={() => pageActiveReviews('next')}>
      			        			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M165.2 505.2l-68-68.1 180.9-180.9L97.2 75.3l68-68 249 248.9z"/></svg>
      			        		</div>
      		        		</div>

      		        	</div>
			        </div>
					
					<div className="widget-v2-body">

						{
							isLoading ? (
								<div className="responsive-line-container">
									<div className="spinner-centered">
										<div className="spinner"></div>
									</div>
								</div>
							) : (
								<div className="reviews-widget-content">
									
									<div className="reviews-main">
										<div className="reviews-main-title">{widgetState.total_score}</div>
										<div className="reviews-main-subtitle">Baseret på {widgetState.reviews.length} anmeldelser</div>
									</div>	

									<div className="reviews-list">
										{

											widgetState.active_reviews.map((review, i) => {
												console.log(review);
												return (
													<div key={i} className="reviews-list-item">
														<div  className="reviews-list-item-rating-line">
															<div  className="reviews-list-item-rating">
																<ReviewStars rating={review.review_score} total={5}/>
															</div>
															<div  className="reviews-list-item-date">{moment(review.date).format('DD/MM/YYYY HH:mm')}</div>
														</div>
														<div  className="reviews-list-item-comment">{review.review_comment}<span> — {review.customer_name}</span></div>
														<div  className="reviews-list-item-metapill">
															Wolt — {review.department_name}
														</div>
													</div>
												)
											})
										}

									</div>	

								</div>
							)
						}

					</div>
					
					<div className="widget-v2-footer">
			        	<ul className="widget-footer-filters">
			        		<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
							<li className="widget-footer-filter" style={{textDecoration: 'line-through', cursor: 'initial'}}>Google</li>		
			        	</ul>

			        	
			        </div>
				</div>
		</div>
	); 
};

export default Reviews;
