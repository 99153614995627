import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

export const ProfileDetails = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state

	const default_profile = {
		profile: {
			first_name: 'N/A',
			last_name: 'N/A',
			date_of_birth: 'N/A',
			gender: 'N/A',
			city: 'N/A',
		},
		attribution: 'N/A',
		tags: [],
		event_count: 0,
		order_count: 0,
		total_spent: 0,
	}

	const [profileData, setProfileData] = useState(default_profile);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');

			if(props.match){
				const profile_id = props.match.params.profile;	
			
				try {
					// Get access token
					const access_token = await getAccessTokenSilently();

					// Get styles data
					const profileDataResponse = await axios.get(`${api_url}/profiles/${profile_id}`, { 
						headers: { 'Authorization': `Bearer ${access_token}`},

					});

					if(profileDataResponse.data.data.profile === null) {
						setWidgetState('error');	
					} else {
						
						setProfileData(prevState => ({
							...prevState,
							profile: {...prevState.profile, ...profileDataResponse.data.data.profile.properties},
							event_count: profileDataResponse.data.data.event_count,
							order_count: profileDataResponse.data.data.order_count,
							total_spent:profileDataResponse.data.data.total_spent / 100,
						}));

						// Make profile data available for parentcomponent
						if(props.setParentProfileData) {
							props.setParentProfileData(prevState => ({
								...prevState,
								profileState: 'done',
								profile: {...prevState.profile, ...profileDataResponse.data.data.profile.properties},
							}));
						}

						setWidgetState('done');
					}				
					
				} catch (e) {
		    		setProfileData(default_profile);
		    		console.error('error', e);
		    		setWidgetState('error');
		  		}
		  	} else {
		  		// If we dont have a profile id, we should error out
		  		setWidgetState('error');
		  	}

		})();		

	}, [
		getAccessTokenSilently, 
	]);


	/**
	 * Convert date of birth to age
	 */
	const dateToAge = (date_of_birth) => {
		
		// Quickfix for cleaner console log when data hasent loaded.
		if(date_of_birth === 'N/A') {
			return 'N/A';
		}

		// Make sure date_of_birth is a date
		const m = moment(date_of_birth);
		if (!m.isValid()) {
			// console.log('Date of birth is not valid. Age could not be calculated.');
			// console.log('Date of birth: ', date_of_birth);
			return 'N/A';
		} else {
			const ageInMilliseconds = new Date() - new Date(date_of_birth);
			const age = Math.floor(ageInMilliseconds/1000/60/60/24/365); // convert to years
			return age	
		}
	}


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">

							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
							    <path d="M281.51 254.88c29.21-10.49 50.15-38.46 50.15-71.24 0-41.73-33.94-75.68-75.67-75.68s-75.69 33.95-75.69 75.68c0 32.96 21.2 61.06 50.67 71.41-46.7 8.89-85.96 42.3-101.77 89.16-7.21 24.74 7.02 50.75 31.76 57.97 4.29 1.26 8.72 1.89 12.68 1.89.53 0 1.07-.01 1.58-.03H337.6c.49.01.97.02 1.48.02 24.9 0 45.65-19.75 46.67-44.92.21-5.1-.42-10.12-2.04-15.64-15.76-46.66-55.24-80.01-102.2-88.62zm-65.59-71.24c0-22.09 17.99-40.07 40.08-40.07s40.06 17.98 40.06 40.07-17.97 40.07-40.06 40.07-40.08-17.98-40.08-40.07zm123.14 184.81c-.37 0-.81-.02-1.16-.02l-163.39.01c-1.18.09-2.39-.09-3.57-.43-5.89-1.73-9.27-7.93-7.77-13.11 13.45-39.85 50.77-66.62 93.01-66.62.32 0 .67-.01 1.02-.01 42.08 0 79.28 26.66 92.54 65.92.32 1.15.49 2.36.44 3.57-.24 5.98-5.18 10.69-11.12 10.69z" />
							    <path d="M445.91.81H66.09C30.09.81.81 30.09.81 66.09v379.82c0 36 29.28 65.28 65.28 65.28h379.82c36 0 65.28-29.28 65.28-65.28V66.09c0-36-29.28-65.28-65.28-65.28zm29.68 445.1c0 16.37-13.31 29.67-29.67 29.67H66.09c-16.37 0-29.67-13.31-29.67-29.67V66.09c0-16.37 13.31-29.67 29.67-29.67h379.82c16.37 0 29.67 13.31 29.67 29.67v379.82z" />
							</svg>
						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Profile Details</h2>	
		        		<div className="widget-v3-subtitle">Who dis?</div>
		        	</div>
		            
		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="widget-profile-details">

								<ul className="widget-profile-details-list">
									
									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">Total Spent</div>
										<div className="widget-profile-details-list-item-value">{profileData.total_spent.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</div>
									</li>

									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">Order Count</div>
										<div className="widget-profile-details-list-item-value">{profileData.order_count}</div>
									</li>

									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">Event Count</div>
										<div className="widget-profile-details-list-item-value">{profileData.event_count}</div>
									</li>

									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">Gender</div>
										<div className="widget-profile-details-list-item-value">{profileData.profile.gender}</div>
									</li>

									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">Age</div>
										<div className="widget-profile-details-list-item-value">{dateToAge(profileData.profile.date_of_birth)}</div>
									</li>
									
									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">City</div>
										<div className="widget-profile-details-list-item-value">{profileData.profile.city}</div>
									</li>

									<li className="widget-profile-details-list-item">
										<div className="widget-profile-details-list-item-label">Attribution</div>
										<div className="widget-profile-details-list-item-value">{profileData.attribution}</div>
									</li>
								</ul>

								{/*
								<div className="widget-profile-details-tags">
								
									<div className="widget-profile-details-tags-label">Tags</div>
									<ul className="widget-profile-details-tag-list">
										<li className="widget-profile-details-tag-list-item">App</li>
										<li className="widget-profile-details-tag-list-item">E-commerce</li>
										<li className="widget-profile-details-tag-list-item">Retail</li>
										<li className="widget-profile-details-tag-list-item">Accepted Marketing</li>
									</ul>

								</div>
								*/}

							</div>

						)
					}
				</div>

			      {/*
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All channels</li>
									<li className="widget-v3-footer-filter">OnlinePOS</li>
									<li className="widget-v3-footer-filter">HeapsGO</li>
					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        */}

		    </div>
		</div>	
	); 
};

export default ProfileDetails;

