import React, { Component } from 'react';
import { Calendar } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import moment from 'moment';

class DatePickerSelect extends Component {

	constructor() {
		super();
		this.toggleDatePicker = this.toggleDatePicker.bind(this);

		// If we make widget controls, these should be stored in state.
		this.state = {
			show: false,
		}

		this.setWrapperRef = this.setWrapperRef.bind(this);
	    this.handleClickOutside = this.handleClickOutside.bind(this);

	}


	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.date !== this.props.date) {
			this.setState({show: false});    
		}
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	/**
	 * Toggle datepicker
	 */
	toggleDatePicker() {
		// Toggle
		const show = !this.state.show;
		this.setState({ 
			show: show,
		});
	}

	/**
	 * Set the wrapper ref
	 */
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	/**
	 * Alert if clicked on outside of element
	 */
	handleClickOutside(event) {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({ show: false });
		}
	}

	/**
	 * Render component
	 */
	render() {

		return (
			<div ref={this.setWrapperRef} className="datepicker-select">
				
				<div className="datepicker-select-input" onClick={this.toggleDatePicker}>
					<div className="datepicker-select-body">
					{ moment(this.props.date).format('DD/MM/YYYY')}
					</div>
					{
					    this.state.show ? ( 
					        <div className="datepicker-select-toggler"><div className="arrow up"></div></div>
					    ) : (
					        <div className="datepicker-select-toggler"><div className="arrow down"></div></div>
					    )
					}
				</div>
				{ 

				    this.state.show  && (
						<div className="datepicker-select-container">
							<Calendar date={this.props.date} onChange={this.props.changeHandler} locale={rdrLocales.enGB} />
						</div>
					)
				}

			</div>
		);
	}
}

export default DatePickerSelect;