import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

// Charts
import SimpleBars from '../../charts/SimpleBars';



/**
 * Get profiles Spent ranges
 */
const getProfilesSpentRanges = async (access_token) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-spent-ranges/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`}
	});

	return axiosPromise;		
}

// /**
//  * Get profiles age split by platform
//  */
// const getProfilesSpentRangesByPlatform = async (access_token) => {

// 	// Get departments data
// 	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-spent-ranges-by-source/`, { 
// 		headers: { 'Authorization': `Bearer ${access_token}`}
// 	});

// 	return axiosPromise;		
// }


export const ProfilesLifetimeSpendRange = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	const [profilesSpentData, setprofilesSpentData] = useState([]);

	// Loading state
	const [sourceFilter, setSourceFilter] = useState('All');

	// First entry is always the master
	const [barData, setBarData] = useState([]);


	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		const loadAllData = async () => {

			setWidgetState('loading');

			// Get access token
			const access_token = await getAccessTokenSilently();
			
			const profilesSpentRanges = getProfilesSpentRanges(access_token);
			//const profilesSpentRangesByPlatform = getProfilesSpentRangesByPlatform(access_token);
			
		
			Promise.all([profilesSpentRanges])
				.then( values => {

					const profilesSpentRanges = values[0].data.data;
					// const profilesSpentRangesByPlatform = values[1].data.data.map(d => {

					// 	// if(d.platform === 'Mixpanel'){
					// 	// 	d.platform = 'GRØD App';
					// 	// }

					// 	// if(d.platform === 'Klaviyo'){
					// 	// 	d.platform = 'Mailing list';
					// 	// }

					// 	// if(d.platform === 'Shopify'){
					// 	// 	d.platform = 'Webshop';
					// 	// }

					// 	return d;

					// });

					const spentRanges = [
						{
							platform: 'All',
							buckets: [...profilesSpentRanges]
						},
						//...profilesSpentRangesByPlatform
					];
					
					setprofilesSpentData(spentRanges);

					setWidgetState('done');
				})
				.catch(error => {
					setprofilesSpentData([]);
					
					setWidgetState('error');
					
					console.log(error);
				});
		}
		loadAllData();

	}, [
		getAccessTokenSilently, 
	]);



	useEffect(() => {


		const dataset = profilesSpentData.find(a => a.platform === sourceFilter);

		if(dataset) {
			
			const buckets = dataset.buckets;

			const total = buckets.reduce( (previousValue, currentValue) => {
				return previousValue + currentValue.count;
			}, 0)

			
			const bars = buckets.map(bucket => {

				const obj = {
					label: bucket.bucket,
					value: bucket.count,
					total: total,
					data: [
						{
							data_name: bucket.bucket,
							color: '#2771D5',
							value: bucket.count,
						},
					]
				};

				return obj;

			});

			setBarData(bars);

		}

	}, [
		profilesSpentData,
		sourceFilter
	]);


	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setSourceFilter(new_mode);
	}


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Customer Lifetime Value</h2>
		        		<div className="widget-v3-subtitle">Number of profiles by CLV</div>
		        	</div>

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<SimpleBars
								data={barData}
								showLegends={false}
							 />

						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All</li>
					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesLifetimeSpendRange;