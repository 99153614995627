import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const ThickBar = (props) => {

	// Thick bars
	const [thickBarState, setThickBarState] = useState({
		total: 100,
		legends: [],
		data: [],
	});


	const colors = ['#2771D5', '#181B5F', '#6F9DDB', 'green', 'blue', 'orange'];

	/**
	 * Format data
	 */
	useEffect(() => {
		//formatLegends(props.data);
		
		// Calculate total
		const total = props.data.reduce( (previousValue, currentValue) => {
			return previousValue + currentValue.value;
		}, 0)		

		// Add colors 
		const data_with_colors = props.data.map((entry, i) => {
			let colorIndex = (i % 6);
			
			if(entry.label === 'Not specified') {
				entry.color = "#C9C9C9";
			} else {
				entry.color = colors[colorIndex];	
			}
			return entry;
		});

		setThickBarState(prevState => ({
			...prevState,
			total: total,
			legends: data_with_colors,
			data: data_with_colors
		}))

	}, [
		props.data
	]);


	return (
		<div>
			{
				props.data.length == 0 && (
					<div className="widget-v3-error">
						<div>No data</div>
					</div>
				)
			}

			{
				props.data.length > 0 && (
					<div className="thick-bar">
						<div className="thick-bar-track">
							

							{
								thickBarState.data.map((entry, i) => {
									const percentage = entry.value / thickBarState.total * 100;

									return (
										<div key={i} className="thick-bar-entry" style={{width: percentage + '%', backgroundColor: entry.color}} title={entry.value}></div>
									)

								})
							}
							
						</div>

							{
								props.showLegends && thickBarState.legends.length > 0 && (
									<div className="simple-bars-footer">
										<ul className="simple-bars-legends">
											{
											thickBarState.legends.map((legend, i) => {
												const percentage = legend.value / thickBarState.total * 100;

												return (
													<li key={i} className="simple-bars-legend" style={{color: legend.color}}>
														<div className="simple-bars-legend-dot" style={{backgroundColor: legend.color}}></div>
														<div className="simple-bars-legend-label">{legend.label} ({percentage.toFixed()}%)</div>
													</li>
												)

											})

											}
										</ul>
									</div>
								)
							}

						

					</div>
				)
			}
		</div>
			
	); 
};

export default ThickBar;

