import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';


/**
 * RolfStats
 */
export const WebsiteStats = (props) => {

	
	/**
	 * Render
	 */
	return (
		<div className="widget-social-media-stats">			

			<div className="social-media-stats">
			
				<div className="social-media-primary-stats">

					<div className="social-media-stat-line-primary">
						<div className="social-media-stat-line-label">Omsætning (webshop)</div>
						<div className="social-media-stat-line-value">4.142 kr.</div>
					</div>

				</div>

				<div className="social-media-secondary-stats">

					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">Total sessions</div>
						<div className="stat-line-value">9.159</div>
					</div>

					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">Page views</div>
						<div className="stat-line-value">26.502</div>
					</div>

					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">Pages pr. session</div>
						<div className="stat-line-value">2,89</div>
					</div>

					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">Avg. session duration</div>
						<div className="stat-line-value">01:41</div>
					</div>

					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">Bounce Rate</div>
						<div className="stat-line-value">33,53%</div>
					</div>

					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">New visitors</div>
						<div className="stat-line-value">69%</div>
					</div>


				</div>

			</div>
		</div>		
	); 
};

export default WebsiteStats;
