import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../../components/Loading";
import Navigation from "../../components/Navigation";
import BrandBar from "../../components/BrandBar";

// Charts
import InfluencersTable from '../../components/widgets/profiles/InfluencersTable';

export const Profiles = (props) => {

	//STATE
	const [filterData, setFilterData] = useState({
		active_department_ids: [],
		active_department_labels: ['Alle afdelinger'],
		active_start_date: new Date(),
		active_end_date: new Date(),
		active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	});


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Profiles"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Influencers</h1>

									<div className="page-description">
										<p>Profiles represent profile-data on people who interacted with your brand in a way that collected their data</p>
									</div>
								</div>

							</div>

							<div className="page-charts">

								<InfluencersTable/>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Profiles, {
  onRedirecting: () => <Loading />,
});
