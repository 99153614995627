import React, { useState, useEffect } from "react";

import Select from 'react-select';
import { ResponsiveLine } from '@nivo/line';

export const TimelineLewsletter = (props) => {

	// Loading state
	const [widgetState, setWidgetState] = useState({
		source_filter: 'groed',
		error: false,
		data: {
			mailchimp: {
				target: 0,
				max: 25000,
				data: [
					{
						"id": "Subscribers",
						"color": "hsl(223, 70%, 54%)",
						"data": [
							{ x: '21-2021', y: 4103},
							{ x: '22-2021', y: 4128},
							{ x: '23-2021', y: 4566},
							{ x: '24-2021', y: 4826},
							{ x: '25-2021', y: 4892},
							{ x: '26-2021', y: 5015},
							{ x: '28-2021', y: 5167},
							{ x: '29-2021', y: 5267},
							{ x: '30-2021', y: 5367},
							{ x: '31-2021', y: 5467},
							{ x: '32-2021', y: 5567},
						]
					},
				],
			},	
		}
	});

	return (
		<div className="widget-v2">
	        <div className="widget-v2-header">      
      			Subscribers
	        </div>
			
			<div className="widget-v2-body">
					<div className="responsive-line-container">
						
						<ResponsiveLine
							data={widgetState.data.mailchimp.data}
							colors={d => d.color}
							margin={{ top: 50, right: 50, bottom: 70, left: 90 }}
							xScale={{ type: "point"}}
							yScale={{ type: "linear", stacked: false, min: 0, max: 'auto' }}
							curve="monotoneX"
							axisTop={null}
							axisRight={null}
							axisBottom={{
								orient: "bottom",
								tickSize: 5,
								tickPadding: 5,
								tickRotation: -40,
								legend: "",
								legendOffset: 36,
								legendPosition: "middle"
							}}
							axisLeft={{
								orient: "left",
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: "",
								legendOffset: -40,
								legendPosition: "middle",
								format: value => `${Number(value).toLocaleString('da-DK')}`,
							}}



							yFormat={value => {
								if (props.salaryPercentageMode) {
									return `${value}%`;
								} else {
									return `${Number(value).toLocaleString('da-DK')}`;
								}
								
							}}

			      	    	enablePointLabel={true}
			      	    	pointSize={6}
			      	    	pointBorderWidth={1}
			      	    	pointBorderColor={{
								from: 'color',
								modifiers: [['darker', 0.3]],
							}}
							pointLabelYOffset={-10}
							enableArea={true}			        
							areaOpacity={0.07}
							useMesh={false}
							enableSlices='x'		
							animate={true}
							motionStiffness={300}
							motionDamping={30}
							markers={[
					            {
					                // axis: 'y',
					                // value: widgetState.data[widgetState.source_filter].target,
					                // lineStyle: { stroke: 'hsl(223, 70%, 54%)', strokeWidth: 1, strokeDasharray: "4 4", },
					                // legend: 'Reach target',
					            },
					        ]}
						/>

					</div>	
					<div className="widget-v2-body-legends">

        				<div className="widget-v2-body-legend" style={{color: 'hsl(223, 70%, 54%)'}}>
			            	<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(223, 70%, 54%)'}}></div>
			            	<div className="widget-v2-body-legend-title">Subscribers</div>
			            </div>
		            </div>

			</div>						        


	        <div className="widget-v2-footer">
	        	
	        	<div className="widget-timeline-v2-footer-settings">
		        		
	        	</div>
	        </div>

	    </div>
	); 
};

export default TimelineLewsletter;
