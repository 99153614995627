import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import channel_data from '../../../data/channel_data.json';

/**
 * RolfStats
 */
export const SocialMediaStats = (props) => {

	// Loading state
	const [widgetState, setWidgetState] = useState({
		primary_field: 'post_reach',
		metrics: []
	});

	
	// Set default filter
	useEffect(() => {
		// Filter posts (this would be equivalent to a database query)
		const channel = channel_data.channels_by_week.find(channel => channel.channel === props.channel && channel.profile === props.profile);

		if (!channel) {
			return;
		}

		// Setup metric options based on lates entry
		const newest_entry = channel.data[0];
		const prev_entry = channel.data[1];
		const metrics = newest_entry.metrics.map(m => { 
			return { 
				key: m.key, 
				label: m.label,
				value: m.value,
				prev_value: prev_entry.metrics.find(pm => pm.key === m.key).value,
			}
		})

		// Update state
		setWidgetState(prevState => ({
			...prevState,
			metrics: metrics,
		}))

	}, []);

	/**
	 * Render
	 */
	return (
		<div className="widget-social-media-stats">			

			<div className="social-media-stats">
			

				<div className="social-media-primary-stats">

					<div className="social-media-stat-line-primary">
						<div className="social-media-stat-line-label">Subscribers</div>
						<div className="social-media-stat-line-value">5.567 (+100)</div>
					</div>

				</div>

				{/*
				<div className="social-media-secondary-stats">
					<div className="social-media-stat-line-secondary">
						<div className="stat-line-label">Label</div>
						<div className="stat-line-value">{(0).toLocaleString("da-DK")} 
						</div>
					</div>
				</div>
				*/}

			</div>
		</div>		
	); 
};

export default SocialMediaStats;
