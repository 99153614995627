import React from "react";
import { NavLink } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";



export const Navigation = (props) => {
	
	const {
	  logout,
	} = useAuth0();


	const toggleNavigationClass = () => {
		document.body.classList.toggle('navigation-open');
	}


  	const logoutWithRedirect = () => logout({
  		returnTo: window.location.origin,
  	});


	return (
		<div className="navigation-container">
			<div className="navigation-bar-mobile">
				<div className="navigation-bar-mobile-trigger-container">
					<div className="navigation-bar-mobile-trigger" onClick={() => toggleNavigationClass()}>

						<div className="navigation-bar-mobile-trigger-line-wrapper">
							<div className="navigation-bar-mobile-trigger-line"></div>
							<div className="navigation-bar-mobile-trigger-line"></div>
							<div className="navigation-bar-mobile-trigger-line"></div>
						</div>
					</div>
				</div>
				
				<div className="navigation-bar-mobile-heading">{props.page_title}</div>
			</div>

			<div className="navigation">
				
				<div className="menu-items-container">
					<ul>
						
						<li className="menu-item">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/dashboard">
								<div className="menu-item-icon">										
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M382.5 8.2H128.6C61.9 8.2 7.3 62.8 7.3 129.5v253.9c0 66.7 54.6 121.3 121.3 121.3h253.9c66.7 0 121.3-54.6 121.3-121.3V129.5C503.7 62.8 449.2 8.2 382.5 8.2zM77.2 78.1c13.8-13.8 32.1-21.4 51.4-21.4h253.9c19.3 0 37.6 7.6 51.4 21.4 13.8 13.8 21.4 32.1 21.4 51.4v42.1H55.8v-42.1c0-19.3 7.6-37.6 21.4-51.4zm0 356.6c-13.8-13.8-21.4-32.1-21.4-51.4V220.1h107.3v236.1h-34.5c-19.3 0-37.6-7.6-51.4-21.5zm356.6 0c-13.8 13.8-32.1 21.4-51.4 21.4H211.6v-236h243.7v163.3c-.1 19.3-7.7 37.5-21.5 51.3z"/></svg>
								</div>
								<div className="menu-item-label">Dashboard</div>
							</NavLink>
						</li>
						<li className="menu-item submenu-container-open">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/performance/menu-engineering" isActive={(match, location) => location.pathname.startsWith('/performance/menu-engineering') } >
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M506.1 486.4H370.9V25.6h135.2v460.8zm-95.2-40h55.2V65.6h-55.2v380.8zM141.1 486.4H5.9V236h135.2v250.4zm-95.2-40h55.2V276H45.9v170.4zM323.6 486.4H188.4V125.5h135.2v360.9zm-95.2-40h55.2V165.5h-55.2v280.9z"/></svg>
								</div>
								<div className="menu-item-label">Performance</div>								
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/performance/orders">App Orders</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/performance/menu-engineering">Menu Engineering</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/performance/waste">Spild</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/performance/competition">Konkurrence</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/performance/rolf">ROLF</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor menu-item-anchor--disabled" activeClassName="current-menu-item" to="/performance/webshop" onClick={e => e.preventDefault()}>Webshop</NavLink>	
									</li>
								</ul>
							</div>
						</li>

						<li className="menu-item">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/marketing/social-media" isActive={(match, location) => location.pathname.startsWith('/marketing') }>
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16.2 59v267.2c0 10.4 4.4 20.4 12.1 27.5 6.9 6.3 15.9 9.8 25.2 9.8 1.1 0 2.2 0 3.3-.1 14.8-1.3 27.2-11.7 31.9-25.4l157.5-38.8v.9H276v47.7h24.6v107c0 10.4 4.4 20.4 12.1 27.5 6.9 6.3 15.9 9.8 25.2 9.8 1.1 0 2.2 0 3.3-.1 19-1.7 33.9-18.2 33.9-37.6V347.7h24.6V300h29.8v-46c36.3-1 65.5-30.7 65.5-67.2s-29.2-66.3-65.5-67.2v-40H246.2v.7L88.5 46.8c-4.8-13.5-17-23.7-31.7-25-10.4-.9-20.8 2.6-28.5 9.6-7.7 7.1-12.1 17.1-12.1 27.6zm383.2 211H276.2V109.6h123.2V270zm30-46v-74.4c19.7.9 35.5 17.3 35.5 37.2S449.1 223 429.4 224zm-98.9 123.7H345v106.4c0 4-2.9 7.4-6.6 7.7-2.8.2-4.7-1.1-5.6-1.9-.9-.8-2.4-2.6-2.4-5.4V347.7zM306 300h63.6v17.7H306V300zm-59.8-31.8L90.7 306.5V78l155.5 32.9v157.3zM46.2 59c0-2.8 1.5-4.5 2.4-5.4.9-.8 2.8-2.1 5.6-1.9 3.7.3 6.6 3.7 6.6 7.7v266.2c0 4-2.9 7.4-6.6 7.7-2.8.3-4.7-1.1-5.6-1.9-.9-.8-2.4-2.6-2.4-5.4V59z"/><path d="M319.7 143.9h36.2c8.3 0 15 6.7 15 15s-6.7 15-15 15h-36.2c-8.3 0-15-6.7-15-15s6.7-15 15-15zM319.7 202.3h36.2c8.3 0 15 6.7 15 15s-6.7 15-15 15h-36.2c-8.3 0-15-6.7-15-15s6.7-15 15-15z"/></svg>
								</div>
								<div className="menu-item-label">Marketing</div>
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/marketing/profile-analytics" isActive={(match, location) => location.pathname.startsWith('/marketing/profile') }>Profiles</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/marketing/social-media">Sociale medier</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/marketing/website-and-seo">Website & SEO</NavLink>	
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/marketing/newsletter">Nyhedsbrev</NavLink>	
									</li>
									
								</ul>
							</div>
						</li>

					{/*
						<li className="menu-item">
							<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/inventories" isActive={(match, location) => location.pathname.startsWith('/settings') }>
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 506.1c-36.5 0-68.4-20.8-83.1-54.2-7.8-17.6-25.8-28-45-26-36.3 4-70.2-13.3-88.5-44.9-18.3-31.6-16.2-69.6 5.4-99.1 11.4-15.5 11.4-36.4 0-51.9-21.6-29.5-23.6-67.4-5.4-99.1S91.6 82.1 127.9 86c19.1 2.1 37.2-8.4 45-26C187.6 26.7 219.5 5.9 256 5.9c36.5 0 68.4 20.8 83.1 54.2 7.8 17.6 25.8 28 45 26 36.3-4 70.2 13.3 88.5 44.9 18.3 31.6 16.2 69.6-5.4 99.1-11.4 15.5-11.4 36.4 0 51.9 21.6 29.5 23.6 67.4 5.4 99.1-16.5 28.6-45.9 45.4-78.2 45.4-3.4 0-6.8-.2-10.3-.6-19.1-2.1-37.2 8.4-45 26-14.7 33.4-46.6 54.2-83.1 54.2zM132.8 378.8c35.7 0 68.3 20.9 83 54.2 7.2 16.4 22.3 26.2 40.2 26.2 17.9 0 33-9.8 40.2-26.2 16-36.4 53.4-58 92.9-53.7 17.8 1.9 33.8-6.2 42.8-21.7s8-33.5-2.6-47.9c-23.5-32.1-23.5-75.2 0-107.3 10.6-14.5 11.6-32.4 2.6-47.9s-25-23.7-42.8-21.7c-3.3.4-6.7.5-10 .5-35.7 0-68.3-20.9-83-54.2-7.1-16.5-22.2-26.3-40.1-26.3-17.9 0-33 9.8-40.2 26.2-16.1 36.4-53.4 58-92.9 53.7-17.9-2-33.9 6.2-42.9 21.7s-8 33.5 2.6 47.9c23.5 32.1 23.5 75.2 0 107.3-10.6 14.5-11.5 32.5-2.6 48 9 15.5 25 23.7 42.8 21.7 3.4-.3 6.7-.5 10-.5z"/><path d="M256 337.6c-45 0-81.6-36.6-81.6-81.6s36.6-81.6 81.6-81.6 81.6 36.6 81.6 81.6-36.6 81.6-81.6 81.6zm0-125.7c-24.3 0-44.1 19.8-44.1 44.1s19.8 44.1 44.1 44.1 44.1-19.8 44.1-44.1-19.8-44.1-44.1-44.1z"/></svg>
								</div>
								<div className="menu-item-label">Indstillinger</div>
							</NavLink>
							<div className="submenu-container">
								<ul className="submenu">
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/inventories">Targets</NavLink>
									</li>
									<li>
										<NavLink className="menu-item-anchor" activeClassName="current-menu-item" to="/settings/suppliers">Integrationer</NavLink>	
									</li>
								</ul>
							</div>
						</li>
					*/}
						
						<li className="menu-item">
							<button href="#" className="menu-item-anchor" onClick={() => logoutWithRedirect()}>
								<div className="menu-item-icon">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M252.7 229.4c-13.5 0-24.4-10.9-24.4-24.4V43.3c0-13.5 10.9-24.4 24.4-24.4s24.4 10.9 24.4 24.4V205c0 13.4-10.9 24.4-24.4 24.4z"/><path d="M256 493.2c-58.4 0-113.4-23-154.7-64.6-41.2-41.6-64-96.9-64-155.8 0-65.6 28.7-127.4 78.8-169.5 10.3-8.7 25.8-7.3 34.4 3 8.7 10.3 7.3 25.8-3 34.4-39 32.7-61.4 80.8-61.4 132 0 94.6 76.1 171.5 169.7 171.5s169.7-76.9 169.7-171.5c0-51.2-22.4-99.3-61.4-132-10.3-8.7-11.7-24.1-3-34.4 8.7-10.3 24.1-11.7 34.4-3 50.1 42 78.8 103.8 78.8 169.5 0 58.8-22.7 114.1-64 155.8-40.9 41.6-95.9 64.6-154.3 64.6z"/></svg>
								</div>
								<div className="menu-item-label">Log ud</div>
							</button>
						</li>

						
					</ul>
				</div>

			</div>
		</div>			
	); 
};

export default Navigation;
