import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import ReviewStars from '../ReviewStars';
//import Loading from "../components/Loading";

export const DepartmentTable = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [departmentsData, setDepartmentsData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setIsLoading(true);
			try {
				// Get access token
				const access_token = await getAccessTokenSilently();
				console.log('at', access_token);
				// Get departments data
				const departmentsDataResponse = await axios.get(`${api_url}/aggregation/department-data/`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
						department_ids: props.department_ids,
	                    start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
	                    end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
	                    compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
	                    compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
					}
				});

				setDepartmentsData(departmentsDataResponse.data.departments);
				

				setIsLoading(false);
				
			} catch (e) {
        		console.error(e);
      		}

		})();		

	}, [
		getAccessTokenSilently, 
		props.department_ids,
		props.start_date, 
		props.end_date, 
		props.compared_start_date,
		props.compared_end_date,
	]);


	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	let combined_total = 0;
	let combined_total_compared = 0;
	let combined_total_percentage = 0;

	let combined_orders = 0;
	let combined_orders_compared = 0;
	let combined_orders_percentage = 0;

	let combined_basket_size = 0;
	let combined_basket_size_compared = 0;
	let combined_basket_size_percentage = 0;


	let combined_review_score = 0;
	let combined_review_count = 0;

	let combined_salary = 0;
	let combined_salary_ratio = 0;
	let combined_salary_compared = 0;
	let combined_salary_compared_ratio = 0;
	let combined_salary_ratio_percentage = 0;


	const updown = (x, y) => {
		if (x > y) {
			return 'Op fra';	
		} else if(x < y) {
			return 'Ned fra';
		}	
	}

	return (
		<div className="widget-v2-container">
		    <div className={`widget-v2 ${loadingClass}`}>
		        <div className="widget-v2-header">
		            <h2 className="widget-v2-title">Butiksoverblik</h2>
		        </div>
				
				<div className="widget-v2-body">
						
					{
						isLoading ? (
							<div className="spinner-widget-container">
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							</div>
						) : (

							<div className="widget-department-table">

								<div className="widget-department-table-inner">

									<table className="department-table-table">
										<thead>
											<tr className="department-table-header">
												<th>Afdeling</th>
												<th>Omsætning</th>
												<th>Antal ordrer</th>
												<th>Basket Size</th>
												<th>Løn [%]</th>
												{/*
												<th>Anmeldelser</th>
												*/}
											</tr>
										</thead>
										<tbody>
										
									
											{

												departmentsData.map((entry, i) => {

													// Revenue
													//let total_revenue = (entry.onlinepos.revenue_excl_vat + entry.orderyoyo.revenue_excl_vat + entry.wolt.revenue_excl_vat + entry.heaps.revenue_excl_vat) / 100;
													let total_revenue = (entry.onlinepos.revenue_excl_vat + entry.orderyoyo.revenue_excl_vat + entry.heaps.revenue_excl_vat) / 100;
													// let total_revenue_compared = (entry.onlinepos_compared_to.revenue_excl_vat + entry.orderyoyo_compared_to.revenue_excl_vat + entry.wolt_compared_to.revenue_excl_vat + entry.heaps_compared_to.revenue_excl_vat) / 100;
													let total_revenue_compared = (entry.onlinepos_compared_to.revenue_excl_vat + entry.orderyoyo_compared_to.revenue_excl_vat + entry.heaps_compared_to.revenue_excl_vat) / 100;
													let total_compare_percent = ( ( total_revenue - total_revenue_compared ) / total_revenue_compared ) * 100; // (y2 - y1) / y1 * 100

													combined_total += total_revenue;
													combined_total_compared += total_revenue_compared;
													combined_total_percentage = ( ( combined_total - combined_total_compared ) / combined_total_compared ) * 100;

													// Orders
													// let total_orders = entry.onlinepos.order_count + entry.orderyoyo.order_count + entry.wolt.order_count + entry.heaps.order_count;
													let total_orders = entry.onlinepos.order_count + entry.orderyoyo.order_count + entry.heaps.order_count;
													// let total_orders_compared = entry.onlinepos_compared_to.order_count + entry.orderyoyo_compared_to.order_count + entry.wolt_compared_to.order_count + entry.heaps_compared_to.order_count;
													let total_orders_compared = entry.onlinepos_compared_to.order_count + entry.orderyoyo_compared_to.order_count + entry.heaps_compared_to.order_count;
													let total_orders_compare_percent = ( ( total_orders - total_orders_compared ) / total_orders_compared ) * 100;

													combined_orders += total_orders;
													combined_orders_compared += total_orders_compared;
													combined_orders_percentage = ( ( combined_orders - combined_orders_compared ) / combined_orders_compared ) * 100;

													// Basket Size
													let basket_size = total_revenue / total_orders;
													let basket_size_compared = total_revenue_compared / total_orders_compared;
													let basket_size_compare_percent = ( ( basket_size - basket_size_compared ) / basket_size_compared ) * 100;

													combined_basket_size = combined_total / combined_orders;
													combined_basket_size_compared = combined_total_compared / combined_orders_compared;
													combined_basket_size_percentage = ( ( combined_basket_size - combined_basket_size_compared ) / combined_basket_size_compared ) * 100;
													
													// Salary Percentage
													let salary = parseInt((entry.salary * 1.155) / 100);
													let salary_ratio = (salary / total_revenue) * 100;
													salary_ratio = isFinite(salary_ratio) ? parseInt(salary_ratio) : 0;

													let salary_compared = parseInt((entry.salary_compared * 1.155) / 100);
													let salary_compared_ratio = (salary_compared / total_revenue_compared) * 100;
													salary_compared_ratio = isFinite(salary_compared_ratio) ? parseInt(salary_compared_ratio) : 0;

													let salary_ratio_percentage = ( ( salary_ratio - salary_compared_ratio ) / salary_compared_ratio ) * 100;

													combined_salary += entry.salary;
													let combined_salary_displayed = parseInt((combined_salary * 1.155) / 100);
													combined_salary_ratio = (combined_salary_displayed / combined_total) * 100;
													combined_salary_ratio = isFinite(combined_salary_ratio) ? parseInt(combined_salary_ratio) : 0;
													
													combined_salary_compared += entry.salary_compared;
													combined_salary_compared_ratio = (combined_salary_compared / combined_total_compared) * 100;
													combined_salary_compared_ratio = isFinite(combined_salary_compared_ratio) ? parseInt(combined_salary_compared_ratio) : 0;
													
													combined_salary_ratio_percentage = ( ( combined_salary_ratio - combined_salary_compared_ratio ) / combined_salary_compared_ratio ) * 100;

													// Reviews
													// let wolt_score = entry.wolt.review_score;
													// let wolt_review_count = entry.wolt.review_count;
												
													// combined_review_score += wolt_score * wolt_review_count;
													// combined_review_count += wolt_review_count;

													return (
															<tr key={i}>
														    	<td className="department-table-field">
														    		<div className="department-table-field-label">{entry.department_name}</div>
														    	</td>
														    	<td className="department-table-field">
														    		
														    		<div className="department-table-field-main-label-container">
															    		<div className="department-table-field-main-label">{Math.round(total_revenue).toLocaleString("da-DK")} kr.</div>
															    		{
															    			total_revenue > total_revenue_compared && isFinite(total_compare_percent) && (
															    				<div className="tag tag--positive">+{total_compare_percent.toFixed(2)}%</div>
															    			)
															    		}

															    		{
															    			total_revenue < total_revenue_compared && isFinite(total_compare_percent) && (
															    				<div className="tag tag--negative">{total_compare_percent.toFixed(2)}%</div>
															    			)
															    		}
															    	</div>
															    	<div className="department-table-field-secondary-label-container">
															    		<div className="department-table-field-secondary-label">{updown(total_revenue, total_revenue_compared)} {Math.round(total_revenue_compared).toLocaleString("da-DK")} kr.</div>
															    	</div>
														    	</td>
														    
														    	<td className="department-table-field">
														    		<div className="department-table-field-main-label-container">
															    		<div className="department-table-field-main-label">{total_orders} ordrer</div>
															    		{
															    			total_orders > total_orders_compared && isFinite(total_orders_compare_percent) && (
															    				<div className="tag tag--positive">+{total_orders_compare_percent.toFixed(2)}%</div>
															    			)
															    		}

															    		{
															    			total_orders < total_orders_compared && isFinite(total_orders_compare_percent) && (
															    				<div className="tag tag--negative">{total_orders_compare_percent.toFixed(2)}%</div>
															    			)
															    		}
															    	</div>

															    	<div className="department-table-field-secondary-label-container">
															    		<div className="department-table-field-secondary-label">{updown(total_orders, total_orders_compared)} {total_orders_compared}</div>
															    	</div>
														    		
														    	</td>
														 
														    	<td className="department-table-field">
														    		<div className="department-table-field-main-label-container">
															    		<div className="department-table-field-main-label">{basket_size.toFixed(2)} kr.</div>

															    		{
															    			basket_size > basket_size_compared && isFinite(basket_size_compare_percent) && (
															    				<div className="tag tag--positive">+{basket_size_compare_percent.toFixed(2)}%</div>
															    			)
															    		}

															    		{
															    			basket_size < basket_size_compared && isFinite(basket_size_compare_percent) && (
															    				<div className="tag tag--negative">{basket_size_compare_percent.toFixed(2)}%</div>
															    			)
															    		}
															    	</div>
															    	<div className="department-table-field-secondary-label-container">
															    		<div className="department-table-field-secondary-label">
															    			{
															    				isFinite(basket_size_compared) ? (
															    					`${updown(basket_size, basket_size_compared)} ${basket_size_compared.toFixed(2)}`
															    				) : (
															    					'-'
															    				)
															    				
															    			}
															    		</div>
															    	</div>
														    	</td>

												    	    	
												    	    	<td className="department-table-field">
												    	    		<div className="department-table-field-main-label-container">
												    		    		<div className="department-table-field-main-label">{salary.toLocaleString("da-DK")} kr.</div>

												    		    		{
												    		    			salary_ratio <= 27 && (
												    		    				<div className="tag tag--positive">{salary_ratio}%</div>
												    		    			)
												    		    		}

												    		    		{
												    		    			salary_ratio > 27 && salary_ratio < 33 && (
												    		    				<div className="tag tag--warning">{salary_ratio}%</div>
												    		    			)
												    		    		}

												    		    		{
												    		    			salary_ratio >= 33 && (
												    		    				<div className="tag tag--negative">{salary_ratio}%</div>
												    		    			)
												    		    		}
												    		    		
												    		    	</div>

												    		    	<div className="department-table-field-secondary-label-container">
												    		    		<div className="department-table-field-secondary-label">{updown(salary, salary_compared)} {salary_compared.toLocaleString("da-DK")} kr.</div>
												    		    	</div>
												    	    	</td>
												    	    	

														    	{/*
														    	<td className="department-table-field">
												    	    		<div className="department-table-field-main-label-container">
												    		    		{
												    		    			wolt_score > 0 ? (
												    		    				<div className="department-table-field-main-label">
												    		    					<div className="department-table-field-main-label-stars">
												    		    						
												    		    						<ReviewStars rating={wolt_score} total={5}/>

													    		    					<span>{wolt_score.toFixed(1)}</span>
												    		    					</div>
												    		    				</div>
												    		    			) : (
												    		    				<div className="department-table-field-main-label">-</div>
												    		    			)
												    		    		}
												    		    	</div>

												    		    	<div className="department-table-field-secondary-label-container">
												    		    		{
												    		    			wolt_score > 0 ? (
												    		    				<div className="department-table-field-secondary-label">
												    		    					Baseret på {wolt_review_count} 
												    		    					{
												    		    						wolt_review_count === 1 ? (
												    		    							<span> anmeldelse</span>
												    		    						) : (
												    		    							<span> anmeldelser</span>
												    		    						)
												    		    					}
												    		    				</div>
												    		    			) : (
												    		    				<div className="department-table-field-secondary-label">Ingen anmeldelser</div>
												    		    			)	
												    		    		}
												    		    	</div>
												    	    	</td>
												    	    	*/}
														  	</tr>

													)
												})

											}
												<tr className="department-table-totals-row">
											    	<td className="department-table-field">
											    		<div className="department-table-field-label">TOTAL</div>
											    	</td>
											    	<td className="department-table-field">
								    		    		<div className="department-table-field-main-label-container">
								    			    		<div className="department-table-field-main-label">{Math.round(combined_total).toLocaleString("da-DK")} kr.</div>
								    			    		{
								    			    			combined_total > combined_total_compared && isFinite(combined_total_percentage) && (
								    			    				<div className="tag tag--positive">+{combined_total_percentage.toFixed(2)}%</div>
								    			    			)
								    			    		}

								    			    		{
								    			    			combined_total < combined_total_compared && isFinite(combined_total_percentage) && (
								    			    				<div className="tag tag--negative">{combined_total_percentage.toFixed(2)}%</div>
								    			    			)
								    			    		}
								    			    	</div>
								    			    	<div className="department-table-field-secondary-label-container">
								    			    		<div className="department-table-field-secondary-label">{updown(combined_total, combined_total_compared)} {combined_total_compared.toLocaleString("da-DK")} kr.</div>
								    			    	</div>
											    	</td>
											    	
											    	<td className="department-table-field">

											    		<div className="department-table-field-main-label-container">
								    			    		<div className="department-table-field-main-label">{combined_orders} ordrer</div>
								    			    		{
								    			    			combined_orders > combined_orders_compared && isFinite(combined_orders_percentage) && (
								    			    				<div className="tag tag--positive">+{combined_orders_percentage.toFixed(2)}%</div>
								    			    			)
								    			    		}

								    			    		{
								    			    			combined_orders < combined_orders_compared && isFinite(combined_orders_percentage) && (
								    			    				<div className="tag tag--negative">{combined_orders_percentage.toFixed(2)}%</div>
								    			    			)
								    			    		}
								    			    	</div>
								    			    	<div className="department-table-field-secondary-label-container">
								    			    		<div className="department-table-field-secondary-label">{updown(combined_orders, combined_orders_compared)} {combined_orders_compared}</div>
								    			    	</div>

											    	</td>

										    		{
										    			combined_total > 0 ? (
										    				<td className="department-table-field">	
											    				<div className="department-table-field-main-label-container">
							    		    			    		<div className="department-table-field-main-label">{combined_basket_size.toFixed(2)} kr.</div>
							    		    			    		{
							    		    			    			combined_basket_size > combined_basket_size_compared && (
							    		    			    				<div className="tag tag--positive">+{combined_basket_size_percentage.toFixed(2)}%</div>
							    		    			    			)
							    		    			    		}

							    		    			    		{
							    		    			    			combined_basket_size < combined_basket_size_compared && (
							    		    			    				<div className="tag tag--negative">{combined_basket_size_percentage.toFixed(2)}%</div>
							    		    			    			)
							    		    			    		}
							    		    			    	</div>
							    		    			    	<div className="department-table-field-secondary-label-container">
							    		    			    		<div className="department-table-field-secondary-label">{updown(combined_basket_size, combined_basket_size_compared)} {combined_basket_size_compared.toFixed(2)} kr.</div>
							    		    			    	</div>
							    		    			    </td>
										    		
										    			) : (
										    				<td className="department-table-field">	
										    					<div className="department-table-field-label">0 kr.</div>
										    				</td>
										    			)
										    		}

										    		<td className="department-table-field">	
									    				<div className="department-table-field-main-label-container">
					    		    			    		<div className="department-table-field-main-label">{parseInt((combined_salary * 1.155) / 100).toLocaleString("da-DK")} kr.</div>
					    		    			    		{
					    		    			    			combined_salary_ratio <= 27 && (
					    		    			    				<div className="tag tag--positive">{combined_salary_ratio}%</div>
					    		    			    			)
					    		    			    		}

					    		    			    		{
					    		    			    			combined_salary_ratio > 27 && combined_salary_ratio < 33 && (
					    		    			    				<div className="tag tag--warning">{combined_salary_ratio}%</div>
					    		    			    			)
					    		    			    		}

					    		    			    		{
					    		    			    			combined_salary_ratio >= 33 && (
					    		    			    				<div className="tag tag--negative">{combined_salary_ratio}%</div>
					    		    			    			)
					    		    			    		}
					    		    			    	</div>
					    		    			    	<div className="department-table-field-secondary-label-container">
					    		    			    		<div className="department-table-field-secondary-label">
					    		    			    			
										    		    		<div className="department-table-field-secondary-label">{updown(combined_salary, combined_salary_compared)} {parseInt((combined_salary_compared * 1.155) / 100).toLocaleString("da-DK")} kr.</div>
										    		    			
					    		    			    		</div>
					    		    			    	</div>
					    		    			    </td>

					    		    			    {/*
										    		<td className="department-table-field">	
									    				<div className="department-table-field-main-label-container">
					    		    			    		<div className="department-table-field-main-label">
			    			    		    					<div className="department-table-field-main-label-stars">
			    			    		    					
			    			    		    						<ReviewStars rating={(combined_review_score / combined_review_count)} total={5}/>
			    			    		    						{
			    			    		    							(combined_review_score / combined_review_count) > 1 && (
			    			    		    								<span>{(combined_review_score / combined_review_count).toFixed(1)	}</span>
			    			    		    							)	
			    			    		    						}
			    				    		    					
			    			    		    					</div>
					    		    			    		</div>
					    		    			    	</div>
					    		    			    	<div className="department-table-field-secondary-label-container">
					    		    			    		<div className="department-table-field-secondary-label">
					    		    			    			{
										    		    			combined_review_score > 0 ? (
										    		    				<div className="department-table-field-secondary-label">Baseret på {combined_review_count} anmeldelser</div>
										    		    			) : (
										    		    				<div className="department-table-field-secondary-label">Ingen anmeldelser</div>
										    		    			)
										    		    		}
					    		    			    		</div>
					    		    			    	</div>
					    		    			    </td>
					    		    			    */}
										    		
											  	</tr>
										</tbody>
									</table>

								</div>


							</div>

						)
					}

			
				</div>						        
		        
		    </div>
		</div>	
	); 
};

export default DepartmentTable;

