import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

// Charts
import WebsiteStats from "../components/widgets/marketing/WebsiteStats";
import TimelineWebsite from "../components/widgets/marketing/TimelineWebsite";
import SEOKeywords from "../components/widgets/marketing/SEOKeywords";
import WebsiteContentStats from "../components/widgets/marketing/WebsiteContentStats";

export const WebsiteAndSEO = (props) => {


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Website & SEO (Uge 31)</h1>

									<div className="page-description">
										<p>
											Denne side bliver IKKE opdateret automatisk! Sidst opdatert: 16 aug 2021
										</p>
									</div>
								</div>

							</div>
						
							<div className="page-charts">
							
								<div className="page-chart-with-sidebar-element">
									
									<div className="page-chart-with-sidebar-element-sidebar">
										<WebsiteStats/>
										<WebsiteContentStats/>
									</div>

									<div className="page-chart-with-sidebar-element-main">
										<TimelineWebsite/>
									</div>
								</div>

								<SEOKeywords/>
							
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(WebsiteAndSEO, {
  onRedirecting: () => <Loading />,
});
