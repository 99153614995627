import React, {useState, useEffect, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Select from 'react-select';
import { ResponsiveLine } from '@nivo/line';

const moment = extendMoment(Moment);

export const TimelineRevenue = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [timelineState, setTimelineState] = useState({
		frequency: 'hour',

		source_filter: 'all',
		timeline_accumulate: false, // Accumulate
		timeline_salary_percentage: false, // Calculate salary percentages

		revenue_by_time: [], 
		revenue_by_time_orderyoyo: [],
		revenue_by_time_wolt: [],
		revenue_by_time_heaps: [],

		shifts: [],
		salary_by_time: [],
	});

	// Loading state
	const [isTakeawayOnly, setIsTakeawayOnly] = useState(false);

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids,
		props.frequency,
		isTakeawayOnly,
	]);

	/**
	 * Automatically detect what frequency to use
	 */
	const getFrequency = () => {

		let frequency = '';
		if (props.frequency.value) {
			frequency = props.frequency.value.value;
		}

		return frequency;
	}

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setTimelineState({
			...timelineState,
			source_filter: new_mode
		});
	}

	/**
	 * Toggle accumulator
	 */
	const toggleTimelineAccumulator = () => {

		const current = timelineState.timeline_accumulate;

		setTimelineState({
			...timelineState,
			timeline_accumulate: !current,
		})
	}

	/**
	 * Toggle timeline Salary percentages
	 */
	const toggleTimelineSalaryPercentage = () => {

		const current = timelineState.timeline_salary_percentage;

		setTimelineState({
			...timelineState,
			timeline_salary_percentage: !current,
		})
	}

	/**
	 * Toggle timeline Takeaway
	 */
	const toggleTimelineTakeaway = () => {

		const current = isTakeawayOnly;
		setIsTakeawayOnly(!current)
	}



	/**
	 * Handle data load
	 */
	const loadAllData = useCallback(() => {
		
		//console.log('load data for timelineMain_Revenue');

		setIsLoading(true);

		const aggregatedRevenuePromise = loadAggregatedRevenue(isTakeawayOnly);

		const punchClockPromise = loadPunchClocks();

		Promise.all([aggregatedRevenuePromise, punchClockPromise])
			.then( values => {

				//console.log('Data for timelineMain_Revenue has loaded');

				const frequency = getFrequency();

				const revenueData = values[0];
				const salaryData = values[1];

				console.log('sdf', revenueData);

				let shifts = values[1].data.punch_clocks;
			
				shifts = shifts.filter(shift => shift.status !== 'Open');

				setTimelineState({ 
					...timelineState,
					frequency: frequency,
					revenue_by_time: revenueData.data.revenue_by_time,
					revenue_by_time_orderyoyo: revenueData.data.revenue_by_time_orderyoyo,
					revenue_by_time_wolt: revenueData.data.revenue_by_time_wolt,
					revenue_by_time_heaps: revenueData.data.revenue_by_time_heaps,
					shifts: shifts,
					salary_by_time: salaryData.data.salary_by_time,

				});

				setIsLoading(false);

			})
			.catch(error => {
				setTimelineState({ 
					...timelineState,
					revenue_by_time: [], 
					revenue_by_time_orderyoyo: [],
					revenue_by_time_wolt: [],
					revenue_by_time_heaps: [],
				})

				setIsLoading(true);
				console.log(error);
			}
		);

	},[
		props.start_date, 
		props.end_date, 
		props.department_ids,
		isTakeawayOnly,
	])

	/**
	 * Load sales data
	 */
	const loadAggregatedRevenue = async () => {
			
		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/revenue/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: getFrequency(), // hour, day, month or year
				compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
                compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
                takeawayOnly: isTakeawayOnly,
			}
		});

		return axiosPromise;

	}

	/**
	 * Load Punchclock data
	 */
	const loadPunchClocks = async () => {
		
		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/salary/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: getFrequency(), // hour, day, month or year
				compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
                compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}

	/**
	 * Prepare the sales data for the timeline
	 */
	const prepareData = () => {

		let data = [{
			"id": "Omsætning",
			"color": "hsl(223, 70%, 54%)",
			"data": []
		}];

		let salary = [{
			"id": "Lønudgifter",
			"color": "hsl(223, 70%, 54%)",
			"data": []
		}];

		const frequency = getFrequency();

		if (frequency === 'hour') {
			data = prepareDataHourly();
			salary = prepareSalaryHourly();
		} else if (frequency === 'day') {
			data = prepareDataDaily();
			salary = prepareSalaryDaily();
		} else if (frequency === 'week') {
			data = prepareDataWeekly();
			salary = prepareSalaryWeekly();
		} else if (frequency === 'month') {
			data = prepareDataMonthly();
			salary = prepareSalaryMonthly();
		} else if (frequency === 'year') {
			data = prepareDataYearly();
			salary = prepareSalaryYearly();
		}

		if (timelineState.timeline_accumulate) {

			// Accumulate data
			let accumulated_revenue = 0;
			data[0].data.map(dataset => {
				accumulated_revenue += dataset.y;
				dataset.y = accumulated_revenue;
				return dataset;
			})


			let accumulated_salary = 0;
			salary[0].data.map(dataset => {
				accumulated_salary += dataset.y;
				dataset.y = accumulated_salary;
				return dataset;
			})

		}
		

		if (timelineState.timeline_salary_percentage) {

			const revenue_data = data[0].data;
			const salary_data = salary[0].data;

			let alerts = [{
				"id": ">=2 på vagt, løn > 70%",
				"color": "hsl(339, 70%, 50%)",
				"data": [
					{ x: '00.00', y: null },
					{ x: '01.00', y: null },
					{ x: '02.00', y: null },
					{ x: '03.00', y: null },
					{ x: '04.00', y: null },
					{ x: '05.00', y: null },
					{ x: '06.00', y: null },
					{ x: '07.00', y: null },
					{ x: '08.00', y: null },
					{ x: '09.00', y: null },
					{ x: '10.00', y: null },
					{ x: '11.00', y: null },
					{ x: '12.00', y: null },
					{ x: '13.00', y: null },
					{ x: '14.00', y: null },
					{ x: '15.00', y: null },
					{ x: '16.00', y: null },
					{ x: '17.00', y: null },
					{ x: '18.00', y: null },
					{ x: '19.00', y: null },
					{ x: '20.00', y: null },
					{ x: '21.00', y: null },
					{ x: '22.00', y: null },
					{ x: '23.00', y: null },
				],
			}];

			let salary_percentage_data = revenue_data.map((revenue_point, i) => {

				const salary_datapoint = salary_data[i];
				//console.log('s', salary_datapoint);
				const calculated_percentage = (salary_datapoint.y / revenue_point.y) * 100;
				

				if ( isFinite(calculated_percentage) && revenue_point.y > 0 ) {
				
					if ( salary_datapoint.total_minutes >= 120 && calculated_percentage > 70 ) {

						// Find hour
						const find_hour = alerts[0].data.findIndex(entry => entry.x === revenue_point.x )
						if (find_hour) {
							alerts[0].data[find_hour] = {
								x: revenue_point.x,
								y: parseInt(calculated_percentage)
							};	
						}
					}

					return {
						x: revenue_point.x,
						y: parseInt(calculated_percentage)
					};

				} else {
					return {
						x: revenue_point.x,
						y: 0
					};	
				}				

				
			});


			let salary_percentages = [{
				"id": "Lønprocent",
				"color": "hsl(166, 67%, 49%)",
				"data": salary_percentage_data,
			}];
			

			if (props.frequency === 'hour') {
				return [salary_percentages[0], alerts[0]];
			} else {
				return [salary_percentages[0]];
			}




		} else {

			const source_filter = timelineState.source_filter;
			if (source_filter === 'all') {
				return [data[0], salary[0]];	
			} else {
				return [data[0]];
			}

		}

	}


	/**
	 * Prepare the sales data for the timeline (hourly)
	 */
	const prepareDataHourly = () => {

		// Get aggregated revenue data
		const revenue = timelineState.revenue_by_time;
		const revenue_orderyoyo = timelineState.revenue_by_time_orderyoyo;
		const revenue_wolt = timelineState.revenue_by_time_wolt;
		const revenue_heaps = timelineState.revenue_by_time_heaps;
		const source_filter = timelineState.source_filter;

		// setup (relevant) hours array
		let hours = [
			{ x: '00.00', y: 0 },
			{ x: '01.00', y: 0 },
			{ x: '02.00', y: 0 },
			{ x: '03.00', y: 0 },
			{ x: '04.00', y: 0 },
			{ x: '05.00', y: 0 },
			{ x: '06.00', y: 0 },
			{ x: '07.00', y: 0 },
			{ x: '08.00', y: 0 },
			{ x: '09.00', y: 0 },
			{ x: '10.00', y: 0 },
			{ x: '11.00', y: 0 },
			{ x: '12.00', y: 0 },
			{ x: '13.00', y: 0 },
			{ x: '14.00', y: 0 },
			{ x: '15.00', y: 0 },
			{ x: '16.00', y: 0 },
			{ x: '17.00', y: 0 },
			{ x: '18.00', y: 0 },
			{ x: '19.00', y: 0 },
			{ x: '20.00', y: 0 },
			{ x: '21.00', y: 0 },
			{ x: '22.00', y: 0 },
			{ x: '23.00', y: 0 },
		];

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'onlinepos') {
			
			for (const revenue_item of revenue) {
				
				// Get the hour number
				const hour_number = moment.utc(revenue_item.date).format('HH.00');

				// Find index for current hour
				const x_index = hours.findIndex(hour => hour.x === hour_number );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					hours[x_index].y +=	revenue_item.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'orderyoyo') {
			for (const revenue_item_orderyoyo of revenue_orderyoyo) {
				
				// Get the hour number
				const hour_number = moment.utc(revenue_item_orderyoyo.date).format('HH.00');

				// Find index for current hour
				const x_index = hours.findIndex(hour => hour.x === hour_number );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					hours[x_index].y +=	revenue_item_orderyoyo.revenue_excl_vat;
				}

			}
		}


		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'wolt') {
			for (const revenue_item_wolt of revenue_wolt) {
				
				// Get the hour number
				// const hour_number = moment(revenue_item_wolt.date).format('HH.00');
				const hour_number = moment.utc(revenue_item_wolt.date).format('HH.00');

				// Find index for current hour
				const x_index = hours.findIndex(hour => hour.x === hour_number );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					hours[x_index].y +=	revenue_item_wolt.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'heapsgo') {
			for (const revenue_item_heaps of revenue_heaps) {
				
				// Get the hour number
				const hour_number = moment(revenue_item_heaps.date).format('HH.00');

				// Find index for current hour
				const x_index = hours.findIndex(hour => hour.x === hour_number );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					hours[x_index].y +=	revenue_item_heaps.revenue_excl_vat;
				}

			}
		}

		// Format cents to dollar (kroner)
		const formatted_hours = hours.map( hour => { return { x: hour.x, y: parseInt(hour.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Omsætning",
			"color": "hsl(223, 70%, 54%)",
			"data": formatted_hours
		}];

		return timelineConfig;
	}

	/**
	 * Prepare the sales data for the timeline (Daily)
	 */
	const prepareDataDaily = () => {

		// Get aggregated revenue data
		const revenue = timelineState.revenue_by_time;
		const revenue_orderyoyo = timelineState.revenue_by_time_orderyoyo;
		const revenue_wolt = timelineState.revenue_by_time_wolt;
		const revenue_heaps = timelineState.revenue_by_time_heaps;
		const source_filter = timelineState.source_filter;

		const start_date = props.start_date;
		const end_date = props.end_date;

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date <= end_date) {

			data.push({
				x: temp_date.format('YYYY-MM-DD'),
				y: 0
			});

			temp_date.add( 1, 'days');
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'onlinepos') {
			for (const revenue_item of revenue) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item.date).format('YYYY-MM-DD');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'orderyoyo') {
			for (const revenue_item_orderyoyo of revenue_orderyoyo) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item_orderyoyo.date).format('YYYY-MM-DD');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item_orderyoyo.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'wolt') {
			for (const revenue_item_wolt of revenue_wolt) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item_wolt.date).format('YYYY-MM-DD');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item_wolt.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'heapsgo') {
			for (const revenue_item_heaps of revenue_heaps) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item_heaps.date).format('YYYY-MM-DD');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item_heaps.revenue_excl_vat;
				}

			}
		}

		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('dddd DD/MM'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Omsætning",
			"color": "hsl(223, 70%, 54%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}


	/**
	 * Prepare the sales data for the timeline (Weekly)
	 */
	const prepareDataWeekly = () => {

		// Get aggregated revenue data
		const revenue = timelineState.revenue_by_time;
		const revenue_orderyoyo = timelineState.revenue_by_time_orderyoyo;
		const revenue_wolt = timelineState.revenue_by_time_wolt;
		const revenue_heaps = timelineState.revenue_by_time_heaps;
		const source_filter = timelineState.source_filter;

		const start_date = props.start_date;
		const end_date = props.end_date;

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date.format('YYYY-WW') <= moment(end_date).format('YYYY-WW')) {

			data.push({
				x: temp_date.format('YYYY-WW'),
				y: 0
			});

			temp_date.add( 1, 'weeks');
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'onlinepos') {
			for (const revenue_item of revenue) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item.date, 'YYYY-WW').format('YYYY-WW');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );

				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'orderyoyo') {
			for (const revenue_item_orderyoyo of revenue_orderyoyo) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item_orderyoyo.date, 'YYYY-WW').format('YYYY-WW');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item_orderyoyo.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'wolt') {
			for (const revenue_item_wolt of revenue_wolt) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item_wolt.date, 'YYYY-WW').format('YYYY-WW');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item_wolt.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'heapsgo') {
			for (const revenue_item_heaps of revenue_heaps) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item_heaps.date, 'YYYY-WW').format('YYYY-WW');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item_heaps.revenue_excl_vat;
				}

			}
		}

		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x, 'YYYY-WW').format('W'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Omsætning",
			"color": "hsl(223, 70%, 54%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}

	/**
	 * Prepare the sales data for the timeline (Daily)
	 */
	const prepareDataMonthly = () => {


		// Get aggregated revenue data
		const revenue = timelineState.revenue_by_time;
		const revenue_orderyoyo = timelineState.revenue_by_time_orderyoyo;
		const revenue_wolt = timelineState.revenue_by_time_wolt;
		const revenue_heaps = timelineState.revenue_by_time_heaps;
		const source_filter = timelineState.source_filter;

		const start_date = props.start_date;
		const end_date = props.end_date;

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date.format('YYYY-MM') <= moment(end_date).format('YYYY-MM') ) {
			
			data.push({
				x: moment( temp_date ).format('YYYY-MM'),
				y: 0
			});

			temp_date.month( temp_date.month() + 1 );
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'onlinepos') {
			for (const revenue_item of revenue) {

				// Get comparable date string 
				const date_string = moment(revenue_item.date).format('YYYY-MM');
				
				//console.log(date_string);

				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y += revenue_item.revenue_excl_vat;
				}

			}
		}


		// Sum all revenue based on montlhy of sale
		if (source_filter === 'all' || source_filter === 'orderyoyo') {
			for (const revenue_item_orderyoyo of revenue_orderyoyo) {

				// Get comparable date string 
				const date_string = moment(revenue_item_orderyoyo.date).format('YYYY-MM');
				
				//console.log(date_string);

				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y += revenue_item_orderyoyo.revenue_excl_vat;
				}

			}
		}


		// Sum all revenue based on montlhy of sale
		if (source_filter === 'all' || source_filter === 'wolt') {
			for (const revenue_item_wolt of revenue_wolt) {

				// Get comparable date string 
				const date_string = moment(revenue_item_wolt.date).format('YYYY-MM');
				
				//console.log(date_string);

				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y += revenue_item_wolt.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on montlhy of sale
		if (source_filter === 'all' || source_filter === 'heapsgo') {
			for (const revenue_item_heaps of revenue_heaps) {

				// Get comparable date string 
				const date_string = moment(revenue_item_heaps.date).format('YYYY-MM');
				
				//console.log(date_string);

				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y += revenue_item_heaps.revenue_excl_vat;
				}

			}
		}


		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('MM/YYYY'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Revenue",
			"color": "hsl(223, 70%, 54%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}

	/**
	 * Prepare the sales data for the timeline (Daily)
	 */
	const prepareDataYearly = () => {

		// Get aggregated revenue data
		const revenue = timelineState.revenue_by_time;
		const revenue_orderyoyo = timelineState.revenue_by_time_orderyoyo;
		const revenue_wolt = timelineState.revenue_by_time_wolt;
		const revenue_heaps = timelineState.revenue_by_time_heaps;
		const source_filter = timelineState.source_filter;

		const start_date = props.start_date;
		const end_date = props.end_date;

		// setup (relevant) dates array
		let dates = [];

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date.format('YYYY') <= moment(end_date).format('YYYY') ) {
			
			dates.push( moment( new Date(temp_date) ) );

			data.push({
				x: moment( new Date(temp_date) ).format('YYYY'),
				y: 0
			});

			temp_date.year( temp_date.year() + 1 );
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'onlinepos') {
			for (const revenue_item of revenue) {
				
				// Get comparable date string 
				const date_string = moment(revenue_item.date).format('YYYY');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	revenue_item.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'orderyoyo') {
			for (const line_item_orderyoyo of revenue_orderyoyo) {
				
				// Get comparable date string 
				const date_string = moment(line_item_orderyoyo.date).format('YYYY');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	line_item_orderyoyo.revenue_excl_vat;
				}

			}
		}


		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'wolt') {
			for (const line_item_wolt of revenue_wolt) {
				
				// Get comparable date string 
				const date_string = moment(line_item_wolt.date).format('YYYY');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	line_item_wolt.revenue_excl_vat;
				}

			}
		}

		// Sum all revenue based on hour of sale
		if (source_filter === 'all' || source_filter === 'heapsgo') {
			for (const line_item_heaps of revenue_heaps) {
				
				// Get comparable date string 
				const date_string = moment(line_item_heaps.date).format('YYYY');
				
				// Find index for current hour
				const x_index = data.findIndex( date => date.x === date_string );
				
				// If the number exists, sum the revenue
				if (x_index !== -1) {
					data[x_index].y +=	line_item_heaps.revenue_excl_vat;
				}

			}
		}


		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('YYYY'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Revenue",
			"color": "hsl(223, 70%, 54%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}

	/**
	 * Prepare salary data for hourly timeline
	 */
	const prepareSalaryHourly = () => {

		// Get sales data from state
		const shifts = timelineState.shifts;

		//console.table(shifts);

		let data = [
			{ x: '00.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '01.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '02.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '03.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '04.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '05.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '06.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '07.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '08.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '09.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '10.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '11.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '12.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '13.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '14.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '15.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '16.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '17.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '18.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '19.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '20.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '21.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '22.00', y: 0, 'employees': [], total_minutes: 0 },
			{ x: '23.00', y: 0, 'employees': [], total_minutes: 0 },
		];

		for (const shift of shifts) {
			

			if (shift.salary > 0) {

				// Calculate cost per minute
				const total_salary = shift.salary * 1.155;
				
				const total_minutes = shift.shift_minutes;
				const salary_per_minute = total_salary / total_minutes;	

				const hour_chunks = minutes_per_hour(moment.utc(shift.shift_start_date_time), moment.utc(shift.shift_end_date_time));
					

				for (const hour of hour_chunks) {
					
					// Get the hour number
					const hour_number = hour.date.format('HH.00');

					// Find index for current hour
					const x_index = data.findIndex(hour => hour.x === hour_number );
					
					// If the number exists, sum the salary
					if (x_index !== -1) {
						
						let calculated_salary = salary_per_minute * hour.minutes;

							data[x_index].y += calculated_salary;
							data[x_index].employees.push(shift.employee_first_name + ' ' + shift.employee_last_name);
							data[x_index].total_minutes += hour.minutes;

					}
				}

			}
		}

		// Filter out hours with no revenue
		//hours = hours.filter(hour => hour.y > 0);

		//console.table(shifts);
		// Format cents to dollar (kroner)
		const formatted_hours = data.map( hour => { return { x: hour.x, y: parseInt(hour.y / 100), total_minutes: hour.total_minutes } });

		


		// Config
		const timelineConfig = [{
			"id": "Lønudgifter",
			"color": "hsl(339, 70%, 50%)",
			"data": formatted_hours
		}];

		return timelineConfig;
	}

	/**
	 * Convert timerange into hourly chunks
	 */
	const minutes_per_hour = (start, end) => {

		const range = moment.range(start, end)
		const time_slots = Array.from(range.by('hours'))
		let counter = 0;
		let chunks = [];

		for (const time_slot of time_slots) {
			counter++;

			if (counter === 1) {
				chunks.push({
					date: time_slot,
					hour: time_slot.format('HH'),
					minutes: 60 - time_slot.format('mm'),
				})
			} else if ( counter === time_slots.length ) {
				chunks.push({
					date: time_slot,
					hour: time_slot.format('HH'),
					minutes: parseInt(end.format('mm')),
				})
			} else {
				chunks.push({
					date: time_slot,
					hour: time_slot.format('HH'),
					minutes: 60,
				})
			}
		}

		return chunks;

	}


	/**
	 * Prepare the salary data for the timeline (Daily)
	 */
	const prepareSalaryDaily = () => {

		// Get salary aggregations from state
		const salary_aggregations = timelineState.salary_by_time

		const start_date = props.start_date;
		const end_date = props.end_date;

		// Setup data feed
		let data = [];

		let temp_date = new Date(start_date);
		while(temp_date <= end_date) {

			data.push({
				x: moment( temp_date ).format('YYYY-MM-DD'),
				y: 0
			});

			temp_date.setDate(temp_date.getDate() + 1);
		}

		// Sum all revenue based on hour of sale
		for (const salary_aggregation of salary_aggregations) {
			
			// Get comparable date string 
			const date_string = moment(salary_aggregation.date).format('YYYY-MM-DD');
			
			// Find index for current hour
			const x_index = data.findIndex( date => date.x === date_string );
			
			// If the number exists, sum the revenue
			if (x_index !== -1 && salary_aggregation.total_salary > 0) {
				data[x_index].y +=	salary_aggregation.total_salary * 1.155;
			}

		}
		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('dddd DD/MM'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Lønudgifter",
			"color": "hsl(339, 70%, 50%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}

	/**
	 * Prepare the salary data for the timeline (Weekly)
	 */
	const prepareSalaryWeekly = () => {

		// Get salary aggregations from state
		const salary_aggregations = timelineState.salary_by_time

		const start_date = props.start_date;
		const end_date = props.end_date;

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date.format('YYYY-WW') <= moment(end_date).format('YYYY-WW')) {

			data.push({
				x: moment( temp_date ).format('YYYY-WW'),
				y: 0
			});

			temp_date.add( 1, 'weeks');
		}

		// Sum all revenue based on hour of sale
		for (const salary_aggregation of salary_aggregations) {
			
			// Get comparable date string 
			const date_string = moment(salary_aggregation.date, 'YYYY-WW').format('YYYY-WW');
			
			// Find index for current hour
			const x_index = data.findIndex( date => date.x === date_string );
			
			// If the number exists, sum the revenue
			if (x_index !== -1 && salary_aggregation.total_salary > 0) {
				data[x_index].y +=	salary_aggregation.total_salary * 1.155;
			}

		}
		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x, 'YYYY-WW').format('W'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Lønudgifter",
			"color": "hsl(339, 70%, 50%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}

	/**
	 * Prepare the salary data for the timeline (Monthly)
	 */
	const prepareSalaryMonthly = () => {

		// Get salary aggregation data from state
		const salary_aggregations = timelineState.salary_by_time
		
		const start_date = props.start_date;
		const end_date = props.end_date;

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date.format('YYYY-MM') <= moment(end_date).format('YYYY-MM') ) {
			
			data.push({
				x: moment( temp_date ).format('YYYY-MM'),
				y: 0
			});

			temp_date.month( temp_date.month() + 1 );
		}

		// Sum all revenue based on hour of sale
		for (const salary_aggregation of salary_aggregations) {
			
			// Get comparable date string 
			const date_string = moment(salary_aggregation.date).format('YYYY-MM');
			
			// Find index for current hour
			const x_index = data.findIndex( date => date.x === date_string );
			
			// If the number exists, sum the revenue
			if (x_index !== -1 && salary_aggregation.total_salary > 0) {
				data[x_index].y +=	salary_aggregation.total_salary * 1.155;
			}

		}

		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('MM/YYYY'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Lønugifter",
			"color": "hsl(339, 70%, 50%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}


	/**
	 * Prepare the sales data for the timeline (Daily)
	 */
	const prepareSalaryYearly = () => {

		// Get salary aggregation data from state
		const salary_aggregations = timelineState.salary_by_time
		
		const start_date = props.start_date;
		const end_date = props.end_date;

		// setup (relevant) dates array
		let dates = [];

		// Setup data feed
		let data = [];

		let temp_date = moment(start_date);
		while(temp_date.format('YYYY') <= moment(end_date).format('YYYY') ) {
			
			dates.push( moment( new Date(temp_date) ) );

			data.push({
				x: moment( new Date(temp_date) ).format('YYYY'),
				y: 0
			});

			temp_date.year( temp_date.year() + 1 );
		}

		// Sum all revenue based on hour of sale
		for (const salary_aggregation of salary_aggregations) {
			
			// Get comparable date string 
			const date_string = moment(salary_aggregation.date).format('YYYY');
			
			// Find index for current hour
			const x_index = data.findIndex( date => date.x === date_string );
			
			// If the number exists, sum the revenue
			if (x_index !== -1 && salary_aggregation.total_salary > 0) {
				data[x_index].y +=	salary_aggregation.total_salary * 1.155;
			}

		}

		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('YYYY'), y: parseInt(date.y / 100) } });

		// Config
		const timelineConfig = [{
			"id": "Lønugifter",
			"color": "hsl(339, 70%, 50%)",
			"data": formatted_data
		}];

		return timelineConfig;
	}

	return (
	    <div className={`widget-v2 ${'loadingClass'}`}>
	        <div className="widget-v2-header">
	        				      
      			<Select 
              		className="timeline-selector" 
              		options={props.select.options} 
              		value={props.select.value}
              		onChange={props.select.onChange}
              	/>

              	<Select 
              		className="timeline-selector" 
              		options={props.frequency.options} 
              		value={props.frequency.value}
              		onChange={props.frequency.onChange}
              	/>
	        </div>
			
			<div className="widget-v2-body">
					<div className="responsive-line-container">
						
						
						{
							isLoading ? (
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							) : (
								<ResponsiveLine
									data={prepareData()}
									colors={d => d.color}
									margin={{ top: 50, right: 50, bottom: 70, left: 90 }}
									xScale={{ type: "point"}}
									yScale={{ type: "linear", stacked: false, min: 0, max: "auto" }}
									curve="monotoneX"
									axisTop={null}
									axisRight={null}
									axisBottom={{
										orient: "bottom",
										tickSize: 5,
										tickPadding: 5,
										tickRotation: -40,
										legend: "",
										legendOffset: 36,
										legendPosition: "middle"
									}}
									axisLeft={{
										orient: "left",
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendOffset: -40,
										legendPosition: "middle",
										format: value => `${Number(value).toLocaleString('da-DK')}`,
									}}



									yFormat={value => {
										if (props.salaryPercentageMode) {
											return `${value}%`;
										} else {
											return `${Number(value).toLocaleString('da-DK')}`;
										}
										
									}}

					      	    	enablePointLabel={true}
					      	    	pointSize={6}
					      	    	pointBorderWidth={1}
					      	    	pointBorderColor={{
										from: 'color',
										modifiers: [['darker', 0.3]],
									}}
									pointLabelYOffset={-10}
									enableArea={true}			        
									areaOpacity={0.07}
									useMesh={false}
									enableSlices='x'		
									animate={true}
									motionStiffness={300}
									motionDamping={30}
								/>
							)

						}
						
					</div>	

					{
	            		timelineState.timeline_salary_percentage ? (
	            			<div className="widget-v2-body-legends">
		            			<div className="widget-v2-body-legend" style={{color: 'hsl(166, 67%, 49%)'}}>
		            				<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(166, 67%, 49%)'}}></div>
		            				<div className="widget-v2-body-legend-title">Lønprocent</div>
		            			</div>
		            		</div>
	            		) : (
	            			<div className="widget-v2-body-legends">
		            			<div className="widget-v2-body-legend" style={{color: 'hsl(223, 70%, 54%)'}}>
		            				<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(223, 70%, 54%)'}}></div>
		            				<div className="widget-v2-body-legend-title">Omsætning</div>
		            			</div>

		            			<div className="widget-v2-body-legend" style={{color: 'hsl(339, 70%, 50%)'}}>
		            				<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(339, 70%, 50%)'}}></div>
		            				<div className="widget-v2-body-legend-title">Lønudgifter</div>
		            			</div>
		            		</div>
	            		)
	            	}
			</div>						        


	        <div className="widget-v2-footer">
	        	<ul className="widget-footer-filters">
	        		{
						timelineState.source_filter === 'all' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('all')}>Samlet</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('all')}>Samlet</li>
						)
					}

					{
						timelineState.source_filter === 'onlinepos' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						)
					}

					{
						timelineState.source_filter === 'heapsgo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						)
					}

					{
						timelineState.source_filter === 'orderyoyo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						)
					}

					{
						timelineState.source_filter === 'wolt' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						)
					}
	        	</ul>
	        	
	        	<div className="widget-timeline-v2-footer-settings">
		        	{
						isTakeawayOnly ? (
							<button className="widget-timeline-v2-footer-button widget-timeline-v2-footer-button--active" onClick={() => toggleTimelineTakeaway()}>Takeaway Only</button>
						) : (
							<button className="widget-timeline-v2-footer-button" onClick={() => toggleTimelineTakeaway()}>Takeaway Only</button>
						)
						
					}

		        	{
						timelineState.timeline_accumulate ? (
							<button className="widget-timeline-v2-footer-button widget-timeline-v2-footer-button--active" onClick={() => toggleTimelineAccumulator()}>Akkumuleret</button>
						) : (
							<button className="widget-timeline-v2-footer-button" onClick={() => toggleTimelineAccumulator()}>Akkumuleret</button>
						)
						
					}

					{
						timelineState.timeline_salary_percentage ? (
							<button className="widget-timeline-v2-footer-button widget-timeline-v2-footer-button--active" onClick={() => toggleTimelineSalaryPercentage()}>Lønprocent</button>
						) : (
							<button className="widget-timeline-v2-footer-button" onClick={() => toggleTimelineSalaryPercentage()}>Lønprocent</button>
						)
						
					}
	        	</div>
	        </div>

	    </div>	
	); 
};

export default TimelineRevenue;