import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Barometer from '../../charts/Barometer';

export const SocialMediaInstagramTargets = (props) => {


	// Loading state
	const [widgetState, setWidgetState] = useState({
		data: []
	});


	/**
	 * update frequency
	 */
	useEffect(() => {
		
		if (props.profile === 'groedcph') {

			// Update state
			setWidgetState(prevState => ({
				...prevState,
				data: [
					{
						title: 'Post Reach pr. week',
						target: 100000,
						value: 81431,
						type: 'number',
						reverse: false,
					},
					{
						title: 'New Followers pr. week',
						target: 130,
						value: 24,
						type: 'number',
						reverse: false,
					},
				],
			}))
		}

		if (props.profile === 'groedoslo') {

			// Update state
			setWidgetState(prevState => ({
				...prevState,
				data: [
					{
						title: 'Post Reach pr. week',
						target: 5000,
						value: 2872,
						type: 'number',
						reverse: false,
					},
					{
						title: 'New Followers pr. week',
						target: 40,
						value: 8,
						type: 'number',
						reverse: false,
					},
					{
						title: 'Followers by 1. March 2022',
						target: 2500,
						value: 240,
						type: 'number',
						reverse: false,
					},
				],
			}))
		}




	}, []);



	return (
		<div className="widget-v2">
	        <div className="widget-v2-header">
	            <h2 className="widget-v2-title">Targets</h2>
	        </div>
			
			<div className="widget-v2-body">

				{
					widgetState.data.map((entry, i) => {
						return (
							<div key={i} className="target-indicator-block">
								<Barometer title={entry.title} target={entry.target} value={entry.value} max={25000} type={entry.type} reverse={entry.reverse} />
							</div>
						)
					})		
				}

			</div>
			<br/>
		</div>

	);
}

export default SocialMediaInstagramTargets;
