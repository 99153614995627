import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../../components/Loading";
import Navigation from "../../components/Navigation";
import BrandBar from "../../components/BrandBar";
import Filters from "../../components/Filters";

// Widgets
import ProfilesKPIsSidebar from "../../components/widgets/profiles/ProfilesKPIsSidebar";
import ProfilesAcquisition from "../../components/widgets/profiles/ProfilesAcquisition";
import ProfilesActivity from "../../components/widgets/profiles/ProfilesActivity";
import ProfilesEventTimeline from "../../components/widgets/profiles/ProfilesEventTimeline";
import ProfilesFunnel from "../../components/widgets/profiles/ProfilesFunnel";

export const ProfileAnalytics = (props) => {

	//STATE
	const [filterData, setFilterData] = useState({
		active_department_ids: [],
		active_department_labels: ['Alle afdelinger'],
		active_start_date: new Date(),
		active_end_date: new Date(),
		// active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		// active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	});


	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Profiles"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Profile Analytics</h1>

									<div className="page-description">
										<p>Profiles represent profile-data on people who interacted with your brand in a way that collected their data</p>
									</div>
								</div>
								<div className="page-filters-container">
									<Filters filterData={filterData} setFilterData={setFilterData} departments={[]}/>
								</div>
							</div>
							


							<div className="page-charts">

								<div className="split-chart-container-1-3">
									<div className="split-chart-container-widget">
										<ProfilesKPIsSidebar />		
									</div>

									<div className="split-chart-container-widget">
										<ProfilesAcquisition 
											start_date={filterData.active_start_date}
											end_date={filterData.active_end_date}
										/>
									</div>
								</div>

								<ProfilesEventTimeline 
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>


								<ProfilesActivity
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>			

								

								<ProfilesFunnel />

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(ProfileAnalytics, {
  onRedirecting: () => <Loading />,
});
