import React from "react";

export const Redacted = (props) => {

	const random_names = [
		'Andersine And',
		'Jennifer niels Lopez',
		'Barack Sørensen-Obama',
		'Mette Frederik Tobiasen',
		'Thomas Eje',
		'Mickey Mouse',
		'Johanne Schmidt Thomsen',
		'Will Johannes Schmidt',
		'Rosalia',
		'Joan Troels-Amon Frederiksen',
		'Janni Kaj Bentesen',
		'Vinnie Who',
		'Do not look here',
		'Abbey Mayo',
		'Jean Ochoa',
		'Quintin Clarke',
		'Reagan Allison',
		'Mallory Ponce',
		'Ethan Anderson',
		'Emmalee Summers',
		'Sarai Jarvis',
		'Athena Werner',
		'Alexus Barnes',
		'Alisson Phillips',
		'Averie Acosta',
		'Valery Robertson',
		'Kade Jensen',
		'Sullivan Evans',
		'Jocelyn Doyle',
		'Emily Mann',
		'Paris Callahan',
		'German Miles',
		'Elisa Taylor',
		'Larissa Rowe',
		'Josiah Osborn',
		'Giovanni Cooke',
		'Lila Velez',
		'Jase Drake',
		'Braiden Faulkner',
		'Cadence Lang',
		'Teagan Tanner',
		'Milo Leonard',
		'Karissa Willis',
		'Trent Phillips',
		'Maddison Wise',
		'Adalynn Sparks',
		'Natasha Todd',
		'Jamarion Zuniga',
		'Abbigail Sexton',
		'Izaiah Fowler',
		'Monica Henry',
		'Hope Vincent',
		'Maia Ellis',
		'Andrea Mcmahon',
		'Leyla Macdonald',
		'Axel Salinas',
		'Micheal Marsh',
		'Grayson Soto',
		'Irvin Blanchard',
		'Reese Pitts',
		'Evie Grant',
		'Gabriel Velazquez',
	];

	const random_emails = [
		'andersine@and.com',
		'jennifer@niels.com',
		'barack@sørensen.com',
		'mette@frederik.com',
		'thomas@eje.com',
		'mickey@mouse.com',
		'johanne@schmidt.com thomsen',
		'will@johannes.com',
		'rosalia@gmail.com',
		'joan@troels.com',
		'janni@kaj-bentesen.com',
		'vinnie@who.com',
		'do@not.com',
		'abbey@mayo.com',
		'jean@ochoa.com',
		'quintin@clarke.com',
		'reagan@allison.com',
		'mallory@ponce.com',
		'ethan@anderson.com',
		'emmalee@summers.com',
		'sarai@jarvis.com',
		'athena@werner.com',
		'alexus@barnes.com',
		'alisson@phillips.com',
		'averie@acosta.com',
		'valery@robertson.com',
		'kade@jensen.com',
		'sullivan@evans.com',
		'jocelyn@doyle.com',
		'emily@mann.com',
		'paris@callahan.com',
		'german@miles.com',
		'gabriel@velazquez.com',
	];

	
	const redacted_email = random_emails[Math.floor(Math.random() * random_emails.length)];
	let redacted_name = random_names[Math.floor(Math.random() * random_names.length)];
	if(props.text) {
		redacted_name = props.text
	} 

	return (
		<div className="redacted">
			{
				props.type === 'email' ? (
					<span>{redacted_email}</span>
				) : (
					<span>{redacted_name}</span>
				)
				
			}
		</div>
	); 
};

export default Redacted;