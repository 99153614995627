import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../../components/Loading";
import Navigation from "../../components/Navigation";
import BrandBar from "../../components/BrandBar";
import Redacted from "../../components/Redacted";

// Widgets
import ProfileDetails from "../../components/widgets/profiles/ProfileDetails";
import ProfileEventHistory from "../../components/widgets/profiles/ProfileEventHistory";
import ProfileConsent from "../../components/widgets/profiles/ProfileConsent";
import OrdersTable from "../../components/widgets/orders/OrdersTable";
import Drawer from "../../components/Drawer";
import OrderDetails from "../../components/widgets/orders/OrderDetails";

export const Profile = (props) => {

	const [profileData, setProfileData] = useState({
		profileState: 'loading',
		profile: {
			first_name: 'No Name',
			last_name: '',
		}
	});


	// Loading state
	const [drawerOrder, setDrawerOrder] = useState({});

	const [drawerState, setDrawerState] = useState('closed'); // loading, error, done	

	let profile_id = '';
	if(props.match) {
		profile_id = props.match.params.profile;	
	}


	return (
		<div className="app-container">

			<Drawer drawerState={drawerState} setDrawerState={setDrawerState}>
				<OrderDetails order={drawerOrder}/>
			</Drawer>
			
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Profiles"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">
										
										{
											profileData.profile.first_name === 'REDACTED' ? (
												<Redacted text="Redacted Name"/>
											) : (
												<div>{profileData.profile.first_name} {profileData.profile.last_name}</div>
											)
										}
										

										</h1>
									<div className="page-subtitle">ID: {props.match.params.profile}</div>
									<div className="page-description">
										<p>An overview of a single profiles interactions</p>
									</div>
								</div>

							</div>

							<div className="page-charts">

								<div className="page-with-sidebar">
								
									<div className="page-with-sidebar-sidebar">
												<ProfileDetails {...props} setParentProfileData={setProfileData} />

												<ProfileConsent {...props} />
									</div>

									<div className="page-with-sidebar-main">
												<OrdersTable profile_id={profile_id} setDrawerOrder={setDrawerOrder} setDrawerState={setDrawerState} {...props}/>
												<ProfileEventHistory {...props}/>
									</div>			

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(Profile, {
  onRedirecting: () => <Loading />,
});
