import React, { Component } from 'react';

class Barometer extends Component {

	constructor() {
		super();
		
		// If we make widget controls, these should be stored in state.
		this.state = {}
	}


	/**
	 * Render component
	 */
	render() {

		const {
			title,
			target,
			value,
			reverse = false,
			type = 'currency',
			useColors = true,
		} = this.props;



		//// Thinking:

		// full bar value = X
		// Target indicator always at 70% (or 30% in reverse). 
		// Current value percentage = 


		/**
		 * Full bar value
		 * - This is set to the target value + an additional 40 % of the target value.
		 */
		let full_bar_value = target + (target / 100 * 40);
		full_bar_value = target;
		if (type === 'percentage') {
			full_bar_value = 100;			
		}

		/**
		 * Value indicator
		 * - Sets the percentage for the value bar
		 */
		let value_indicator_percentage = value / full_bar_value * 100;
		
		// If the value is bigger than maximum, then set the indicator to full (100%).
		if (value > full_bar_value) {
			value_indicator_percentage = 100;
		}


		/**
		 * Target indicator
		 * - Position the target indicator based on percentage of full bar value
		 */
		let target_indicator_percentage = target / full_bar_value * 100;
	
		// if value is beyond the maxiumum scale, then lower the target indicator to er percentage of the value instaed of full bar.
		if (value > full_bar_value) {
			target_indicator_percentage = target / value * 100;
		}


		/**
		 * Calculate acceptable diff
		 * - 20% of target
		 */
		let acceptable_diff = target / 100 * 20;


		/**
		 * Changes for reverse bars
		 * - This applies to bars going the other direction
		 */

		 if (reverse) { 	
		 	value_indicator_percentage = 100 - (value / full_bar_value * 100);
		 	
		 	if (value > full_bar_value) {
		 		value_indicator_percentage = 0;
		 	}
		 }


		 /**
		  * Increase / decrease combared to target
		  */
		  let compared_percentage = ( ( value - target ) / target ) * 100;
		  //console.log(title, compared_percentage);

		/**
		 * Value type
		 */
		let value_classification = '';
		if (type === 'percentage') {
			value_classification = '%';
		} else if (type === 'currency') {
			value_classification = 'DKK';
		}

		
		let main_class = '';
		if (reverse) {
			main_class = 'barometer--reversed';
		}
	
		let color_class = 'barometer-fill--fail';
		
		if (value >= target && !reverse) {		
			color_class = 'barometer-fill--success';
		} else if (target - value < acceptable_diff && !reverse) {
			color_class = 'barometer-fill--close';				
		} 
		if(value <= target && reverse) {
			color_class = 'barometer-fill--success';
		} else if (value - target < acceptable_diff && reverse) {
			color_class = 'barometer-fill--close';				
		}

		let changeClass = '';
		if (this.props.showChange) {
			changeClass = 'barometer--with-change-label';
		}

		if (!useColors) {
			color_class = 'barometer-fill--regular';	
		}

		return (
			<div className={`barometer ${changeClass} ${main_class}`}>
				<div className="barometer-header">
					<div className="barometer-header-title">{title}</div>
					
					{
						target > 0 ? (
							<div className="barometer-header-target">{target.toLocaleString("da-DK")} {value_classification}</div>
						) : (
							<div className="barometer-header-target">N/A</div>
						)

					}

				</div>
				
				<div className="barometer-body">

					<div className="barometer-target-indicator" style={{ left: target_indicator_percentage + '%' }}></div>

					{
						(target > 0) && (
							<div className={"barometer-fill " + color_class} style={{width: value_indicator_percentage + '%'}}>
								<div className="barometer-value-indicator">


									{
										compared_percentage > 10 && !reverse && (
											<div className="barometer-emoji"><span role="img" aria-label="Fire">🔥</span></div>
										)

										
									}
									{
										(100 + compared_percentage) < (100 - 10) && reverse && (
											<div className="barometer-emoji"><span role="img" aria-label="Fire">🔥</span></div>
										)		
									}
									<div className="barometer-value-indicator-number">{value.toLocaleString("da-DK")}
										{
											type === 'percentage' ? '%' : ''
										}

										{
											this.props.showChange && this.props.changeValue >= 0 && (
												<div className="tag tag--positive">+{parseFloat(this.props.changeValue).toFixed(2)} {this.props.changeLabel}</div> 
											)
										}

										{
											this.props.showChange && this.props.changeValue < 0 && (
												<div className="tag tag--negative">{parseFloat(this.props.changeValue).toFixed(2)} {this.props.changeLabel}</div> 
											)
										}

									

									</div>

								</div>
							</div>
						) 

					}

					
				</div>

			</div>

		);
	}
}

export default Barometer;
