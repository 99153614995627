import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import Select from 'react-select';
import { ResponsiveLine } from '@nivo/line';

const moment = extendMoment(Moment);

export const ProductTimeline = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Frequency options and defaults
	const [frequencyOptions, setFrequencyOptions] = useState([]);

	// Frequency state
	const [selectedFrequency, setSelectedFrequency] = useState({ value: 'hour', label: 'Time' });

	// Loading state
	const [widgetState, setWidgetState] = useState({
		searchValue: '',
		combine: false,
		options: [],
		selectedOptions: [],
		products_by_time: [],
	});

	/**
	 * update frequency
	 */
	useEffect(() => {
		updateFrequencyOptions();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
		prepareData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids, 
		selectedFrequency
	]);


	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		mapColorsToSelected();
	}, [widgetState.combine]);

	
	/**
	 * Update Frequency options
	 */
	const updateFrequencyOptions = () => {

		const start_date = moment(props.start_date);
		const end_date = moment(props.end_date);

		let f_options = [];
		let f_selected = '';

		// Check if hour should be an option
		if (start_date.format('YYYY-MM-DD') === end_date.format('YYYY-MM-DD')) {
			f_options.push({ value: 'hour', label: 'Time' });
			f_options.push({ value: 'day', label: 'Dag' });
			f_selected = 'hour';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'days') >= 1 && end_date.diff(start_date, 'days') <= 31 ) {
			f_options.push({ value: 'day', label: 'Dag' });
			f_options.push({ value: 'week', label: 'Uge' });
			f_options.push({ value: 'month', label: 'Month' });
			f_selected = 'day';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'months') >= 1 && end_date.diff(start_date, 'months') < 24) {
			f_options.push({ value: 'day', label: 'Dag' });
			f_options.push({ value: 'week', label: 'Uge' });
			f_options.push({ value: 'month', label: 'Month' });
			f_options.push({ value: 'year', label: 'år' });
			f_selected = 'month';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'months') >= 24) {
			f_options.push({ value: 'day', label: 'Dag' });
			f_options.push({ value: 'week', label: 'Uge' });
			f_options.push({ value: 'month', label: 'Month' });
			f_options.push({ value: 'year', label: 'år' });
			f_selected = 'year';
		}

		setFrequencyOptions(f_options);

		let currentSelectionExists = false;
		if (selectedFrequency && selectedFrequency.value) {
			currentSelectionExists = f_options.find(option => option.value === selectedFrequency.value);	
		}

		if (!currentSelectionExists) {
			setSelectedFrequency(f_options.find(option => option.value === f_selected));	
		}

	}

	/**
	 * Automatically detect what frequency to use
	 */
	const getFrequency = () => {
		let frequency = '';
		if (selectedFrequency && selectedFrequency.value) {
			frequency = selectedFrequency.value;
		}
		return frequency;
	}

	// Handle frequency change
	const handleFrequencyChange = (selectedFrequency) => {
		setSelectedFrequency(selectedFrequency);
	}

	/**
	 * Handle search change
	 */
	const handleSearchChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		//const name = target.name;

		// Set state
		setWidgetState({
			...widgetState,
			searchValue: value
		});
	}

	/**
	 * Toggle combine
	 */
	const toggleTimelineCombine = () => {
		// Set state
		setWidgetState({
			...widgetState,
			combine: !widgetState.combine,
		});	
	}

	/**
	 * Map colors to selected items
	 */
	const mapColorsToSelected = () => {

		let selectedOptions = widgetState.selectedOptions;

		let colors = ['#4D80FF', '#29D0A9', '#E33F3F', '#B44DFF', '#CBCB4E', '#CC8632']

		if (widgetState.combine) {
			colors = ['#4D80FF', '#4D80FF', '#4D80FF', '#4D80FF', '#4D80FF', '#4D80FF'];			
		}

		selectedOptions = selectedOptions.map((option, i) => {
			let colorIndex = (i % 6);
			option.color = colors[colorIndex];

			return option;
		})


		setWidgetState({
			...widgetState,
			selectedOptions: selectedOptions,
		});	
	}

	const clearSelected = () => {
		setWidgetState({
			...widgetState,
			selectedOptions: [],
		});	
	}

	/**
	 * Handle product select
	 */
	const handleProductSelect = (product, i) => {

		let options = widgetState.options;
		let selectedOptions = widgetState.selectedOptions;

		// Add to selected,
		selectedOptions.push(product);

		// Remove from options
		options.splice(i, 1);

		// Update state
		setWidgetState({
			...widgetState,
			options: options, 
			selectedOptions: selectedOptions,
		});	
		mapColorsToSelected();
	}


	/**
	 * Handle product select
	 */
	const handleProductDeselect = (product, i) => {
		//console.log('selected', product);
		let options = widgetState.options;
		let selectedOptions = widgetState.selectedOptions;

		// Add to options,
		options.push(product);

		// Remove from selected
		selectedOptions.splice(i, 1);

		// Update state
		setWidgetState({
			...widgetState,
			options: options, 
			selectedOptions: selectedOptions,
		});	
		mapColorsToSelected();
	}

	/**
	 * Get options
	 */

	const getOptions = () =>{

		let data = widgetState.products_by_time;
		
		data = data.map(entry => {
			console.log(entry);
			return {
				label: entry.product_name,
				category: entry.product_category,
			}

		})

		const selectedOptions = widgetState.selectedOptions;


		let options = data;

		// Only keep useful categories
		const accept_categories = ['Morgen', 'Morgen TO GO', 'Frokost/Aften', 'Frokost/Aften TO GO', 'Varme Drikke', 'Varme Drikke TO GO', 'Kolde Drikke', 'Kolde Drikke TO GO', 'Tilbehør', 'Retail', 'Te', 'Rabatter', 'Toppings - Morgen', 'Toppings - Aften', 'Combos', 'Combos TO GO', '*Inaktive produkter*', 'Emballage'];
		options = options.filter(option => accept_categories.includes(option.category) );

		const remove_products = ['1 stk topping (aften)', '1 stk. topping (Aften)', '1 stk. topping (morgen)', '3 stk. topping (morgen)', '1 stk. topping', '3 stk topping (aften)', '3 stk. topping'];
		options = options.filter(option => !remove_products.includes(option.label) );

		// filter by search term
		options = options.filter(option => option.label.search(new RegExp(widgetState.searchValue, "i")) !== -1 );

		// remove already selected items
		for (const selectedOption of selectedOptions) {
			options = options.filter(option => {
				if(option.label == selectedOption.label && option.category == selectedOption.category) {
					return false;
				} else {
					return true;
				}
			});
		}

		options.sort((a,b) => (a.label > b.label) ? 1 : -1);

		return options;
	}

	/** 
	 * Setup default data with no actual input.
	 */
	const getDefaultTimelineData = () => {

		const frequency = getFrequency();
		
		const start_date = props.start_date;
		const end_date = props.end_date;
		
		// Hourly
		let default_hourly = [];
		default_hourly = [
			{ x: '00.00', y: 0 },
			{ x: '01.00', y: 0 },
			{ x: '02.00', y: 0 },
			{ x: '03.00', y: 0 },
			{ x: '04.00', y: 0 },
			{ x: '05.00', y: 0 },
			{ x: '06.00', y: 0 },
			{ x: '07.00', y: 0 },
			{ x: '08.00', y: 0 },
			{ x: '09.00', y: 0 },
			{ x: '10.00', y: 0 },
			{ x: '11.00', y: 0 },
			{ x: '12.00', y: 0 },
			{ x: '13.00', y: 0 },
			{ x: '14.00', y: 0 },
			{ x: '15.00', y: 0 },
			{ x: '16.00', y: 0 },
			{ x: '17.00', y: 0 },
			{ x: '18.00', y: 0 },
			{ x: '19.00', y: 0 },
			{ x: '20.00', y: 0 },
			{ x: '21.00', y: 0 },
			{ x: '22.00', y: 0 },
			{ x: '23.00', y: 0 },
		];

		if (frequency === 'hour') {

			return default_hourly;
		}


		else if (frequency === 'day') {
			
			// Daily
			let default_daily = [];
			let daily_temp_date = moment(start_date);
			
			while(daily_temp_date.format('YYYY-MM-DD') <= moment(end_date).format('YYYY-MM-DD')) {

				default_daily.push({
					x: daily_temp_date.format('YYYY-MM-DD'),
					y: 0,
					ordercount: 0,
					revenue_excl_vat: 0,
				});

				daily_temp_date.add(1, 'days');
			}

			return default_daily;

		}

		else if (frequency === 'week') {
			
			// Weekly
			let default_weekly = [];
			let weekly_temp_date = moment(start_date);

			while(weekly_temp_date.format('YYYY-WW') <= moment(end_date).format('YYYY-WW')) {

				default_weekly.push({
					x: weekly_temp_date.format('W'),
					y: 0,
					ordercount: 0,
					revenue_excl_vat: 0,
				});

				weekly_temp_date.add(1, 'weeks');
			}

			return default_weekly;

		}

		else if (frequency === 'month') {
			// Monthly
			let default_monthly = [];
			let monthly_temp_date = moment(start_date);
			
			while(monthly_temp_date.format('YYYY-MM') <= moment(end_date).format('YYYY-MM')) {

				default_monthly.push({
					x: moment( monthly_temp_date ).format('YYYY-MM'),
					y: 0
				});

				monthly_temp_date.month(monthly_temp_date.month() + 1);
			}
			return default_monthly;
		}

		else if (frequency === 'year') {
			
			// Yearly
			let default_yearly = [];
			let yearly_temp_date = moment(start_date);
			
			while(yearly_temp_date.format('YYYY') <= moment(end_date).format('YYYY')) {

				default_yearly.push({
					x: moment( yearly_temp_date ).format('YYYY'),
					y: 0
				});

				yearly_temp_date.year(yearly_temp_date.year() + 1);
			}

			return default_yearly;
		} else {
			return default_hourly;
		}

	}

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		//const aggregatedProductsPromise = loadAggregatedProducts();
		const loadAggregatedProductsByTimePromise = loadAggregatedProductsByTime();

		Promise.all([loadAggregatedProductsByTimePromise])
			.then( values => {

				setWidgetState({
					...widgetState,
					products_by_time: values[0].data.products_by_time,
				})

				setIsLoading(false);

			})
			.catch(error => {
				setWidgetState({ 
					...widgetState,
					products_by_time: []
				})
				setIsLoading(true);
				console.log(error);
			}
		);

	}

	/**
	 * Load products by time data
	 */
	const loadAggregatedProductsByTime = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/products-by-time/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: getFrequency(), // hour, day, month or year
				// compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
    			// compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;		
	}

	/**
	 * Prepare the data for the line chart
	 */
	const prepareData = () => {

		const frequency = getFrequency();

		// Setup default data with no input
		let data = [{
			"id": "No data",
			"color": "hsl(223, 70%, 54%)",
			"data": getDefaultTimelineData(),
		}];

		const products_by_time = widgetState.products_by_time;

		if (products_by_time.length && widgetState.selectedOptions.length) {

			// Remove the initial "No data" object
			const nodata_obj_index = data.findIndex(data_obj => data_obj.id === "No data");
			if (nodata_obj_index !== -1) {
				data.splice(nodata_obj_index, 1);	
			}
			
			
			for (const entry of widgetState.selectedOptions) {

				let timeline_data = getDefaultTimelineData();

				const product = products_by_time.find(data_entry => data_entry.product_name === entry.label && data_entry.product_category === entry.category ); 

				if (product) {
					
					if (frequency === 'hour') {
						for (const date_entry of product.dates) {

							// Get the hour number
							const hour_number = moment.utc(date_entry.date).format('HH.00');

							// Find index for current hour
							const x_index = timeline_data.findIndex(hour => hour.x === hour_number );
							
							// // If the number exists, sum item
							if (x_index !== -1) { timeline_data[x_index].y +=	date_entry.item_count; }
						}
					}

					if (frequency === 'day') {
						for (const date_entry of product.dates) {

							// Get the hour number
							const date_string = moment.utc(date_entry.date).format('YYYY-MM-DD');

							// Find index for current hour
							const x_index = timeline_data.findIndex(date => date.x === date_string );
							
							// // If the number exists, sum item
							if (x_index !== -1) { timeline_data[x_index].y +=	date_entry.item_count; }
						}
					}

					if (frequency === 'week') {
						for (const date_entry of product.dates) {

							// Get the hour number
							const date_string = moment.utc(date_entry.date, 'YYYY-W').format('W');

							// Find index for current hour
							const x_index = timeline_data.findIndex(date => date.x === date_string );
							
							// // If the number exists, sum item
							if (x_index !== -1) { timeline_data[x_index].y +=	date_entry.item_count; }
						}
					}

					if (frequency === 'month') {
						for (const date_entry of product.dates) {

							// Get the hour number
							const date_string = moment.utc(date_entry.date).format('YYYY-MM');

							// Find index for current hour
							const x_index = timeline_data.findIndex(date => date.x === date_string );
							
							// // If the number exists, sum item
							if (x_index !== -1) { timeline_data[x_index].y +=	date_entry.item_count; }
						}
					}

					if (frequency === 'year') {
						for (const date_entry of product.dates) {

							// Get the hour number
							const date_string = moment.utc(date_entry.date).format('YYYY');

							// Find index for current hour
							const x_index = timeline_data.findIndex(date => date.x === date_string );
							
							// // If the number exists, sum item
							if (x_index !== -1) { timeline_data[x_index].y +=	date_entry.item_count; }
						}
					}
				

					//Format cents to dollar (kroner)
					timeline_data = timeline_data.map( entry => { return { x: entry.x, y: parseInt(entry.y) } });



					const timelineSet = {
						"id": product.product_name + ` (${product.product_category})`,
						"color": entry.color,
						"data": timeline_data,
					}

					data.push(timelineSet);
	
				}

			}
			
		}
		

		// Combine all data into one line		
		if (widgetState.combine) {

			let combinedTimelineData = getDefaultTimelineData();
			
			for (const timelineset of data) {	
				for (const data_set of timelineset.data) {
					const data_index = combinedTimelineData.findIndex(entry => entry.x === data_set.x);
					combinedTimelineData[data_index].y += data_set.y;
				}
			}

			data = [{
				"id": 'Total (Combined)',
				"color": '#4D80FF',
				"data": combinedTimelineData,
			}];
		}


		return data;	
	}


	let loadingClass = "widget--loading";
	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className="widget-v2-container">
		    <div className={`widget-v2 ${loadingClass}`}>
		        <div className="widget-v2-header">
		            <h2 className="widget-v2-title">Produkter over tid</h2>
		            <Select 
              			className="timeline-selector" 
              			options={frequencyOptions} 
              			value={selectedFrequency}
              			onChange={handleFrequencyChange}
              		/>
		        </div>
				
				<div className="widget-v2-body">
					
					
							<div className="widget-timeline-split-container">
								<div className="widget-timeline-split-selector">
							
									<div className="widget-timeline-input-container">
										<input type="text" className="widget-timeline-input" name="searchValue" value={widgetState.searchValue} onChange={handleSearchChange} placeholder="Søg efter produkter"/>
									</div>
									
									<div className="widget-timeline-product-list-container">

										<ul className="widget-timeline-product-list">
											
											{ 
												getOptions().map((item, i) => {
													return (
														<li key={i} className="widget-timeline-product-list-item"  onClick={() => handleProductSelect(item, i)}>{item.label + ` (${item.category})`}</li>
													)

												})
											}

										</ul>

										{ 
											getOptions().length === 0 && (
												<div className="no-options">Ingen tilgængelige produkter</div>
											)
										}

									</div>

								</div>

								<div className="widget-timeline-split-timeline">
									<div className="responsive-line-container">
										{
											isLoading ? (
												
													<div className="spinner-centered">
														<div className="spinner"></div>
													</div>
											
											) : (
										<ResponsiveLine
												data={prepareData()}
												colors={d => d.color}
												margin={{ top: 40, right: 50, bottom: 80, left: 60 }}
												xScale={{ type: "point"}}
												yScale={{ type: "linear", stacked: false, min: 0, max: "auto" }}
												curve="monotoneX"
												axisTop={null}
												axisRight={null}
												axisBottom={{
													orient: "bottom",
													tickSize: 5,
													tickPadding: 5,
													tickRotation: -40,
													legend: "",
													legendOffset: 36,
													legendPosition: "middle"
												}}
												axisLeft={{
													orient: "left",
													tickSize: 5,
													tickPadding: 5,
													tickRotation: 0,
													legend: "",
													legendOffset: -40,
													legendPosition: "middle",
													format: value => `${Number(value).toLocaleString('da-DK')}`,
												}}



												yFormat={value => {
													return `${Number(value).toLocaleString('da-DK')}`;
												}}

								      	    	enablePointLabel={true}
								      	    	pointSize={6}
								      	    	pointBorderWidth={1}
								      	    	pointBorderColor={{
													from: 'color',
													modifiers: [['darker', 0.3]],
												}}
												pointLabelYOffset={-10}
												enableArea={true}			        
												areaOpacity={0.07}
												useMesh={false}
												enableSlices='x'		
												animate={true}
												motionStiffness={300}
												motionDamping={30}
											/>
										)
									}
								</div>	
								<div className="widget-timeline-selected-list-container">

									<ul className="widget-timeline-selected-list">
										{ 
											widgetState.selectedOptions.map((item, i) => {
												return (
													<li key={i} className="widget-timeline-selected-list-item" style={{backgroundColor: item.color}}>
														{item.label} ({item.category})
														<span className="widget-timeline-selected-list-item-close" onClick={() => handleProductDeselect(item, i)}>
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{fill: item.color}}><path d="M477.5 116.3l-81.9-81.8-140.1 140.1L115.4 34.5l-81.8 81.8 140.1 140.1L33.6 396.5l81.8 81.9 140.1-140.1 140.1 140.1 81.9-81.9-140.2-140.1z"/></svg>
														</span>
													</li>
												)

											})
										}
									</ul>

									{ 
										widgetState.selectedOptions.length > 0 ? (
											<div className="product-timeline-clear" onClick={clearSelected}>Clear all</div>
										) : (
											<div className="product-timeline-clear product-timeline-clear--disabled" onClick={clearSelected}>Clear all</div>
										)
									}


								</div>

						</div>

					</div>
						
				</div>

		        <div className="widget-v2-footer">
		        	<div>
    			    	<ul className="widget-footer-filters">
    						<li className="widget-footer-filter" style={{textDecoration: 'line-through', cursor: 'initial'}}>GRØD App</li>
    		        	</ul>
		        	</div>
		        	<div className="widget-timeline-v2-footer-settings">
			        	{
			        		widgetState.combine ? (
			        			<button className="widget-timeline-v2-footer-button widget-timeline-v2-footer-button--active" onClick={() => toggleTimelineCombine()}>Samlet</button>
			        		) : (
			        			<button className="widget-timeline-v2-footer-button" onClick={() => toggleTimelineCombine()}>Samlet</button>
			        		)
			        		
			        	}
		        	</div>
		        </div>
		    </div>
		</div>
	)

}
export default ProductTimeline;