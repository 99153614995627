import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

// Charts
import ThickBar from '../../charts/ThickBar';


/**
 * Get profiles gender split
 */
const getProfilesGenderSplit = async (access_token) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-gender-split/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`}
	});

	return axiosPromise;		
}

/**
 * Get profiles gender split by source
 */
const getProfilesGenderSplitBySource = async (access_token) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-gender-split-by-source/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`}
	});

	return axiosPromise;		
}

export const ProfilesGender = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// data
	const [profilesGenderData, setProfilesGenderData] = useState([]);

	// Source Filter
	const [sourceFilter, setSourceFilter] = useState('All');

	// Bar data
	const [barData, setBarData] = useState([
		{
			label: 'Male',
			value: 22,
		},
		{
			label: 'Female',
			value: 43,
		},
		{
			label: 'Not specified',
			value: 3,
		}
	]);


	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		const loadAllData = async () => {

			setWidgetState('loading');

			// Get access token
			const access_token = await getAccessTokenSilently();
			
			const profilesGenderSplit = getProfilesGenderSplit(access_token);
			const profilesGenderSplitBySource = getProfilesGenderSplitBySource(access_token);
			
		
			Promise.all([profilesGenderSplit, profilesGenderSplitBySource])
				.then( values => {

					const profilesGenderSplit = values[0].data.data;
					const profilesGenderSplitBySource = values[1].data.data.map(d => {

						// if(d.platform === 'Mixpanel'){
						// 	d.platform = 'GRØD App';
						// }

						// if(d.platform === 'Klaviyo'){
						// 	d.platform = 'Mailing list';
						// }

						// if(d.platform === 'Shopify'){
						// 	d.platform = 'Webshop';
						// }

						return d;

					});

					const genders = [
						{
							platform: 'All',
							genders: [...profilesGenderSplit]
						},
						...profilesGenderSplitBySource
					];
					
					setProfilesGenderData(genders);

					setWidgetState('done');
				})
				.catch(error => {
					setProfilesGenderData([]);
					
					setWidgetState('error');
					
					console.log(error);
				});
		}
		loadAllData();

		

	}, [
		getAccessTokenSilently, 
	]);



	useEffect(() => {


		const dataset = profilesGenderData.find(a => a.platform === sourceFilter);
		console.log(dataset);

		if(dataset) {

			// Setup data
			let data = dataset.genders.map(gender => {

				return {
					label: gender.gender,
					value: gender.count
				}
			})

			// Sort by value
			data = data.sort( (a, b) => new Date(b.value) - new Date(a.value) );

			// Grab not specified
			const ns_index = data.findIndex(d => d.label === 'Not specified');
			
			// remove "Not specified"
			const ns = data.splice(ns_index, 1);

			// Add it back in at end. 
			data = [...data, ...ns];

			// Set bar data
			setBarData(data);
		}

	}, [
		profilesGenderData,
		sourceFilter
	]);


	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setSourceFilter(new_mode);
	}

	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Gender</h2>	
		        		<div className="widget-v3-subtitle">Best guess based on name</div>
		        	</div>

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<ThickBar
								data={barData}
								showLegends={true}
							/>

						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									{

										profilesGenderData.map((source, i) => {

											let activeClass = '';
											if (sourceFilter === source.platform) {
												activeClass = 'widget-footer-filter--active';
											}


											return (
												<li key={i} className={`widget-v3-footer-filter ${activeClass}`} onClick={() => changeSourceFilter(source.platform)}>{source.platform}</li>
											)
										})
									}

					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesGender;

