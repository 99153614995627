import React, { useState, useEffect } from "react";
import moment from 'moment';
import TimelineWebsiteSessions from './TimelineWebsiteSessions';

export const TimelineWidget = (props) => {

	const options = [
		{ value: 'sessions', label: 'Sessions & Pageviews', widgetName: 'TimelineWebsiteSessions' },
		// { value: 'instagram', label: 'Instagram', widgetName: 'TimelineSocialMediaInstagram' },
		// { value: 'facebook', label: 'Facebook', widgetName: 'TimelineSocialMediaFacebook' },
		// { value: 'tiktok', label: 'TikTok', widgetName: 'TimelineSocialMediaTikTok' },
		
		// { value: 'revenue_heatmap', label: 'Omsætning (Heatmap)', widgetName: 'TimelineMainRevenue' },
		// { value: 'orders', label: 'Antal Ordrer', widgetName: 'TimelineMainOrders' },
		// { value: 'basketsize', label: 'Basket Size', widgetName: 'TimelineMainBasketsize' },
		// { value: 'diffs', label: 'Kassedifferencer', widgetName: 'TimelineMainDiffs' },
	];

	// Timeline state
	const [selectedTimeline, setSelectedTimeline] = useState(options[0]);

	// Handle timeline change
	const handleTimelineChange = (selectedTimeline) => {
		setSelectedTimeline(selectedTimeline);
	}


	const selectConfig = {
		options: options,
		value: selectedTimeline,
		onChange: handleTimelineChange,
	}

	return (
		<div className="widget-v2-container widget-v2-container--timeline-main">
				

				
			{
				selectedTimeline.value === 'sessions' && (
					<TimelineWebsiteSessions select={selectConfig} {...props}/>
				)
			}
			

		</div>
	); 
};

export default TimelineWidget;
