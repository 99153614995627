import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

import channel_data from '../../../data/channel_data.json';

export const SocialMediaChannels = (props) => {


	// Loading state
	const [widgetState, setWidgetState] = useState({
		last_week_start_date: '2021-08-02',
		current_week_start_date: '2021-08-09',
		channels: []
	});


	/**
	 * update frequency
	 */
	useEffect(() => {
	
		// Filter posts (this would be equivalent to a database query)
		const channels = channel_data.channels_by_week;

		// Update state
		setWidgetState(prevState => ({
			...prevState,
			channels: channels,
		}))

	}, []);

	const getMetric = (channel, metric) => {
		const current_data = channel.data.find(data => data.start_date === widgetState.current_week_start_date)
		return current_data.metrics.find(m => m.key === metric).value;
	}

	const getProgress = (channel, metric) => {
		const prev_data = channel.data.find(data => data.start_date === widgetState.last_week_start_date)
		const current_data = channel.data.find(data => data.start_date === widgetState.current_week_start_date)
		
		const progress = current_data.metrics.find(m => m.key === metric).value - prev_data.metrics.find(m => m.key === metric).value;
		
		return progress;
	}


	return (
		<div className="widget-v2 widget-social-media-channelse">
	        <div className="widget-v2-header">
	            <h2 className="widget-v2-title">Channels</h2>
	        </div>
			
			<div className="widget-v2-body">

				<div className="social-media-channels">
					
					<table className="social-media-channels-table">	

						{/*
						<thead>
							<tr>
								<th></th>
								<th></th>
								<th>Following</th>
								<th>Content</th>
								<th>Organic reach</th>
								<th>Paid reach</th>
								<th>Views</th>
							</tr>
						</thead>
						*/}

						<tbody>

							{
								widgetState.channels.map((channel, i) => {
									let fill_table = [{},{},{},{},{}]; // 5 table cells pr row
									return (
										<tr key={i} className={`social-media-channels-table-row social-media-channels-table-row--${channel.channel}`}>
											<td className="social-media-channels-table-field">
												{channel.channel === 'Instagram' && (<div className="social-media-channels-channel-icon social-media-channels-channel-icon--instagram"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M171.1 256.4c0-46.7 37.8-84.5 84.5-84.5s84.5 37.8 84.5 84.5-37.8 84.5-84.5 84.5-84.5-37.8-84.5-84.5m-45.7 0c0 71.9 58.3 130.1 130.1 130.1s130.1-58.3 130.1-130.1-58.3-130.1-130.1-130.1-130.1 58.3-130.1 130.1m235-135.3c0 16.8 13.6 30.4 30.4 30.4 16.8 0 30.4-13.6 30.4-30.4s-13.6-30.4-30.4-30.4c-16.8 0-30.4 13.6-30.4 30.4M153.1 462.7c-24.7-1.1-38.1-5.2-47.1-8.7-11.8-4.6-20.3-10.1-29.2-19-8.9-8.9-14.4-17.3-19-29.1-3.5-8.9-7.6-22.4-8.7-47.1-1.2-26.7-1.5-34.7-1.5-102.4s.3-75.7 1.5-102.4c1.1-24.7 5.3-38.1 8.7-47.1 4.6-11.8 10.1-20.3 19-29.2 8.9-8.9 17.3-14.4 29.2-19 8.9-3.5 22.4-7.6 47.1-8.7 26.7-1.2 34.7-1.5 102.4-1.5s75.7.3 102.4 1.5c24.7 1.1 38.1 5.3 47.1 8.7 11.8 4.6 20.3 10.1 29.2 19 8.9 8.9 14.4 17.3 19 29.2 3.5 8.9 7.6 22.4 8.7 47.1 1.2 26.7 1.5 34.7 1.5 102.4s-.2 75.7-1.5 102.4c-1.1 24.7-5.3 38.1-8.7 47.1-4.6 11.8-10.1 20.3-19 29.1-8.9 8.9-17.3 14.4-29.2 19-8.9 3.5-22.4 7.6-47.1 8.7-26.7 1.2-34.7 1.5-102.4 1.5-67.6 0-75.7-.2-102.4-1.5M151 4.5c-27 1.2-45.4 5.5-61.5 11.8-16.7 6.5-30.8 15.1-44.9 29.2-14.1 14.1-22.7 28.2-29.2 44.9C9.1 106.5 4.8 125 3.6 151.9c-1.2 27-1.5 35.7-1.5 104.5s.3 77.5 1.5 104.5 5.5 45.4 11.8 61.5c6.5 16.7 15.1 30.8 29.2 44.9 14.1 14.1 28.2 22.7 44.9 29.2 16.1 6.3 34.5 10.5 61.5 11.8 27 1.2 35.7 1.5 104.5 1.5s77.5-.3 104.5-1.5 45.4-5.5 61.5-11.8c16.7-6.5 30.8-15.1 44.9-29.2 14.1-14.1 22.7-28.2 29.2-44.9 6.3-16.1 10.6-34.5 11.8-61.5 1.2-27 1.5-35.7 1.5-104.5s-.3-77.5-1.5-104.5-5.5-45.4-11.8-61.5c-6.5-16.7-15.1-30.8-29.2-44.9-14.1-14.1-28.2-22.8-44.9-29.2C405.4 10 387 5.7 360 4.5 333 3.3 324.4 3 255.5 3c-68.8 0-77.4.3-104.5 1.5"/></svg></div>)}
												{channel.channel === 'Facebook' && (<div className="social-media-channels-channel-icon social-media-channels-channel-icon--facebook"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M370.8 286.9l13.8-89.3h-85.7v-58c-2.2-24.6 16-46.2 40.5-48.4 3.3-.3 6.6-.2 9.8.2h39V15.2c-23-3.7-46.2-5.7-69.4-6-70.5 0-116.7 42.8-116.7 120.2v68.1h-78.4v89.4h78.4v216h96.5v-216h72.2z"/></svg></div>)}
												{channel.channel === 'TikTok' && (<div className="social-media-channels-channel-icon social-media-channels-channel-icon--tiktok"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352.5 9.1c8.1 70.1 47.2 111.8 115.2 116.3v78.8c-40.7 2.1-80.9-9.6-114.1-33.3v147.4c0 187.2-204.2 245.8-286.2 111.5-52.7-86.3-20.4-237.9 148.7-244v83c-13.3 2.1-26.5 5.3-39.3 9.6-37.6 12.7-59 36.6-53 78.6 11.4 80.6 159.3 104.4 146.9-53V9.3h81.6l.2-.2z" fill-rule="evenodd" clip-rule="evenodd"/></svg></div>)}
												{channel.channel === 'LinkedIn' && (<div className="social-media-channels-channel-icon social-media-channels-channel-icon--linkedin"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M79.417 15.912c5.7 1.4 11.4 3.2 16.9 5.3 29.3 12.6 42.8 46.6 30.1 75.9-12.6 29.3-46.6 42.8-75.9 30.1-17.5-7.5-30.1-23.2-33.8-41.8-.3-1.1-.7-2.2-1.2-3.3v-16.9c.6-1.6 1.2-3.2 1.7-4.9 5.2-21.5 22.3-38.1 43.9-42.7 2-.5 4.1-1.1 6.1-1.7h12.2zM281.217 220.012c.9-.8 1.8-1.7 2.6-2.7 17.9-29.5 49.6-48 84.1-49.1 24.6-2.5 49.4 1.7 71.8 12.2 24.6 12.7 42.2 35.7 48.1 62.7 5.9 22.5 8.7 45.7 8.4 69 .2 59.5 0 118.9 0 178.4 0 1.8-.1 3.5-.3 5.7h-99.3v-7.2c0-54.6 0-108.9-.4-163.4-.1-10.6-1.3-21.2-3.5-31.6-3.3-24.8-26.2-42.3-51-38.9-.8.1-1.6.2-2.3.4-28.4.3-51.4 22.9-52.1 51.3-1.5 10.5-2.2 21-2.3 31.6-.2 50.1 0 100.1 0 150.2v7.5h-98.8v-320h94.8l.2 43.9zM24.217 176.012h98.7v319.9h-98.7v-319.9z"/></svg></div>)}
												{channel.channel === 'YouTube' && (<div className="social-media-channels-channel-icon social-media-channels-channel-icon--youtube"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M492.6 137.3c-5.7-21.1-22.4-37.8-43.5-43.5C410.5 83.2 256 83.2 256 83.2s-154.5 0-193.1 10.2c-20.7 5.7-37.8 22.8-43.5 43.9C9.2 175.9 9.2 256 9.2 256s0 80.5 10.2 118.7c5.7 21.1 22.4 37.8 43.5 43.5 39 10.6 193.1 10.6 193.1 10.6s154.5 0 193.1-10.2c21.1-5.7 37.8-22.4 43.5-43.5 10.2-38.6 10.2-118.7 10.2-118.7s.4-80.5-10.2-119.1zM206.8 330V182l128.5 74-128.5 74z"/></svg></div>)}
												{channel.channel === 'Pinterest' && (<div className="social-media-channels-channel-icon social-media-channels-channel-icon--pinterest social-media-channels-channel-icon--disabled"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M399.7 59.7c-34-32.6-81-50.6-132.4-50.6-78.5 0-126.8 32.2-153.5 59.2-33 33.3-51.8 77.5-51.8 121.3 0 55 23 97.1 61.5 112.8 2.6 1.1 5.2 1.6 7.7 1.6 8.1 0 14.6-5.3 16.8-13.8 1.3-4.9 4.3-17 5.6-22.2 2.8-10.3.5-15.3-5.6-22.5-11.1-13.2-16.3-28.8-16.3-49 0-60.2 44.8-124.2 128-124.2 65.9 0 106.9 37.5 106.9 97.8 0 38.1-8.2 73.3-23.1 99.3-10.4 18-28.6 39.5-56.5 39.5-12.1 0-22.9-5-29.8-13.6-6.5-8.2-8.6-18.7-6-29.8 2.9-12.4 6.9-25.4 10.8-38 7.1-22.9 13.8-44.6 13.8-61.8 0-29.5-18.2-49.4-45.2-49.4-34.3 0-61.3 34.9-61.3 79.4 0 21.8 5.8 38.2 8.4 44.4-4.3 18.3-30 127.3-34.9 147.9-2.8 12-19.8 106.8 8.3 114.3 31.6 8.5 59.8-83.8 62.7-94.2 2.3-8.5 10.5-40.6 15.5-60.3 15.3 14.7 39.8 24.6 63.7 24.6 45 0 85.6-20.3 114.1-57.1 27.7-35.7 42.9-85.5 42.9-140.1 0-42.7-18.3-84.8-50.3-115.5z"/></svg></div>)}
											</td>

											<td className="social-media-channels-table-field">
												<div className="social-media-channels-table-field-title-block">
													<div className="social-media-channels-table-field-title-block-main">{channel.channel}</div>
													<div className="social-media-channels-table-field-title-block-secondary">
														<a href={channel.profile_url} target="_blank">{channel.profile_name}</a>
													</div>
												</div>
											</td>
											
											{
												channel.data && channel.data.find(dataset => dataset.start_date === widgetState.current_week_start_date) && (
													
													
													channel.data.find(dataset => dataset.start_date === widgetState.current_week_start_date).metrics.filter(m => !m.hidden).map(metric => {
														
														// remove element from table filler
														fill_table.shift()
														return (
															<td className="social-media-channels-table-field">

																<div className="social-media-channels-table-field-content">
																	<div className="social-media-channels-table-field-title-block social-media-channels-table-field-title-block--center">
																		<div className="social-media-channels-table-field-title-block-main">{getMetric(channel, metric.key).toLocaleString("da-DK")}</div>
																		<div className="social-media-channels-table-field-title-block-secondary">{metric.label}</div>
																	</div>
																	{getProgress(channel, metric.key) > 0 && (<div className="social-media-channels-table-field-progress social-media-channels-table-field-progress--positive">+{getProgress(channel, metric.key).toLocaleString("da-DK")}</div>)}
																	{getProgress(channel, metric.key) < 0 && (<div className="social-media-channels-table-field-progress social-media-channels-table-field-progress--negative">{getProgress(channel, metric.key).toLocaleString("da-DK")}</div>)}
																	{getProgress(channel, metric.key) === 0 && (<div className="social-media-channels-table-field-progress">{getProgress(channel, metric.key).toLocaleString("da-DK")}</div>)}	
																</div>
															</td>
														)
													})
													
												)
											}

											{
												fill_table.map(f => (<td>
													<div className="social-media-channels-table-field-title-block social-media-channels-table-field-title-block--center">
														<div className="social-media-channels-table-field-title-block-main"></div>
														<div className="social-media-channels-table-field-title-block-secondary"></div>
													</div>
												</td>))
											}

											{channel.channel === 'Instagram' && (<td className="social-media-channels-table-field"><Link to={channel.insights_path} className="social-media-channels-table-field-button social-media-channels-table-field-button--instagram">Detailed insights</Link></td>)}
											{channel.channel === 'Facebook' && (<td className="social-media-channels-table-field"><Link to={channel.insights_path} className="social-media-channels-table-field-button social-media-channels-table-field-button--facebook">Detailed insights</Link></td>)}
											{channel.channel === 'TikTok' && (<td className="social-media-channels-table-field"><Link to={channel.insights_path} className="social-media-channels-table-field-button social-media-channels-table-field-button--tiktok">Detailed insights</Link></td>)}
											{channel.channel === 'LinkedIn' && (<td className="social-media-channels-table-field"><Link to={channel.insights_path} className="social-media-channels-table-field-button social-media-channels-table-field-button--linkedin">Detailed insights</Link></td>)}
											{channel.channel === 'YouTube' && (<td className="social-media-channels-table-field"><Link to={channel.insights_path} className="social-media-channels-table-field-button social-media-channels-table-field-button--youtube">Detailed insights</Link></td>)}
											{channel.channel === 'Pinterest' && (<td className="social-media-channels-table-field disabled"><Link to={channel.insights_path} onClick={e => e.preventDefault()} className="social-media-channels-table-field-button social-media-channels-table-field-button--pinterest">Detailed insights</Link></td>)}

											
										</tr>
									)
								})
							}

						</tbody>

					</table>

				</div>

			</div>
		</div>

	);
}

export default SocialMediaChannels;
