import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import { Spinner } from "../../Spinner";

export const ProductTableV2 = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Data
	const [productData, setProductData] = useState([]);

	// Data
	const [tableData, setTableData] = useState([
		{
			name: 'Havregrød 1 TEST',
			category: 'Morgen',
			category_id: '124352234',
			quantity: 2,
			subtotal: 1200,
			total: 1200,
			total_cost: 1200,
			total_discount: 1234,
			total_profit: 1234,
	}]);


	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');
			
			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get styles data
				const profileDataResponse = await axios.get(`${api_url}/orders-product-sales`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
						department_ids: props.department_ids,
		                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
		                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
					}
				});

				setProductData(profileDataResponse.data.data);

				setWidgetState('done');
							
				
			} catch (e) {
	    		setProductData([]);
	    		console.error('error', e);
	    		setWidgetState('error');
	  		}
		  	

		})();		

	}, [
		getAccessTokenSilently, 
		props.start_date, 
		props.end_date,
		props.department_ids,
	]);


	// Format table
	useEffect(() => {
		
		let products = productData.map(product => {


			let product_type = product.product_type;
			product_type = product.product_type == 'line_item' ? 'Product' : product_type;
			product_type = product.product_type == 'option' ? 'Product Option' : product_type;
			

			return {
				name: product.name,
				category: product.category,
				product_type: product_type,
				quantity: product.quantity,
				subtotal: product.subtotal.amount,
				total: Math.floor(product.total.amount / 100),
				total_cost: Math.floor(product.total_cost.amount / 100),
				total_discount: Math.floor((product.price.amount - product.total.amount) / 100),
			}

		});

		products = products.sort((a, b) => b.quantity - a.quantity);


		setTableData(products);

	}, [productData]);


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
							   <path d="M345.3 123.49c0-20.91-17.82-37.92-39.71-37.92h-98.83c-21.9 0-39.71 17.01-39.71 37.92v55.19h-58.1v207.7c0 21.9 17.82 39.71 39.71 39.71H363.7c21.9 0 39.71-17.82 39.71-39.71v-207.7h-58.1v-55.19zm-142.26 0c0-.49 1.28-1.92 3.71-1.92h98.83c2.44 0 3.71 1.42 3.71 1.92v55.19H203.04v-55.19zm164.36 91.19v171.7c0 2.05-1.67 3.71-3.71 3.71H148.65c-2.05 0-3.71-1.67-3.71-3.71v-171.7h22.1v68.35h36v-68.35H309.3v68.35h36v-68.35h22.1z" />
							 </svg>
						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Product Sales (APP only)</h2>	
		        		<div className="widget-v3-subtitle">Sales metrics for each product</div>
		        	</div>
		            
		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div>
								<MaterialTable
									title=""
									columns={[
										{ title: 'Product', field: 'name', },
										{ title: 'Product Category', field: 'category', 
											// lookup: { 
											// 	'Morgen': 'Morgen', 
											// 	'Morgen TO GO': 'Morgen TO GO', 
											// 	'Frokost/Aften': 'Frokost/Aften', 
											// 	'Frokost/Aften TO GO': 'Frokost/Aften TO GO',
											// 	'Varme Drikke': 'Varme Drikke',
											// 	'Varme Drikke TO GO': 'Varme Drikke TO GO',
											// 	'Kolde Drikke': 'Kolde Drikke',
											// 	'Kolde Drikke TO GO': 'Kolde Drikke TO GO',
											// 	'Toppings - Morgen': 'Toppings - Morgen',
											// 	'Toppings - Aften': 'Toppings - Aften',
											// 	'Combos': 'Combos',
											// 	'Combos TO GO': 'Combos TO GO',
											// 	'Tilbehør': 'Tilbehør',
											// 	'Rabatter': 'Rabatter',
											// },
										},
										{ title: 'Product Type', field: 'product_type' },
										{ title: 'Quantity', field: 'quantity', type: 'numeric', filtering: false },
										{ 
											title: 'Total Revenue', field: 'total', type: 'numeric', filtering: false,
											render: (row) => {
								    			const value = row.total;
								    			return (
								    				// <span>{value.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</span>
								    				<span>{value}</span>
								    			)
								    		} 
										},
										{ 
											title: 'Total Discount', field: 'total_discount', type: 'numeric', filtering: false, 
											render: (row) => {
								    			const value = row.total_discount;
								    			return (
								    				// <span>{value.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</span>
								    				<span>{value}</span>
								    			)
								    		}  
								    	},
									]}
									data={tableData}
									components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
									options={{
											search: false,
											pagination: false,
											pageSize: 10,
											pageSizeOptions: [10, 20, 50, 100, 200, 300, 400],
											exportButton: true,
											exportAllData: true,
											filtering: true,
											//toolbar: false,
									}}

									/>

							</div>

						)
					}
				</div>

			      {/*
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All channels</li>
									<li className="widget-v3-footer-filter">OnlinePOS</li>
									<li className="widget-v3-footer-filter">HeapsGO</li>
					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        */}

		    </div>
		</div>	
	);

	
};

export default ProductTableV2;
