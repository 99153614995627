import React from "react";
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

export const SEOKeywords = (props) => {

	return (
		<div className="widget-v2">
		    <div className="widget-v2-header">
		    	<h2 className="widget-v2-title">Søgeord</h2>
		    </div>
			
			<div className="widget-v2-body">
				
				<MaterialTable
				  columns={[
				    { title: 'Keyword', field: 'keyword', type: 'string' },
				    { title: 'Placering', field: 'placement', type: 'number' },
				    { title: 'Udvikling', field: 'progress', type: 'url' },
				    { title: 'Antal søgninger (mnd)', field: 'search_volume' },
				    { 
				    	title: 'URL', 
				    	field: 'url', 
				    	render: (row) => {
				    		const url = new URL(row.url);
				    		return (
				    			<a href={row.url} target="_blank" style={{display: 'inline-block', overflow: 'hidden', width: '200px', whiteSpace: 'noWrap', textOverflow: 'ellipsis'}}>{url.pathname}</a>
				    		)
				    	} 
				    },
				  ]}
				  data={[
				  	{
				  		keyword: 'Risalamande',
				  		placement: 13,
				  		progress: 9,
				  		search_volume: '40.5k',
				  		url: 'https://www.groed.com/risalamande-opskrift-med-hjemmelavet-kirsebaersauce'
				  	},
				  	{
				  		keyword: 'Havregrynskugler',
				  		placement: 19,
				  		progress: 5,
				  		search_volume: '33.1k',
				  		url: 'https://www.groed.com/havregrynskugler'
				  	},
				  	{
				  		keyword: 'Risengrød',
				  		placement: 7,
				  		progress: 1,
				  		search_volume: '27.1k',
				  		url: 'https://www.groed.com/risengroed-opskrift-med-kanelsukker-og-smoerklat'
				  	},
				  	{
				  		keyword: 'Risengrød opskrift',
				  		placement: 7,
				  		progress: 1,
				  		search_volume: '4.400',
				  		url: 'https://www.groed.com/risengroed-opskrift-med-kanelsukker-og-smoerklat'
				  	},
				  	{
				  		keyword: 'Havregrød',
				  		placement: 15,
				  		progress: -1,
				  		search_volume: '18.1k',
				  		url: 'https://www.groed.com/havregroed-opskrift-med-karamelsauce-aeble-og-mandler'
				  	},
				  	{
				  		keyword: 'Havregrød opskrift',
				  		placement: 8,
				  		progress: 0,
				  		search_volume: '2.400',
				  		url: 'https://www.groed.com/havregroed-opskrift-med-karamelsauce-aeble-og-mandler'
				  	},
				  	{
				  		keyword: 'Chiagrød',
				  		placement: 34,
				  		progress: -2,
				  		search_volume: '14.8k',
				  		url: 'https://www.groed.com/chiagroed'
				  	},
				  	{
				  		keyword: 'Chia grød opskrift',
				  		placement: 10,
				  		progress: 10,
				  		search_volume: '1.600',
				  		url: 'https://www.groed.com/chiagroed'
				  	},
				  	{
				  		keyword: 'Klatkager',
				  		placement: 34,
				  		progress: -2,
				  		search_volume: '14.8k',
				  		url: 'https://www.groed.com/klatkager'
				  	},
				  	{
				  		keyword: 'Øllebrød',
				  		placement: 5,
				  		progress: -1,
				  		search_volume: '9.9k',
				  		url: 'https://www.groed.com/oellebroed-opskrift-med-vanilje-aeblekompot-floedeskumsskyr-og-ristede-noedder'
				  	},
				  	{
				  		keyword: 'Øllebrød opskrift',
				  		placement: 3,
				  		progress: 0,
				  		search_volume: '2.4k',
				  		url: 'https://www.groed.com/oellebroed-opskrift-med-vanilje-aeblekompot-floedeskumsskyr-og-ristede-noedder'
				  	},
				  	{
				  		keyword: 'Granola',
				  		placement: 9,
				  		progress: 2,
				  		search_volume: '6.600',
				  		url: 'https://www.groed.com/produkter/granola'
				  	},
				  	{
				  		keyword: 'Dahl opskrift',
				  		placement: 18,
				  		progress: -2,
				  		search_volume: '5.400',
				  		url: 'https://www.groed.com/vegansk-dahl-opskrift'
				  	},
				  	{
				  		keyword: 'Risotto med svampe',
				  		placement: 6,
				  		progress: 4,
				  		search_volume: '3.600',
				  		url: 'https://www.groed.com/risotto-med-svampe'
				  	},
				  	{
				  		keyword: 'Rødgrød med fløde',
				  		placement: 6,
				  		progress: 0,
				  		search_volume: '4.600',
				  		url: 'https://www.groed.com/roedgroed-med-floede'
				  	},
				  	{
				  		keyword: 'Muslibar',
				  		placement: 13,
				  		progress: 17,
				  		search_volume: '1.900',
				  		url: 'https://www.groed.com/muslibar'
				  	},
				  	{
				  		keyword: 'Acai bowl',
				  		placement: 2,
				  		progress: 0,
				  		search_volume: '1.600',
				  		url: 'https://www.groed.com/acai-bowl-med-blabaer-granola-og-peanutbutter'
				  	},
				  	{
				  		keyword: 'Ærterisotto',
				  		placement: 1,
				  		progress: 1,
				  		search_volume: '720',
				  		url: 'https://www.groed.com/aerterisotto-med-citronolie-ravost-og-aerteskud'
				  	},
				  	{
				  		keyword: 'Gulerodsrisotto',
				  		placement: 3,
				  		progress: 0,
				  		search_volume: '210',
				  		url: 'https://www.groed.com/gulerodsrisotto'
				  	},
				  ]}
				  title="SEO rankings"
				  components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
				  options={{
				  		search: false,
				  		pagination: false,
				  		pageSize: 10,
				  		pageSizeOptions: [5, 10, 20],
				  		toolbar: false,
				  }}
				/>
					
			</div>						        

			{/*
		    <div className="widget-v2-footer">
		    	footer
		    </div>
		    */}

		</div>
	); 
};

export default SEOKeywords;
