import React from "react";
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

export const NewsletterCampaigns = (props) => {

	return (
		<div className="widget-v2">
		    <div className="widget-v2-header">
		    	<h2 className="widget-v2-title">Nyhedsbreve</h2>
		    </div>
			
			<div className="widget-v2-body">
				
				<MaterialTable
				  columns={[
				    { title: 'Kampagne', field: 'title', type: 'string' },
				    { title: 'Dato', field: 'date', type: 'string' },
				    { title: 'Modtagere', field: 'recipients', type: 'number' },
				    { title: 'Åbningsrate', field: 'opens', type: 'number' },
				    { title: 'Klik', field: 'clicks', type: 'number' },
				    { title: 'Unsubscribes', field: 'unsubscribes', type: 'number' },
				    { 
				    	title: 'URL', 
				    	field: 'url', 
				    	render: (row) => {
				    		const url = new URL(row.url);
				    		return (
				    			<a href={row.url} target="_blank" style={{display: 'inline-block', overflow: 'hidden', width: '200px', whiteSpace: 'noWrap', textOverflow: 'ellipsis'}}>{url.pathname}</a>
				    		)
				    	} 
				    },
				  ]}
				  data={[
				  	{
				  		title: 'Jordbær/peanut butter',
				  		date: '14. juli 2021 14:24',
				  		recipients: 5174,
				  		opens: '2.056 (39,8%)',
				  		clicks: '155 (3.0%)',
				  		unsubscribes: '47',
				  		url: 'https://mailchi.mp/4af2eb788d2e/indbegrebet-af-dansk-sommer-danske-jordbr'
				  	},
				  	{
				  		title: 'Rødgrød nyhedsbrev',
				  		date: '21. juni 2021 14:07',
				  		recipients: 4830,
				  		opens: '1.936 (40,1%)',
				  		clicks: '201 (4.2%)',
				  		unsubscribes: '57',
				  		url: 'https://mailchi.mp/5d8caf46af52/smagen-af-dansk-sommer'
				  	},
				  	{
				  		title: 'Ærterisotto',
				  		date: '17. juni 2021 09:18',
				  		recipients: 4697,
				  		opens: '1.662 (35,4%)',
				  		clicks: '290 (6.2%)',
				  		unsubscribes: '30',
				  		url: 'https://mailchi.mp/a0932c97abf8/den-lnge-ventede-rterisotto-er-tilbage-p-menukortet'
				  	},
				  	{
				  		title: 'Granola',
				  		date: '31. maj 2021 15:38',
				  		recipients: 4140,
				  		opens: '1.647 (39,8%)',
				  		clicks: '99 (2.4%)',
				  		unsubscribes: '60',
				  		url: 'https://mailchi.mp/a352f0f7a5fb/hvad-er-den-perfekte-granola'
				  	},
				  	{
				  		title: 'Sommermenu',
				  		date: '19. maj 2021 11:45',
				  		recipients: 3835,
				  		opens: '1.658 (43,3%)',
				  		clicks: '363 (9,5%)',
				  		unsubscribes: '32',
				  		url: 'https://mailchi.mp/2800f1d93dab/ny-sommermenu-med-aspargesrisotto-se-videoen-her'
				  	},
				  	{
				  		title: 'Chr.Havn',
				  		date: '30. apr 2021 11:05',
				  		recipients: 3788,
				  		opens: '1.860 (49,2%)',
				  		clicks: '2 (0,1%)',
				  		unsubscribes: '43',
				  		url: 'https://mailchi.mp/27bd12c8fadb/havregrd-til-5kr-vi-bner-p-christianshavn'
				  	},
				  	{
				  		title: 'Genåbning',
				  		date: '20. apr 2021 19:09',
				  		recipients: 1813,
				  		opens: '1.822 (47,8%)',
				  		clicks: '88 (2,3%)',
				  		unsubscribes: '63',
				  		url: 'https://mailchi.mp/3be56aeec129/onsdag-d-214-bner-grd-for-siddende-gster-ydmygt-tak-for-sttten-til-alle'
				  	},
				  	{
				  		title: 'Forårsmenu - gulerodsrisotto',
				  		date: '8. mar 2021 14:48',
				  		recipients: 1903,
				  		opens: '1.288 (67,8%)',
				  		clicks: '356 (18,7%)',
				  		unsubscribes: '27',
				  		url: 'https://mailchi.mp/46a92e849cd7/ny-forrsmenu-gratis-opskrift'
				  	},
				  
				  ]}
				  title="Survival of the phattest — Wall of flame 🔥"
				  components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
				  options={{
				  		search: false,
				  		pagination: false,
				  		pageSize: 10,
				  		pageSizeOptions: [5, 10, 20],
				  		toolbar: false,
				  }}
				/>
					
			</div>						        

			{/*
		    <div className="widget-v2-footer">
		    	footer
		    </div>
		    */}

		</div>
	); 
};

export default NewsletterCampaigns;
