import React from "react";
//import { Link, NavLink } from "react-router-dom";

export const ReviewStars = (props) => {


	const create_stars = (rating, total) => {

		let star_map = [];

		if (!rating) {
			return star_map;
		}

		// Add full stars
		if (rating > 0) {
			for (var i = 1; i <= Math.floor(rating); i++) {
				star_map.push('full');
			}	
		}

		// Add half star
		if (rating != Math.floor(rating)) {
			star_map.push('half');
		}

		// Add Empty stars
		if (rating > 0) {
			for (var i = 0; i < 5 - Math.ceil(rating); i++) {
				star_map.push('empty');
			}	
		}

		return star_map
	}


	return (
		<div className="Stars">
			{create_stars(props.rating, props.total).map((s, i) => { 
				if (s === 'full') {												    		    							
					return ( 
						<svg key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.9 200.5l-166.4-32.1-82-148.4-82 148.3L6.1 200.4l115.7 123.9L101 492.5l153.5-71.8L408 492.5l-21-168.2 115.9-123.8z" fill="#020302"/></svg>
					)
				}

				if (s === 'half') {
					return ( 
						<svg key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M254.5 119.4l39.9 72.2 10.9 19.7 22.1 4.3 80.8 15.6-56.4 60.2-15.4 16.5 2.8 22.4 10.1 81.8-74.6-34.9-20.4-9.6.2-248.2m0-99.4l-82 148.3L6.1 200.4l115.7 123.9L101 492.5l153.5-71.8L408 492.5l-21-168.2 115.9-123.8-166.4-32.1-82-148.4z" fill="#020302"/></svg>
					)
				}

				if (s === 'empty') {
					return ( 
						<svg key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M254.5 119.4l39.9 72.2 10.9 19.7 22.1 4.3 80.8 15.6-56.4 60.2-15.4 16.5 2.8 22.4 10.1 81.8-74.6-34.9-20.4-9.6-20.4 9.6-74.5 34.8 10.1-81.8 2.8-22.3-15.4-16.4-56.3-60.3 80.9-15.6 22.1-4.3 10.9-19.7 40-72.2m0-99.4l-82 148.3L6.1 200.4l115.7 123.9L101 492.5l153.5-71.8L408 492.5l-21-168.2 115.9-123.8-166.4-32.1-82-148.4z" fill="#020302"/></svg>
					)
				}

			})}
		</div>		
	); 
};

export default ReviewStars;
