import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";

// Charts
import SocialMediaStats from "../components/widgets/marketing/SocialMediaStats";
import TimelineSocialMedia from "../components/widgets/marketing/TimelineSocialMedia";
import SocialMediaChannels from "../components/widgets/marketing/SocialMediaChannels";

export const SocialMedia = (props) => {

	// //STATE
	// const [filterData, setFilterData] = useState({
	// 	active_start_date: new Date('2021-06-21'),
	// 	active_end_date: new Date('2021-07-01'),	
	// });

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Social Media (Uge 32)</h1>

									<div className="page-description">
										<p>
											Denne side bliver ikke opdateret automatisk! Sidst opdateret: 16 august 2021
										</p>
									</div>
								</div>

							</div>
						
							<div className="page-charts">
							
								{/*
								<div className="page-chart-with-sidebar-element">
									
									<div className="page-chart-with-sidebar-element-sidebar">
										<SocialMediaStats/>
									</div>

									<div className="page-chart-with-sidebar-element-main">
										<TimelineSocialMedia/>
									</div>

								</div>
								*/}

								<SocialMediaChannels/>
							
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(SocialMedia, {
  onRedirecting: () => <Loading />,
});
