import React, {useState} from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../components/Loading";
import Navigation from "../components/Navigation";
import BrandBar from "../components/BrandBar";
import Filters from "../components/Filters";

// import React, { Component } from 'react';
// import axios from 'axios';
// import moment from 'moment';
// import MaterialTable from 'material-table';

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import Filters from '../components/Filters';

// import PieProductCategories from '../components/charts/PieProductCategories';
// import PieProductCategoriesOrderYOYO from '../components/charts/PieProductCategoriesOrderYOYO';
// import ScatterByCategory from '../components/charts/ScatterByCategory';

import ProductTimeline from '../components/widgets/ProductTimeline';
import ProductTable from '../components/widgets/ProductTable';
import ProductTableV2 from '../components/widgets/orders/ProductTable';
import PieProductCategories from '../components/widgets/PieProductCategories';
import TakeAwayBars from '../components/widgets/TakeAwayBars';

// const groed_api_url = process.env.REACT_APP_GROED_API_URL;


export const MenuEngineering = (props) => {
	//STATE
	const [filterData, setFilterData] = useState({
		active_department_ids: [],
		active_department_labels: ['Alle afdelinger'],
		active_start_date: new Date(),
		active_end_date: new Date(),
		// active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		// active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
	});



	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Dashboard"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">
								
							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Menu Engineering</h1>

									<div className="page-description">
										<p>Her kan du grave ned i salget af specifikke produkter og fordelingen i produktgrupper</p>
									</div>
								</div>

								<div className="page-filters-container">
									<Filters filterData={filterData} setFilterData={setFilterData} departments={props.globalData.departments}/>
								</div>
							</div>
						
							<div className="page-charts">
								
								<ProductTimeline 
		                        	department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
									// compared_start_date={filterData.active_compared_start_date}
									// compared_end_date={filterData.active_compared_end_date}
		                        />

								<TakeAwayBars 
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>

								<PieProductCategories
    	                        	department_ids={filterData.active_department_ids}
    								start_date={filterData.active_start_date}
    								end_date={filterData.active_end_date}
		                        />
							
								<ProductTable
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>

								<ProductTableV2
									department_ids={filterData.active_department_ids}
									start_date={filterData.active_start_date}
									end_date={filterData.active_end_date}
								/>
								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

}

export default withAuthenticationRequired(MenuEngineering, {
  onRedirecting: () => <Loading />,
});