import React, {useState, useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import axios from 'axios';

import Loading from "./components/Loading";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import MenuEngineering from './pages/MenuEngineering';
import Waste from './pages/Waste';
import Competition from './pages/Competition';
import SocialMedia from './pages/SocialMedia';
import SocialMediaInstagram from './pages/SocialMediaInstagram';
import SocialMediaFacebook from './pages/SocialMediaFacebook';
import SocialMediaTikTok from './pages/SocialMediaTikTok';
import SocialMediaLinkedIn from './pages/SocialMediaLinkedIn';
import SocialMediaYouTube from './pages/SocialMediaYouTube';

import Orders from './pages/performance/Orders';
import ROLF from './pages/ROLF';

import Profiles from './pages/marketing/Profiles';
import Influencers from './pages/marketing/Influencers';
import Profile from './pages/marketing/Profile';
import ProfileAnalytics from './pages/marketing/ProfileAnalytics';
import ProfileInsights from './pages/marketing/ProfileInsights';

import WebsiteAndSEO from './pages/WebsiteAndSEO';
import Newsletter from './pages/Newsletter';

import { useAuth0 } from "@auth0/auth0-react";
import history from "./history";

import ScrollToTop from "./components/ScrollToTop";

function App() {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;
	
	// Variables from Auth0
	const { isLoading, error, getAccessTokenSilently } = useAuth0();

	// Loading state
	const [apiIsLoading, setApiIsLoading] = useState(true);

	// STATE: Invetory data
	const [globalData, setGlobalData] = useState({
		departments: [],
	});

	/**
  	 * Get data from DB
  	 */
	useEffect(() => {
		
		(async () => {

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get counts Aggregated by accounts (categories)
				const departmentsData = await axios.get(`${api_url}/departments/`, {headers: { 'Authorization': `Bearer ${access_token}`},});
				const departments = departmentsData.data.departments;

				setApiIsLoading(false);
				setGlobalData(prevState => ({
					...prevState,
					departments: departments
				}));

				
			} catch (e) {
				setApiIsLoading(false);
        		console.error(e);
      		}

		})();		

	}, [
		getAccessTokenSilently, 
		api_url
	]);


	if (error) {
	  return <div>Oops... {error.message}</div>;
	}

	if (isLoading || apiIsLoading) {
	  return <Loading />;
	}

	return (
		<Router history={history}>
			<div className="App">
				<ScrollToTop/>
				<Switch>
				  <Route path="/" exact component={Login} globalData={globalData}/>
				  <Route path="/dashboard" render={(props) => (<Dashboard globalData={globalData} {...props} />)}/>
				  
				  <Route path="/performance/menu-engineering" render={(props) => (<MenuEngineering globalData={globalData} {...props} />)}/>
				  <Route path="/performance/waste" render={(props) => (<Waste globalData={globalData} {...props} />)}/>
				  <Route path="/performance/competition" render={(props) => (<Competition globalData={globalData} {...props} />)}/>

				  <Route path="/performance/orders" render={(props) => (<Orders globalData={globalData} {...props} />)}/>

				  <Route path="/performance/rolf" render={(props) => (<ROLF globalData={globalData} {...props} />)}/>
				  
				  <Route path="/marketing/social-media" exact render={(props) => (<SocialMedia globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/social-media/instagram/:profile" render={(props) => (<SocialMediaInstagram globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/social-media/facebook/:profile" render={(props) => (<SocialMediaFacebook globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/social-media/TikTok/:profile" render={(props) => (<SocialMediaTikTok globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/social-media/LinkedIn/:profile" render={(props) => (<SocialMediaLinkedIn globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/social-media/YouTube/:profile" render={(props) => (<SocialMediaYouTube globalData={globalData} {...props} />)}/>

				  
				  <Route path="/marketing/profile-analytics" render={(props) => (<ProfileAnalytics globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/profile-insights" render={(props) => (<ProfileInsights globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/profiles" exact render={(props) => (<Profiles globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/influencers" exact render={(props) => (<Influencers globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/profiles/:profile" exact render={(props) => (<Profile globalData={globalData} {...props} />)}/>


				  <Route path="/marketing/website-and-seo" render={(props) => (<WebsiteAndSEO globalData={globalData} {...props} />)}/>
				  <Route path="/marketing/newsletter" render={(props) => (<Newsletter globalData={globalData} {...props} />)}/>
				</Switch>
			</div>
		</Router>
		
  );
}

export default App;
