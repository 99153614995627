import React, { useState, useEffect, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

export const ProfilesTable = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	const [profileEventsData, setProfileEventsData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		

		(async () => {
			setWidgetState('loading');
			
			const profile_id = props.match.params.profile;

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get styles data
				const profileEventsDataResponse = await axios.get(`${api_url}/profile-events/${profile_id}`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
					    //start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
					}
				});
				
				// Sort events by date
				const profile_event_data = profileEventsDataResponse.data.data.sort( (a, b) => new Date(b.date) - new Date(a.date) );

				// Set data state
				setProfileEventsData(profile_event_data);

				// Set widget state
				setWidgetState('done');
				
			} catch (e) {
        		console.error('error', e);

        		setProfileEventsData([]);
        		
        		setWidgetState('error');
      		}

			
		})();		

	}, [
		getAccessTokenSilently, 
	]);


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	let eventlist_temp_date = false;

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">

							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
							   <path d="M292.02 461.89c-3.7 0-7.35-.85-10.74-2.51-5.91-2.89-10.33-7.9-12.44-14.11l-73.36-209.86a31.975 31.975 0 0 1-.1-20.5c2.65-7.97 8.3-14.5 15.88-18.29 7.56-3.77 16.19-4.42 24.21-1.72l209.43 69.8a24.448 24.448 0 0 1 15.83 16.54c3.7 13.02-3.86 26.59-16.81 30.32l-98.34 28.02-29.97 104.52c-2.24 7.67-7.99 13.79-15.47 16.4-2.68.93-5.4 1.39-8.12 1.39zm14.06-29.71.08.22c-.03-.07-.06-.15-.08-.22zm-68.09-194.82 52.64 150.63 23.03-80.39 72.87-20.74-148.54-49.5zm194.39 64.8c.03 0 .05.01.08.01l-.08-.01zm-199.61-79.71.1.31c-.02-.1-.05-.21-.1-.31zM112.84 333.08c-5.06 0-10.1-1.93-13.96-5.78-7.71-7.71-7.71-20.2 0-27.91l33.88-33.88c7.71-7.71 20.2-7.71 27.91 0s7.71 20.2 0 27.91l-33.88 33.88a19.655 19.655 0 0 1-13.95 5.78zm5.11-99.76H70.07c-10.9 0-19.74-8.84-19.74-19.74s8.84-19.74 19.74-19.74h47.89c10.9 0 19.74 8.84 19.74 19.74s-8.85 19.74-19.75 19.74zm171.55-66.4c-5.04 0-10.1-1.93-13.96-5.77-7.71-7.7-7.71-20.2 0-27.91l33.85-33.9c7.71-7.72 20.2-7.72 27.91-.03s7.71 20.2 0 27.91l-33.85 33.9c-3.85 3.87-8.89 5.8-13.95 5.8zm-142.12 0c-5.06 0-10.1-1.93-13.96-5.78l-33.88-33.9c-7.71-7.71-7.71-20.22 0-27.93 7.74-7.7 20.23-7.69 27.91.01l33.88 33.9c7.71 7.71 7.71 20.22 0 27.93a19.72 19.72 0 0 1-13.95 5.77zm66.42-29.43c-10.9 0-19.74-8.84-19.74-19.74v-47.9c0-10.9 8.84-19.74 19.74-19.74s19.74 8.84 19.74 19.74v47.9c0 10.9-8.84 19.74-19.74 19.74z" />
							</svg>

						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Event History</h2>	
		        		<div className="widget-v3-subtitle">All events recorded on this profile</div>
		        	</div>    
		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="widget-event-history">
								{
									profileEventsData.length > 0 && (
										<ul className="widget-event-history-list">

											{
												profileEventsData.map((event, i) => {

													let new_date = false;

													if (eventlist_temp_date !== moment(event.date).format('YYYY-MM-DD')) {
														new_date = true;
														eventlist_temp_date = moment(event.date).format('YYYY-MM-DD')
													}
													
													
													return (
														<Fragment key={i}>
															
															{
																new_date && (
																	<li className="widget-event-history-list-item">
																		
																		<div className="widget-event-history-date-header">
																			<div className="widget-event-history-date-header-icon">
																				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
																					<path d="M403.44 48.09h-1.52c-.76-11.52-10.35-20.63-22.06-20.63-11.71 0-21.3 9.11-22.06 20.63H154.2c-.76-11.52-10.35-20.63-22.06-20.63-11.71 0-21.3 9.11-22.06 20.63h-1.52c-44.72 0-81.09 36.37-81.09 81.09v274.26c0 44.72 36.37 81.09 81.09 81.09h294.89c44.72 0 81.09-36.37 81.09-81.09V129.19c0-44.72-36.37-81.1-81.1-81.1zM108.56 92.33h1.47v29.49c0 12.21 9.91 22.12 22.12 22.12s22.12-9.91 22.12-22.12V92.33h203.48v29.49c0 12.21 9.91 22.12 22.12 22.12s22.12-9.91 22.12-22.12V92.33h1.47c20.33 0 36.86 16.53 36.86 36.86v42.76H71.69v-42.76c0-20.33 16.53-36.86 36.87-36.86zm294.88 347.98H108.56c-20.33 0-36.86-16.53-36.86-36.86V216.19h368.61v187.26c0 20.33-16.53 36.86-36.87 36.86z" />
																					<path d="M214.7 275.16h-82.56c-12.21 0-22.12 9.91-22.12 22.12v82.56c0 12.21 9.91 22.12 22.12 22.12h82.56c12.21 0 22.12-9.91 22.12-22.12v-82.56c0-12.21-9.91-22.12-22.12-22.12zm-22.11 82.57h-38.33V319.4h38.33v38.33z" />
																				</svg>
																			</div>	
																			<div className="widget-event-history-date-header-label">{moment(event.date).format('MMMM DD, YYYY')}</div>
																		</div>

																	</li>
																)
															}

															<li className="widget-event-history-list-item">
																<div className="widget-event-history-entry">
																	<div className="widget-event-history-entry-time">{moment(event.date).format('HH.mm.ss')}</div>
																	<div className="widget-event-history-entry-tag">{event.source}</div>
																	<div className="widget-event-history-entry-description">{event.event_name}</div>
																</div>
															</li>	
														</Fragment>
													)
												})
											}
										</ul>
									)
								}

							</div>
						)
					}
				</div>

			      {/*
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All channels</li>
									<li className="widget-v3-footer-filter">OnlinePOS</li>
									<li className="widget-v3-footer-filter">HeapsGO</li>
					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        */}

		    </div>
		</div>	
	); 
};

export default ProfilesTable;

