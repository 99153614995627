import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';


export const TakeAwayBars = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,
		revenue_by_category: [],
		bars: [],
		formatted_categories: [],
	});	

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * Format categories
	 */
	useEffect(() => {
		formatCategories();
	}, [widgetState.revenue_by_category]);


	/**
	 * Handle data load
	 */
	const loadAllData = () => {

		//console.log('load data for TakeAwayBars');
		
		setIsLoading(true);

		const aggregatedRevenuePromise = loadAggregatedRevenue();

		Promise.all([aggregatedRevenuePromise])
			.then( values => {

				//console.log('Data for TakeAwayBars has loaded');

				setWidgetState({ 
					...widgetState,
					revenue_by_category: values[0].data.revenue_by_category,
				});
				setIsLoading(false);
			})
			.catch(error => {
				setWidgetState({
					...widgetState,
					error: true,
					revenue_by_category: []
				});
				setIsLoading(false);
				console.log(error);
			}
		);

	}

	/**
	* Load sales data
	*/
	const loadAggregatedRevenue = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/revenue/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: 'year', // hour, day, month or year
				// compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
    			// compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}


	const formatCategories = () => {

		const category_objects = widgetState.revenue_by_category;

		let category_pairs = [
			{
				main: 'Morgen',
				togo: 'Morgen TO GO',
				total_revenue: 0,
				togo_revenue: 0,
				color: '#4D80FF',
			},
			{
				main: 'Frokost/Aften',
				togo: 'Frokost/Aften TO GO',
				total_revenue: 0,
				togo_revenue: 0,
				color: '#29D0A9',
			},
			{
				main: 'Kolde Drikke',
				togo: 'Kolde Drikke TO GO',
				total_revenue: 0,
				togo_revenue: 0,
				color: '#CC8632',
			},
			{
				main: 'Varme Drikke',
				togo: 'Varme Drikke TO GO',
				total_revenue: 0,
				togo_revenue: 0,
				color: '#E33F3F',
			}
		]

		let total = 0;
		let total_togo = 0;

		//console.log(category_objects);
		for (const category_pair of category_pairs) {

			// Current index
			const i = category_pairs.findIndex(c => c.main === category_pair.main);

			let tostay_revenue = 0;
			let togo_revenue = 0;

			// Find to stay revenue 
			const tostay = category_objects.find(category => category.category === category_pair.main);
			if (tostay) {
				tostay_revenue = tostay.revenue_excl_vat;	
			}

			// Find to go revenue
			const togo = category_objects.find(category => category.category === category_pair.togo);
			if (togo) {
				togo_revenue = togo.revenue_excl_vat;	
			}

			// Set total and to go value
			category_pairs[i].togo_revenue = togo_revenue;
			category_pairs[i].total_revenue = tostay_revenue + togo_revenue;

			// add to totals variables
			total_togo += togo_revenue;
			total += tostay_revenue + togo_revenue;

		}

		const formatted_categories = category_pairs.map( cat => {

			return {
				title: cat.main,
				total_revenue: parseInt(cat.total_revenue / 100),
				togo_revenue: parseInt(cat.togo_revenue / 100),
				togo_percentage: parseInt((cat.togo_revenue / cat.total_revenue) * 100) || 0,
				color: cat.color,
			}

		});

		formatted_categories.push({
			title: 'Total',
			total_revenue: parseInt(total / 100),
			togo_revenue: parseInt(total_togo / 100),
			togo_percentage: parseInt((total_togo / total) * 100) || 0,
			color: '#000000',	
		})

		setWidgetState({
			...widgetState,
			formatted_categories: formatted_categories,
		});

	}


	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className={`widget-v2 ${loadingClass}`}>
		    <div className="widget-v2-header">
		    	<h2 className="widget-v2-title">TO GO vs. TO STAY</h2>
		    </div>
			
			<div className="widget-v2-body">
					{
						widgetState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot <span role="img" aria-label="heart">❤️</span></p>
						)
					}

					{
						isLoading === true ? (
							<div className="responsive-line-container">
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							</div>
						) : (
							<div className="barlines-container">
							
								<div className="barlines">

									<div className="barlines-header">
										<div className="barlines-header-line"></div>
										<div className="barlines-header-line">TO GO procent</div>
										<div className="barlines-header-line">Omsætning</div>
									</div>

									{

										widgetState.formatted_categories.map( (bar, i) => {

											return (
												<div className="barline" key={i}>
													<div className="barline-header-dot" style={{backgroundColor: bar.color}}></div>
													<div className="barline-header">{bar.title}</div>
													<div className="barline-bar-container">
														<div className="barline-bar">
															<div className="barline-bar-percentage" style={{width: bar.togo_percentage + '%', backgroundColor: bar.color}}></div>
															<div className="barline-bar-number">{bar.togo_percentage + '%'}</div>
														</div>
													</div>
													<div className="barline-footer">DKK {bar.togo_revenue.toLocaleString("da-DK")}<span>&nbsp;&nbsp;</span>/<span>&nbsp;&nbsp;</span>{bar.total_revenue.toLocaleString("da-DK")}</div>
												</div>
											)

										})
									}

								</div>

							</div>
						)
					}
					
			</div>						        

			
		    <div className="widget-v2-footer">
		    	<ul className="widget-footer-filters">
					<li className="widget-footer-filter widget-footer-filter--active">OnlinePOS + Wolt</li>
					<li className="widget-footer-filter" style={{textDecoration: 'line-through', cursor: 'initial'}}>GRØD App</li>
					<li className="widget-footer-filter" style={{textDecoration: 'line-through', cursor: 'initial'}}>OrderYOYO</li>
					{/*<li className="widget-footer-filter" style={{textDecoration: 'line-through', cursor: 'initial'}}>Wolt</li>*/}

	        	</ul>
		    </div>
		    

		</div>
	); 
};

export default TakeAwayBars;