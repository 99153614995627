import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

// Charts
import SimpleBars from '../../charts/SimpleBars';

/**
 * Get profiles age split
 */
const getProfilesAgeSplit = async (access_token) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-age-split/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`}
	});

	return axiosPromise;		
}

/**
 * Get profiles age split by platform
 */
const getProfilesAgeSplitByPlatform = async (access_token) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-age-split-by-source/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`}
	});

	return axiosPromise;		
}



export const ProfilesAgeRange = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	const [profilesAgeData, setProfilesAgeData] = useState([]);

	// Loading state
	const [sourceFilter, setSourceFilter] = useState('All');

	// First entry is always the master
	const [barData, setBarData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		const loadAllData = async () => {

			setWidgetState('loading');

			// Get access token
			const access_token = await getAccessTokenSilently();
			
			const profilesAgeSplit = getProfilesAgeSplit(access_token);
			const profilesAgeSplitByPlatform = getProfilesAgeSplitByPlatform(access_token);
			
		
			Promise.all([profilesAgeSplit, profilesAgeSplitByPlatform])
				.then( values => {

					const profilesAgeSplit = values[0].data.data;
					const profilesAgeSplitByPlatform = values[1].data.data.map(d => {

						// if(d.platform === 'Mixpanel'){
						// 	d.platform = 'GRØD App';
						// }

						// if(d.platform === 'Klaviyo'){
						// 	d.platform = 'Mailing list';
						// }

						// if(d.platform === 'Shopify'){
						// 	d.platform = 'Webshop';
						// }

						return d;

					});

					const ages = [
						{
							platform: 'All',
							ages: [...profilesAgeSplit]
						},
						...profilesAgeSplitByPlatform
					];
					
					setProfilesAgeData(ages);

					setWidgetState('done');
				})
				.catch(error => {
					setProfilesAgeData([]);
					
					setWidgetState('error');
					
					console.log(error);
				});
		}
		loadAllData();



	}, [
		getAccessTokenSilently, 
	]);


	useEffect(() => {


		const dataset = profilesAgeData.find(a => a.platform === sourceFilter);


		if(dataset) {
			
			const ages = dataset.ages;

			/**
			 * Age Ranges
			 **/
			let ranges = [
				{ label: '0-12', start: 0, end: 12, count: 0, },
				{ label: '13-17', start: 13, end: 17, count: 0, },
				{ label: '18-24', start: 18, end: 24, count: 0, },
				{ label: '25-34', start: 25, end: 34, count: 0, },
				{ label: '35-44', start: 35, end: 44, count: 0, },
				{ label: '45-54', start: 45, end: 54, count: 0, },
				{ label: '55-64', start: 55, end: 64, count: 0, },
				{ label: '65+', start: 65, end: 200, count: 0, },
				{ label: 'N/A', start: 1000, end: 2000, count: 0, },
			]


			const total = ages.reduce( (previousValue, currentValue) => {
				return previousValue + currentValue.count;
			}, 0)

			const bars = ranges.map(range => {

				ages.map(entry => {

					if(entry.age === 'Not specified' && range.label === 'N/A') {
						range.count = range.count + entry.count;
					}				

					if(entry.age > range.start && entry.age < range.end) {
						range.count = range.count + entry.count;
					}

				});

				const obj = {
					label: range.label,
					value: range.count,
					total:  total,
					data: [
						{
							data_name: range.label,
							color: '#2771D5',
							value: range.count,
						},
					]
				};

				return obj;

			});

			setBarData(bars);

		}

	}, [
		profilesAgeData,
		sourceFilter
	]);



	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setSourceFilter(new_mode);
	}

	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Age Range</h2>	
		        	</div>

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<SimpleBars
								data={barData}
								showLegends={false}
							/>

						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									{

										profilesAgeData.map((source, i) => {

											let activeClass = '';
											if (sourceFilter === source.platform) {
												activeClass = 'widget-footer-filter--active';
											}


											return (
												<li key={i} className={`widget-v3-footer-filter ${activeClass}`} onClick={() => changeSourceFilter(source.platform)}>{source.platform}</li>
											)
										})
									}

					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesAgeRange;

