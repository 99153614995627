import React from "react";
import loading from "../assets/loading.svg";

const Loading = () => (
  <div className="spinner-page-wrapper">
	  <div className="spinner spinner-centered">
	    <img src={loading} alt="Loading" />
	  </div>
  </div>
);

export default Loading;
