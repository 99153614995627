import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";
import Redacted from "../../Redacted";

export const OrdersTableProfile = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	const [ordersData, setOrdersData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');

			
			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				let params = {
					limit: 10,
				}

				if(props.profile_id) {
					params.profile_id = props.profile_id;
				}

				if(props.start_date && props.end_date) {
					params.start_date = moment(props.start_date).format('YYYY-MM-DD 00:00');
					params.end_date = moment(props.end_date).format('YYYY-MM-DD 23:59');
				}

				if(props.department_ids) {
					params.department_ids = props.department_ids;
				}	

				// Get styles data
				const ordersDataResponse = await axios.get(`${api_url}/orders/`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: params
				});

				setOrdersData(ordersDataResponse.data.data);

				setWidgetState('done');
				
			} catch (e) {
        		console.error('error', e);

        		setOrdersData([]);
        		
        		setWidgetState('error');
      		}

		})();		

	}, [
		getAccessTokenSilently, 
		props.start_date,
		props.end_date,
	]);


	function showDetails(order) {
		props.setDrawerState('open');
		props.setDrawerOrder(order);
	}


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">
  							 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
  							    <path d="M345.3 123.49c0-20.91-17.82-37.92-39.71-37.92h-98.83c-21.9 0-39.71 17.01-39.71 37.92v55.19h-58.1v207.7c0 21.9 17.82 39.71 39.71 39.71H363.7c21.9 0 39.71-17.82 39.71-39.71v-207.7h-58.1v-55.19zm-142.26 0c0-.49 1.28-1.92 3.71-1.92h98.83c2.44 0 3.71 1.42 3.71 1.92v55.19H203.04v-55.19zm164.36 91.19v171.7c0 2.05-1.67 3.71-3.71 3.71H148.65c-2.05 0-3.71-1.67-3.71-3.71v-171.7h22.1v68.35h36v-68.35H309.3v68.35h36v-68.35h22.1z" />
  							  </svg>
						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Orders</h2>	
		        		<div className="widget-v3-subtitle">All orders by this profile</div>
		        	</div>
		            
		            
		        	<div className="widget-v3-header-actions">
		        	
		        	</div>
		        	
		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div>
								{
									ordersData.length === 0 && (	
										<div className="widget-v3-error">
											<div>No orders</div>
										</div>
									)	
								}

								{
									ordersData.length > 0 && (	
										<div className="widget-style-table">

											<div className="widget-style-table-inner">

												<table className="style-table-table">
													<thead>
														<tr className="style-table-header">
															<th colSpan="2">Date</th>
															<th>Source</th>
															<th>Profile</th>
															<th>Department</th>
															<th>Delivery Type</th>
															<th>Status</th>
															<th>Total</th>
															{/*<th>Created date</th>*/}
															<th></th>
														</tr>
													</thead>
													<tbody>

													  	{
													  		ordersData.map((order, i) => {
													  			console.log(i, order);
													  			const total = (order.total.amount*1.25) / 100;

													  			return (
												  					<tr key={i}>
												  				    	<td className="style-table-field style-table-field-icon-field">
												  				    		
												  				    		<div className="style-table-field-icon">
												  					    		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
												  					    		    <path d="M446.08.64H66.26C30.26.64.98 29.92.98 65.92v379.82c0 36 29.28 65.28 65.28 65.28h379.82c36 0 65.28-29.28 65.28-65.28V65.92c0-36-29.28-65.28-65.28-65.28zm.01 474.77H66.26c-16.37 0-29.67-13.31-29.67-29.67V65.92c0-16.37 13.31-29.67 29.67-29.67h379.82c16.37 0 29.67 13.31 29.67 29.67v379.82h.01c0 16.37-13.31 29.67-29.67 29.67z" />
												  					    		    <path d="M345.3 123.49c0-20.91-17.82-37.92-39.71-37.92h-98.83c-21.9 0-39.71 17.01-39.71 37.92v55.19h-58.1v207.7c0 21.9 17.82 39.71 39.71 39.71H363.7c21.9 0 39.71-17.82 39.71-39.71v-207.7h-58.1v-55.19zm-142.26 0c0-.49 1.28-1.92 3.71-1.92h98.83c2.44 0 3.71 1.42 3.71 1.92v55.19H203.04v-55.19zm164.36 91.19v171.7c0 2.05-1.67 3.71-3.71 3.71H148.65c-2.05 0-3.71-1.67-3.71-3.71v-171.7h22.1v68.35h36v-68.35H309.3v68.35h36v-68.35h22.1z" />
												  					    		</svg>
												  					    	</div>
												  				    	
												  				    	</td>
												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">{moment(order.date).format('DD.MM.YYYY HH:mm')}</div>
												  				    	</td>
												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">{order.source}</div>
												  				    	</td>

												  				    	<td className="style-table-field">
												  				    		{
												  				    			order.profile && order.profile.first_name && order.profile.last_name ? (
												  				    				<div className="style-table-field-label">
												  				    					{
												  				    						order.profile.first_name === 'REDACTED' ? (
												  				    							<Redacted text="Redacted Name"/>
												  				    						) : (
												  				    							<div>{order.profile.first_name} {order.profile.last_name}</div>
												  				    						)
												  				    					}
												  				    				</div>
												  				    			) : (
												  				    				<div className="style-table-field-label">-</div>
												  				    			)
												  				    		}
												  				    		
												  				    	</td>
												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">{order.department.name}</div>
												  				    	</td>
												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">{order.delivery_type}</div>
												  				    	</td>
												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">{order.state}</div>
												  				    	</td>

												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">{total.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</div>
												  				    	</td>										    	

												  				    {/*
												  				    	<td className="style-table-field">
												  				    		<div className="style-table-field-label">22 April 2021</div>
												  				    	</td>
												  				    */}

												  				    	<td className="style-table-field no-stretch">
												  				    		<div className="style-table-button" onClick={() => showDetails(order)}>Order details</div>
												  				    	</td>
												  				  	</tr>
													  			)
													  		})
													  	}

													  		
													  

													  	{/*	
													  	<tr key="SAMPLE-ROW">
													    	<td className="style-table-field style-table-field-icon-field">
													    		
													    		<div className="style-table-field-icon">
														    		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
														    		    <g id="Layer_2">
														    		      <path d="M281.51 254.88c29.21-10.49 50.15-38.46 50.15-71.24 0-41.73-33.94-75.68-75.67-75.68s-75.69 33.95-75.69 75.68c0 32.96 21.2 61.06 50.67 71.41-46.7 8.89-85.96 42.3-101.77 89.16-7.21 24.74 7.02 50.75 31.76 57.97 4.29 1.26 8.72 1.89 12.68 1.89.53 0 1.07-.01 1.58-.03H337.6c.49.01.97.02 1.48.02 24.9 0 45.65-19.75 46.67-44.92.21-5.1-.42-10.12-2.04-15.64-15.76-46.66-55.24-80.01-102.2-88.62zm-65.59-71.24c0-22.09 17.99-40.07 40.08-40.07s40.06 17.98 40.06 40.07-17.97 40.07-40.06 40.07-40.08-17.98-40.08-40.07zm123.14 184.81c-.37 0-.81-.02-1.16-.02l-163.39.01c-1.18.09-2.39-.09-3.57-.43-5.89-1.73-9.27-7.93-7.77-13.11 13.45-39.85 50.77-66.62 93.01-66.62.32 0 .67-.01 1.02-.01 42.08 0 79.28 26.66 92.54 65.92.32 1.15.49 2.36.44 3.57-.24 5.98-5.18 10.69-11.12 10.69z" />
														    		      <path d="M445.91.81H66.09C30.09.81.81 30.09.81 66.09v379.82c0 36 29.28 65.28 65.28 65.28h379.82c36 0 65.28-29.28 65.28-65.28V66.09c0-36-29.28-65.28-65.28-65.28zm29.68 445.1c0 16.37-13.31 29.67-29.67 29.67H66.09c-16.37 0-29.67-13.31-29.67-29.67V66.09c0-16.37 13.31-29.67 29.67-29.67h379.82c16.37 0 29.67 13.31 29.67 29.67v379.82z" />
														    		    </g>
														    		</svg>
														    	</div>
													    	
													    	</td>
													    	<td className="style-table-field">
													    		<div className="style-table-field-label">Malthe M</div>
													    	</td>
													    	<td className="style-table-field">
													    		<div className="style-table-field-label">malthe@milthers.dk</div>
													    	</td>
													    	<td className="style-table-field">
													    		<div className="style-table-field-label">4.804 DKK</div>
													    	</td>

													    	<td className="style-table-field">
													    		<div className="style-table-field-main-label-container">
														    		<div className="style-table-field-main-label">180.114 DKK</div>
														    		<div className="style-table-tag style-table-tag--positive">+23%</div>
														    	</div>
														    	<div className="style-table-field-secondary-label-container">
														    		<div className="style-table-field-secondary-label">Up from 113.201,21 DKK</div>
														    	</div>
													    	</td>  

													    	<td className="style-table-field">
													    		<div className="style-table-field-label">22 April 2021</div>
													    	</td>

													    	<td className="style-table-field">
													    		<div className="style-table-button">Go to profile</div>
													    	</td>
													  	</tr>
													  	*/}	
													</tbody>
												</table>

											</div>


										</div>
									)	
								}							

								
							</div>
						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
			        	
			        	<div></div>
						{/*
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All channels</li>
									<li className="widget-v3-footer-filter">OnlinePOS</li>
									<li className="widget-v3-footer-filter">HeapsGO</li>
					        	</ul>
							) 
						}

						*/}


						<Link to="/performance/orders">
			        		<div className="widget-v3-footer-link">
				        		<div className="widget-v3-footer-link-label">All Orders</div>
				        		<div className="widget-v3-footer-link-icon">
					        		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
					        		    <path d="m166.48 502.4-67.32-67.32L278.2 255.99 99.16 76.92 166.48 9.6l246.36 246.39z" />
					        		</svg>
				        		</div>
			        		</div>
			        	</Link>			

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default OrdersTableProfile;