import React, { useState, useEffect } from "react";
import moment from 'moment';
import TimelineMainRevenue from './TimelineMain_Revenue';
import TimelineMainOrders from './TimelineMain_Orders';
import TimelineMainBasketsize from './TimelineMain_Basketsize';
import TimelineMainDiff from './TimelineMain_Diffs';
import HeatmapRevenue from './HeatmapRevenue';

export const TimelineWidget = (props) => {

	const options = [
		{ value: 'revenue', label: 'Omsætning og løn', widgetName: 'TimelineMainRevenue' },
		{ value: 'revenue_heatmap', label: 'Omsætning (Heatmap)', widgetName: 'TimelineMainRevenue' },
		{ value: 'orders', label: 'Antal Ordrer', widgetName: 'TimelineMainOrders' },
		{ value: 'basketsize', label: 'Basket Size', widgetName: 'TimelineMainBasketsize' },
		{ value: 'diffs', label: 'Kassedifferencer', widgetName: 'TimelineMainDiffs' },
	];

	
	/**
	 * Set frequency options
	 */
	useEffect(() => {
		updateFrequencyOptions();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);


	// Timeline state
	const [selectedTimeline, setSelectedTimeline] = useState(options[0]);

	// Frequency options and defaults
	const [frequencyOptions, setFrequencyOptions] = useState([]);

	// Frequency state
	const [selectedFrequency, setSelectedFrequency] = useState({ value: 'hour', label: 'Time' });

	const updateFrequencyOptions = () => {

		const start_date = moment(props.start_date);
		const end_date = moment(props.end_date);

		let f_options = [];
		let f_selected = '';

		// Check if hour should be an option
		if (start_date.format('YYYY-MM-DD') === end_date.format('YYYY-MM-DD')) {
			f_options.push({ value: 'hour', label: 'Time' });
			f_options.push({ value: 'day', label: 'Dag' });
			f_selected = 'hour';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'days') >= 1 && end_date.diff(start_date, 'days') <= 31 ) {
			f_options.push({ value: 'day', label: 'Dag' });
			f_options.push({ value: 'week', label: 'Uge' });
			f_options.push({ value: 'month', label: 'Month' });
			f_selected = 'day';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'months') >= 1 && end_date.diff(start_date, 'months') < 24) {
			f_options.push({ value: 'day', label: 'Dag' });
			f_options.push({ value: 'week', label: 'Uge' });
			f_options.push({ value: 'month', label: 'Month' });
			f_options.push({ value: 'year', label: 'år' });
			f_selected = 'month';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'months') >= 24) {
			f_options.push({ value: 'day', label: 'Dag' });
			f_options.push({ value: 'week', label: 'Uge' });
			f_options.push({ value: 'month', label: 'Month' });
			f_options.push({ value: 'year', label: 'år' });
			f_selected = 'year';
		}

		setFrequencyOptions(f_options);

		let currentSelectionExists = false;
		if (selectedFrequency && selectedFrequency.value) {
			currentSelectionExists = f_options.find(option => option.value === selectedFrequency.value);	
		}

		if (!currentSelectionExists) {
			setSelectedFrequency(f_options.find(option => option.value === f_selected));	
		}

	}

	
	// Handle timeline change
	const handleTimelineChange = (selectedTimeline) => {
		setSelectedTimeline(selectedTimeline);
	}

	// Handle frequency change
	const handleFrequencyChange = (selectedFrequency) => {
		setSelectedFrequency(selectedFrequency);
	}

	const selectConfig = {
		options: options,
		value: selectedTimeline,
		onChange: handleTimelineChange,
	}

	const frequencyConfig = {
		options: frequencyOptions,
		value: selectedFrequency,
		onChange: handleFrequencyChange,
	}

	return (
		<div className="widget-v2-container widget-v2-container--timeline-main">
				
			{
				selectedTimeline.value === 'revenue_heatmap' && (
					<HeatmapRevenue select={selectConfig} frequency={frequencyConfig} {...props}/>
				)
			}

			{
				selectedTimeline.value === 'revenue' && (
					<TimelineMainRevenue select={selectConfig} frequency={frequencyConfig} {...props}/>
				)
			}

			{
				selectedTimeline.value === 'orders' && (
					<TimelineMainOrders select={selectConfig} frequency={frequencyConfig} {...props}/>
				)
			}

			{
				selectedTimeline.value === 'basketsize' && (
					<TimelineMainBasketsize select={selectConfig} frequency={frequencyConfig} {...props}/>
				)
			}

			{
				selectedTimeline.value === 'diffs' && (
					<TimelineMainDiff select={selectConfig} {...props}/>
				)
			}

		</div>
	); 
};

export default TimelineWidget;
