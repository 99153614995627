import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

export const EmployeeFoodTable = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,
		product_stats: [],
		table_data: [],
	});

	/**
	 * Set frequency options
	 */
	useEffect(() => {
		loadAllData();	
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);


	useEffect(() => {
		formatTableData();	
	}, [widgetState.product_stats]);

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		const aggregatedProductsPromise = loadAggregatedProducts();
      
		Promise.all([aggregatedProductsPromise])
			.then( values => {
			
				setWidgetState({ 
					...widgetState,
					error: false,
					product_stats: values[0].data.products,
				});

				setIsLoading(false);


			})
			.catch(error => {
				setWidgetState({ 
					...widgetState,
					error: true,
					product_stats: [],

				});

				setIsLoading(false);
				console.log(error);
			}
		);
	}

	/**
	 * Load sales data
	 */
	const loadAggregatedProducts = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/employee-products`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;
	}


	/**
	 * Format table data
	 */
	const formatTableData = () => {

		const product_stats = widgetState.product_stats;

		let filtered_product_stats = product_stats.filter(product => {
			// const accepted_categories = ['*Baser*', '*Råvarer*'];

			// if (accepted_categories.includes(product.product_category)) {
			// 	return true;
			// } else {
			// 	return false;	
			// }
            return true;
		})

		filtered_product_stats = filtered_product_stats.map(product => {
			product.revenue_excl_vat = parseInt(product.revenue_excl_vat / 100);
			product.cost = parseInt(product.cost / 100);
			product.profit = parseInt(product.revenue_excl_vat - product.cost);
			return product;
		});

		setWidgetState({ 
			...widgetState,
			table_data: filtered_product_stats,
		});

	}

	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className={`widget-v2 ${loadingClass}`}>
		    <div className="widget-v2-header">
		    	<h2 className="widget-v2-title">Medarbejdermad</h2>
		    </div>
			
			<div className="widget-v2-body">
					{
						widgetState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot <span role="img" aria-label="heart">❤️</span></p>
						)
					}

					{
						isLoading === true ? (
							<div className="responsive-line-container">
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							</div>
						) : (
							<MaterialTable
								columns={[
									{ title: 'Produkt', field: 'product_name' },
									{ title: 'Produktkategori', field: 'product_category' },
									{ title: 'Antal solgte', field: 'item_count', type: 'numeric' },
									{ title: 'Omkostning', field: 'cost', type: 'numeric' },
									//{ title: 'Avance', field: 'profit', type: 'numeric' },
								]}
								data={widgetState.table_data}
								components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
								title=""
								options={{
									search: true,
									pagination: false,
									pageSize: 10,
									exportButton: true,
                                    exportAllData: true,
                                    filtering: true,
									toolbar: true,
								}}
							/>
						)
					}
					
			</div>						        

			{/*
		    <div className="widget-v2-footer">
		    	footer
		    </div>
		    */}

		</div>
	); 
};

export default EmployeeFoodTable;
