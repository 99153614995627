import React, { useRef, useState, useEffect } from "react";
import Select from 'react-select';
import moment from 'moment';
import DatePickerSelect from './DatePickerSelect';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file


export const Filters = (props) => {

	const ref = useRef(null);
    
	// STATE: Invetory data
	const [filterData, setFilterData] = useState({
		//multi_select: false,
		filter_selected_department_option: { value: 'all', label: 'Alle afdelinger' },
		filter_department_ids: [],

		filter_selected_period_option: { value: 'today', label: 'I dag' },
		filter_start_date: new Date(),
		filter_end_date: new Date(),
		
		filter_selected_compare_option: { value: 'previous-year',label: 'Sidste år' },
		filter_compared_start_date: new Date(),
		filter_compared_end_date: new Date(),

		active_selected_department_option: { value: 'all', label: 'Alle afdelinger' },
		active_department_ids: [],
		active_selected_period_option: { value: 'today', label: 'I dag' },
		active_start_date: new Date(),
		active_end_date: new Date(),
		active_selected_compare_option: { value: 'previous-year',label: 'Sidste år' },
		active_compared_start_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),
		active_compared_end_date: moment().subtract(1, 'years').day(moment(new Date()).day()).toDate(),

		show_start_datepicker: false,
		show_end_datepicker: false,
		selectedOption: { value: 'all', label: 'Alle afdelinger' },

		departments: [],
	});

	useEffect(() => {
		setFilterData(prevProps => ({
			...prevProps,
			departments: props.departments,			
		}));

	}, [props.departments]);

    const restaurants = filterData.departments.filter(department => department.type === 'Restaurant' || department.type === 'Streetfood');
    const restaurantOptions = restaurants.map( department => {
    	return {
    		value: department._id,
    		label: department.name
    	}
    });

    const events = filterData.departments.filter(department => department.type === 'Event');
    const eventOptions = events.map( department => {
    	return {
    		value: department._id,
    		label: department.name
    	}
    });

	const selectGroupedDepartments = [
		{ 
	  		label: 'Vælg afdeling', 
	  		options: [{
	  	  		value: 'all',
				label: 'Alle afdelinger'
	  		}]
	  	},
		{ 
	  		label: 'Afdelinger', 
			options: restaurantOptions,
		},
		{ 
			label: 'Events', 
			options: eventOptions,
		},
		{ 
	  		label: 'Andet', 
	  		options: [
	  			{
	  	  			value: false,
					label: 'Webshop',
					disabled: true
	  	  		},
	  		]
	  	},
	];

	const selectPeriod = [
		{ 
			value: 'today',
			label: 'I dag'
	  	},
		{ 
			value: 'yesterday',
			label: 'I går'
	  	},
	  	// 	{ 
			// value: 'last-7-days',
			// label: 'Sidste 7 dage'
	  	// 	},
	  	{ 
			value: 'last-week',
			label: 'Sidste uge'
	  	},
  	  	{ 
  			value: 'week-to-date',
  			label: 'Uge til dato'
  	  	},
  	  	{ 
  			value: 'last-month',
  			label: 'Sidste måned'
  	  	},
  	  	{ 
  			value: 'month-to-date',
  			label: 'Måned til dato'
  	  	},
  	  	{ 
  			value: 'custom',
  			label: 'Brugerdefineret'
  	  	},
	];

	const selectComparedPeriod = [
		{ 
  			value: 'previous-period',
  			label: 'Sidste periode'
  	  	},
  	  	{ 
  			value: 'previous-year',
  			label: 'Sidste år'
  	  	},
  	  // 	{ 
  			// value: 'custom',
  			// label: 'Brugerdefineret'
  	  // 	},
	];

	const groupStyles = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	};

	const groupBadgeStyles = {
		backgroundColor: '#EBECF0',
		borderRadius: '2em',
		color: '#172B4D',
		display: 'inline-block',
		fontSize: 12,
		fontWeight: 'normal',
		lineHeight: '1',
		minWidth: 1,
		padding: '0.16666666666667em 0.5em',
		textAlign: 'center',
	};

	const formatGroupLabel = data => (
	  <div style={groupStyles}>
	    <span>{data.label}</span>
	    <span style={groupBadgeStyles}>{data.options.length}</span>
	  </div>
	);

	/**
	 * Update compare dates, everytime the filter primary dates changes, or when the compare option changes. 
	 */
	useEffect(() => {	
	
		// Base start and end date + compare from filter
		const start_date = filterData.filter_start_date;
		const end_date = filterData.filter_end_date;
		const compare = filterData.filter_selected_compare_option.value;

		// Find out the diff between start and end in days
		const date_diff = moment(end_date).diff(start_date, 'days');

		// Figure out which day of the week the start date is.
		const start_date_day = moment(start_date).day();

		// Setup compare dates
		let compared_start_date = moment();
		let compared_end_date = moment();

		if (compare === 'previous-year') {

			// Find the closest date 1 year ago with the same weekday.
			compared_start_date = moment(start_date).subtract(1, 'years').day(start_date_day);
			
			// Add diff to start date in order to find the compared end date. 
			compared_end_date = moment(start_date).subtract(1, 'years').day(start_date_day).add(date_diff, 'days');

		} else if (compare === 'previous-period') {

			// Find the closest date 1 year ago with the same weekday.
			compared_start_date = moment(start_date).subtract(date_diff + 1, 'days');
						
			// Add diff to start date in order to find the compared end date. 
			compared_end_date = moment(end_date).subtract(date_diff + 1, 'days');
			
		}

		// If start and end date are the first of last day of month, than compare dates should too. 
		if (moment(start_date).startOf('month').format('YYYY-MM-DD') === moment(start_date).format('YYYY-MM-DD') && moment(end_date).endOf('month').format('YYYY-MM-DD') === moment(end_date).format('YYYY-MM-DD') && compare === 'previous-period') {
			compared_start_date.startOf('month');
			compared_end_date.endOf('month');
		}

		// Update this component FilterData
		setFilterData(prevState => ({
			...prevState,
			filter_compared_start_date: compared_start_date,
			filter_compared_end_date: compared_end_date
		}));
	}, [
		filterData.filter_selected_compare_option,
		filterData.filter_start_date,
		filterData.filter_end_date
	]);

	/**
	 * toggle dropdown
	 */
	const toggleDropdownClass = (event) => {

		document.body.classList.toggle('filter-open');		
	}

	/**
	 * Handle department change
	 */
	const handleDepartmentChange = (option) => {

		 setFilterData({
		 	...filterData,
		 	filter_selected_department_option: option,
		 	filter_department_ids: [option.value],
		 });
	}

	/**
	 * Handle period change
	 */
	const handlePeriodChange = (option) => {
		
		let start_date = filterData.active_start_date;
		let end_date = filterData.active_end_date;

		// Presets
		if (option.value === 'today') {
			start_date = moment().toDate();
			end_date = moment().toDate();
		} else if (option.value === 'yesterday') {
			start_date = moment().subtract(1, 'days').toDate();
			end_date = moment().subtract(1, 'days').toDate();
		} else if (option.value === 'last-7-days') {
			start_date = moment().subtract(7, 'days').toDate();
			end_date = moment().subtract(1, 'days').toDate();

		} else if (option.value === 'last-week') {
			start_date = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
			end_date = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
		
		} else if (option.value === 'week-to-date') {
			start_date = moment().startOf('week').toDate();
			end_date = moment().toDate();
		} else if (option.value === 'last-month') {
			start_date = moment().subtract(1, 'months').startOf('month').toDate();
			end_date = moment().subtract(1, 'months').endOf('month').toDate();;
		} else if (option.value === 'month-to-date') {
			start_date = moment().startOf('month').toDate();
			end_date = end_date = moment().toDate();
		}

		setFilterData({
		 	...filterData,
		 	filter_selected_period_option: option,
		 	filter_start_date: start_date,
		 	filter_end_date: end_date,
		 });
	}

	/**
	 * Handle start date select
	 */
	const handleStartDateSelect = (date) => {

		setFilterData({
			...filterData,
			filter_start_date: date,
			active_start_date: date,
			filter_selected_period_option: { value: 'custom', label: 'Brugerdefineret' },
		});

	}

	/**
	 * Handle end date select
	 */
	const handleEndDateSelect = (date) => {
		setFilterData({
			...filterData,
			filter_end_date: date,
			active_end_date: date,
		});
	}	


	/**
	 * Handle period change
	 */
	const handleCompareChange = (option) => {

		setFilterData({
			...filterData,
			filter_selected_compare_option: option,
		});
	}

	/**
	 * Move date step backwards
	 */
	const changeDateBack = () => {	
		// Get current startdate
		const current_startdate = filterData.filter_start_date;
		let new_start_date = new Date(current_startdate);
		new_start_date.setDate(new_start_date.getDate()-1);
		
		// Get current enddate
		const current_enddate = filterData.filter_end_date;
		let new_end_date = new Date(current_enddate);
		new_end_date.setDate(new_end_date.getDate()-1);

		// Set date state
		setFilterData({
			...filterData,
			filter_selected_period_option: { value: 'custom', label: 'Brugerdefineret' },
			filter_start_date: new_start_date,
			filter_end_date: new_end_date
		});

	}
	/**
	 * Move date step forwards
	 */
	const changeDateForward = () => {
		
		// Get current startdate
		const current_startdate = filterData.filter_start_date;
		let new_start_date = new Date(current_startdate);
		new_start_date.setDate(new_start_date.getDate()+1);
		
		// Get current enddate
		const current_enddate = filterData.filter_end_date;
		let new_end_date = new Date(current_enddate);
		new_end_date.setDate(new_end_date.getDate()+1);

		// Set date state
		setFilterData({
			...filterData,
			filter_selected_period_option: { value: 'custom', label: 'Brugerdefineret' },
			filter_start_date: new_start_date,
			filter_end_date: new_end_date
		});
	
	}

	
	/**
	 * Handle sumbit
	 */
	const handleSubmit = (e) => {
		e.preventDefault();
		document.body.classList.remove('filter-open');
		
		// Update parent component filterData
		props.setFilterData(prevState => ({
			...prevState,
			active_department_ids: filterData.filter_department_ids,
			active_start_date: filterData.filter_start_date,
			active_end_date: filterData.filter_end_date,
			active_compared_start_date: filterData.filter_compared_start_date,
			active_compared_end_date: filterData.filter_compared_end_date,
		}))

		// Update this component FilterData
		setFilterData(prevState => ({
			...prevState,
			active_department_ids: filterData.filter_department_ids,
			active_start_date: filterData.filter_start_date,
			active_end_date: filterData.filter_end_date,
			active_compared_start_date: filterData.filter_compared_start_date,
			active_compared_end_date: filterData.filter_compared_end_date,
			active_selected_department_option: filterData.filter_selected_department_option,
		}))
	}

	/**
	 * Handle cancel
	 */
	const handleCancel = (e) => {
		e.preventDefault();
		document.body.classList.remove('filter-open');
		// Update this component FilterData
		setFilterData({
			...filterData,
			filter_selected_department_option: filterData.active_selected_department_option,
			filter_department_ids: filterData.active_department_ids,
			filter_selected_period_option: filterData.active_selected_period_option,
			filter_start_date: filterData.active_start_date,
			filter_end_date: filterData.active_end_date,
			filter_selected_compare_option: filterData.active_selected_compare_option,
			filter_compared_start_date: filterData.active_compared_start_date,
			filter_compared_end_date: filterData.active_compared_end_date,
		})
	}

	/**
	 * Render
	 */
	return (
		<div className="filters" key={props.departments} ref={ref}>
			<div className="filter-preview" onClick={(event) => toggleDropdownClass(event)}>
				<div className="filter-preview-line filter-preview-line--departments">
					{
						props.departments.length > 0 && (
							filterData.active_selected_department_option.label
						)	
						
					}
					<div className="three-dots"><span></span><span></span><span></span></div>
				</div>
				<div className="filter-preview-line filter-preview-line--period">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M456.2 69.7H413V27c0-13.8-11.2-25-25-25s-25 11.2-25 25v42.7H148V27c0-13.8-11.2-25-25-25S98 13.2 98 27v42.7H54.8C24.3 69.7-.5 94.5-.5 125v330.5c0 30.6 24.8 55.3 55.3 55.3h401.4c30.6 0 55.3-24.8 55.3-55.3V125c0-30.5-24.8-55.3-55.3-55.3zm0 391.2H54.8c-2.9 0-5.3-2.4-5.3-5.3V190h412v265.5c0 3-2.4 5.4-5.3 5.4z"/></svg>
					{moment(filterData.active_start_date).format('DD/MM/YYYY')} - {moment(filterData.active_end_date).format('DD/MM/YYYY')}
				</div>
				{
					props.filterData.active_compared_start_date && (
						<div className="filter-preview-line filter-preview-line--compared">Sammenlignet med {moment(filterData.active_compared_start_date).format('DD/MM/YYYY')} - {moment(filterData.active_compared_end_date).format('DD/MM/YYYY')}</div>
					)
				}
				
			</div>
			<form action="" onSubmit={e => handleSubmit(e)}>
				<div className="filters-dropdown">
					<div className="filters-dropdown-body">
						
						{
							props.departments.length > 0 && (
								<div className="filter-field">
									<label htmlFor="" className="filter-field-label">Afdeling</label>
									<Select
										className="department-selector"
										isMulti={false}
									    options={selectGroupedDepartments}
									    formatGroupLabel={formatGroupLabel}
									    isOptionDisabled={(option) => option.disabled === true}
									    onChange={handleDepartmentChange}
									    value={filterData.filter_selected_department_option}
									    placeholder="Vælg afdelinger"
									  />
								</div>
							)
						}
						

						<div className="filter-field filter-field--date1">
							<label htmlFor="" className="filter-field-label">Periode</label>
							<Select
								className="date-selector"
								isMulti={false}
							    options={selectPeriod}
							    formatGroupLabel={formatGroupLabel}
							    isOptionDisabled={(option) => option.disabled === true}
							    onChange={handlePeriodChange}
							    value={filterData.filter_selected_period_option}
							    placeholder="Vælg periode"
							  />
							  <div className="date-picker-container">
							  	<div className="date-picker-container-arrow" onClick={() => changeDateBack()}><div className="date-picker-container-arrow-inner"><div className="arrow left"></div></div></div>
							  	<DatePickerSelect date={filterData.filter_start_date} changeHandler={(date) => handleStartDateSelect(date)} />
							  	<DatePickerSelect date={filterData.filter_end_date} changeHandler={(date) => handleEndDateSelect(date)} />
							  	<div className="date-picker-container-arrow" onClick={() => changeDateForward()}><div className="date-picker-container-arrow-inner"><div className="arrow right"></div></div></div>
							  </div>
						</div>

						{
							props.filterData.active_compared_start_date && (
								<div className="filter-field filter-field--date2">
									<label htmlFor="" className="filter-field-label">Sammenlign med</label>
									<Select
										className="date-selector"
										isMulti={false}
									    options={selectComparedPeriod}
									    formatGroupLabel={formatGroupLabel}
									    isOptionDisabled={(option) => option.disabled === true}
									    onChange={handleCompareChange}
									    value={filterData.filter_selected_compare_option}
									    placeholder="Vælg sammenligning"
									  />
									  <div className="filter-field-subtitle">{moment(filterData.filter_compared_start_date).format('DD/MM/YYYY')} - {moment(filterData.filter_compared_end_date).format('DD/MM/YYYY')}</div>
								</div>
							)
						}

					</div>
					<div className="filters-dropdown-footer">
						<button className="button" onClick={(e) => handleCancel(e)}>Cancel</button>
						<button type="submit" className="button">Apply</button>
					</div>
				</div>
			</form>
		</div>		
	); 
};

export default Filters;
