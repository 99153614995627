import React from "react";
import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

export const WallOfFame = (props) => {

	return (
		<div className="widget-v2">
		    <div className="widget-v2-header">
		    	<h2 className="widget-v2-title">Wall of fame</h2>
		    </div>
			
			<div className="widget-v2-body">
				
				<MaterialTable
				  columns={[
				    { title: 'Dato', field: 'date', type: 'string' },
				    { title: 'Konkurrence', field: 'competition', type: 'string' },
				    { title: 'Vinder', field: 'department' },
				  ]}
				  data={[
				  	{
				  		date: '21/06/2023 — 31/08/2023',
				  		competition: 'Jordbær',
				  		department: 'Torvehallerne',
				  	},
				  	{
				  		date: '21/06/2023 — 31/08/2023',
				  		competition: 'Rødgrødkompot glas',
				  		department: 'Vesterbro',
				  	},
				  	{
				  		date: '16/11/2022 — 23/12/2022',
				  		competition: 'Kogebog',
				  		department: 'Graven',
				  	},
				  	{
				  		date: '24/11/2021 — 24/12/2021',
				  		competition: 'Kogebog',
				  		department: 'Lyngby',
				  	},
				  	{
				  		date: '01/07/2021 — 15/08/2021',
				  		competition: 'Jordbær!',
				  		department: 'Graven',
				  	},
				  	{
				  		date: '31/05/2021 — 21/06/2021',
				  		competition: 'Granolzz!',
				  		department: 'Nordre Frihavnsgade',
				  	},
				  	{
				  		date: '03/03/2021 — 18/05/2021',
				  		competition: 'GRØD BLENDS!',
				  		department: 'Nordre Frihavnsgade',
				  	},
				  	{
				  		date: '18/11/2020 — 24/12/2020',
				  		competition: 'GRØD-biblen!',
				  		department: 'Strandvejen',
				  	},
				  	{
				  		date: '21/10/2020 — 18/11/2020',
				  		competition: 'Trøffelolie og Sriracha!',
				  		department: 'Graven',
				  	},
				  	{
				  		date: '13/08/2020 — 29/09/2020',
				  		competition: 'Combos!',
				  		department: 'Graven',
				  	},
				  	{
				  		date: '27/05/2020 — 13/08/2020',
				  		competition: 'Jordbær!',
				  		department: 'Graven',
				  	},
				  	{
				  		date: '20/11/2019 — 24/12/2019',
				  		competition: 'KogebogsRolf',
				  		department: 'Graven',
				  	},
				  	{
				  		date: '20/10/2019 — 20/11/2019',
				  		competition: 'TrøffelROLF',
				  		department: 'Falkoner Allé',
				  	},
				  	{
				  		date: '09/07/2019 — 11/08/2019',
				  		competition: 'JordbærROLF',
				  		department: 'Falkoner Allé',
				  	}
				  ]}
				  title="Survival of the phattest — Wall of flame 🔥"
				  components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
				  options={{
				  		search: false,
				  		pagination: false,
				  		pageSize: 15,
				  		pageSizeOptions: [5, 10, 20],
				  		toolbar: false,
				  }}
				/>
					
			</div>						        

			{/*
		    <div className="widget-v2-footer">
		    	footer
		    </div>
		    */}

		</div>
	); 
};

export default WallOfFame;
