import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../Spinner";

// Charts
import SimpleBars from '../charts/SimpleBars';

export const SalesByPaymentType = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	const [paymentTypeData, setPaymentTypeData] = useState([]);

	// Source Filter
	const [sourceFilter, setSourceFilter] = useState('All');
	const [sourceOptions, setSourceOptions] = useState(['All']);

	// First entry is always the master
	const [barData, setBarData] = useState([]);

	const colors = ['#2771D5', '#181B5F', 'red', 'green', 'blue', 'orange'];

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get styles data
				const salesByPaymentTypeResponse = await axios.get(`${api_url}/aggregation/revenue-by-payment-type`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
					    start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
					    end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
					}
				});

				setPaymentTypeData(salesByPaymentTypeResponse.data.data);

				setWidgetState('done');
				
			} catch (e) {
        		console.error('error', e);

        		setPaymentTypeData([]);
        		
        		setWidgetState('error');
      		}

		})();

	}, [
		getAccessTokenSilently, 
		props.start_date,
		props.end_date,
	]);


	useEffect(() => {

		// Setup options
		// const sourceOptions = paymentTypeData.reduce( (previousValue, currentValue) => {
		// 	if(!previousValue.includes(currentValue.source)){
		// 	 	previousValue.push(currentValue.source);
		// 	}
		// 	return previousValue;
		// }, ['All']);
		const sourceOptions = ['OnlinePOS'];

		let data = paymentTypeData;

		const total = paymentTypeData.reduce( (previousValue, currentValue) => {
			return previousValue + (currentValue.revenue_excl_vat / 100);
		}, 0)

		console.log('total', total);

		// if(sourceFilter !== 'All') {
		// 	data = data.filter(profiel_event => profiel_event.source === sourceFilter );				
		// }


		const labelMap = [
			{
				key: 'Card',
				value: 'Card'
			},
			{
				key: 'Cash',
				value: 'Cash'
			},
			{
				key: 'np_cc',
				value: 'HeapsGo Beacon'
			},
			{
				key: 'm',
				value: 'MobilePay'
			},
			{
				key: 'GK',
				value: 'Gavekort'
			},
		]

		const bars = data.map(entry => {

			

			let labelIndex = labelMap.findIndex(label => label.key === entry.payment_type);
			
			console.log('sdfaaa', labelIndex);

			let label = (labelIndex !== -1) ? labelMap[labelIndex].value : entry.payment_type;



			const obj = {
				
					label: label,
					tag: 'OnlinePOS',
					value: entry.revenue_excl_vat / 100,
					total:  total,
					data: [
						{
							data_name: entry.payment_type,
							color: '#2771D5',
							value: entry.revenue_excl_vat / 100,
						},
					]
				};
			

			return obj;
		})

		bars.sort((a, b) => (a.value > b.value) ? -1 : 1)

		setSourceOptions(sourceOptions);

		setBarData(bars);

	}, [
		paymentTypeData,
		sourceFilter
	]);


	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setSourceFilter(new_mode);
	}

	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Revenue By Payment Type (OnlinePOS Only)</h2>	
		        	</div>

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (
							<div className="simple-bars-scroll-container simple-bars-scroll-container--small simple-bars-activity-wrapper">
								<SimpleBars
									data={barData}
									showLegends={false}
								 />
							 </div>
						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									{

										sourceOptions.map((source, i) => {

											let activeClass = '';
											if (sourceFilter === source) {
												activeClass = 'widget-footer-filter--active';
											}

											return (
												<li key={i} className={`widget-v3-footer-filter ${activeClass}`} onClick={() => changeSourceFilter(source)}>{source}</li>
											)
										})
									}

					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default SalesByPaymentType;