import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Barometer from '../../charts/Barometer';

export const NewsletterTargets = (props) => {


	// Loading state
	const [widgetState, setWidgetState] = useState({
		data: [
			{
				title: 'Subscribers by 1. july',
				target: 5000,
				value: 5567,
				type: 'number',
				reverse: false,
			}
		]
	});


	/**
	 * update
	 */
	useEffect(() => {

	}, []);



	return (
		<div className="widget-v2">
	        <div className="widget-v2-header">
	            <h2 className="widget-v2-title">Targets</h2>
	        </div>
			
			<div className="widget-v2-body">

				{
					widgetState.data.map((entry, i) => {
						return (
							<div key={i} className="target-indicator-block">
								<Barometer title={entry.title} target={entry.target} value={entry.value} max={25000} type={entry.type} reverse={entry.reverse} />
							</div>
						)
					})		
				}

			</div>
			<br/>
		</div>

	);
}

export default NewsletterTargets;
