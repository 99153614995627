import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import Select from 'react-select';
import { ResponsiveLine } from '@nivo/line';


export const TimelineWebsiteSessions = (props) => {

	// Loading state
	const [widgetState, setWidgetState] = useState({
		source_filter: 'groed',
		error: false,
		data: {
			groed: {
				target: 0,
				max: 25000,
				data: [
					{
						"id": "Omsætning",
						"color": "#29D0A9",
						"data": [
							{ x: 'Uge 21', y: 4685},
							{ x: 'Uge 22', y: 6416},
							{ x: 'Uge 23', y: 4115},
							{ x: 'Uge 24', y: 3485},
							{ x: 'Uge 25', y: 3276},
							{ x: 'Uge 26', y: 4568},
							{ x: 'Uge 27', y: 3272},
							{ x: 'Uge 28', y: 6190},
							{ x: 'Uge 29', y: 4844},
							{ x: 'Uge 30', y: 1986},
							{ x: 'Uge 31', y: 4946},
							{ x: 'Uge 32', y: 4142},
						]
					},
					{
						"id": "Page Views",
						"color": "hsl(339, 70%, 50%)",
						"data": [
							{ x: 'Uge 21', y: 20610},
							{ x: 'Uge 22', y: 17649},
							{ x: 'Uge 23', y: 17558},
							{ x: 'Uge 24', y: 20806},
							{ x: 'Uge 25', y: 17131},
							{ x: 'Uge 26', y: 19552},
							{ x: 'Uge 27', y: 19753},
							{ x: 'Uge 28', y: 22264},
							{ x: 'Uge 29', y: 23647},
							{ x: 'Uge 30', y: 24325},
							{ x: 'Uge 31', y: 25900},
							{ x: 'Uge 32', y: 26502},
						]
					},
					{
						"id": "Sessions",
						"color": "hsl(223, 70%, 54%)",
						"data": [
							{ x: 'Uge 21', y: 7331},
							{ x: 'Uge 22', y: 6334},
							{ x: 'Uge 23', y: 6113},
							{ x: 'Uge 24', y: 7421},
							{ x: 'Uge 25', y: 6392},
							{ x: 'Uge 26', y: 6887},
							{ x: 'Uge 27', y: 6912},
							{ x: 'Uge 28', y: 7387},
							{ x: 'Uge 29', y: 8321},
							{ x: 'Uge 30', y: 8473},
							{ x: 'Uge 31', y: 8808},
							{ x: 'Uge 32', y: 9159},
						]
					},
				],
			},	
		}
	});

	const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
	  <g>
	    <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
	    <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
	  </g>
	);

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setWidgetState(prevState => ({
			...prevState,
			source_filter: new_mode
		}));
	}

	return (
		<div className="widget-v2">
	        <div className="widget-v2-header">
	        				      
      			<Select 
              		className="timeline-selector" 
              		options={props.select.options} 
              		value={props.select.value}
              		onChange={props.select.onChange}
              	/>

	        </div>
			
			<div className="widget-v2-body">
					<div className="responsive-line-container">
						
						<ResponsiveLine
							data={widgetState.data[widgetState.source_filter].data}
							colors={d => d.color}
							margin={{ top: 50, right: 50, bottom: 70, left: 90 }}
							xScale={{ type: "point"}}
							yScale={{ type: "linear", stacked: false, min: 0, max: widgetState.data[widgetState.source_filter].max }}
							curve="monotoneX"
							axisTop={null}
							axisRight={null}
							axisBottom={{
								orient: "bottom",
								tickSize: 5,
								tickPadding: 5,
								tickRotation: -40,
								legend: "",
								legendOffset: 36,
								legendPosition: "middle"
							}}
							axisLeft={{
								orient: "left",
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: "",
								legendOffset: -40,
								legendPosition: "middle",
								format: value => `${Number(value).toLocaleString('da-DK')}`,
							}}



							yFormat={value => {
								if (props.salaryPercentageMode) {
									return `${value}%`;
								} else {
									return `${Number(value).toLocaleString('da-DK')}`;
								}
								
							}}

			      	    	enablePointLabel={true}
			      	    	pointSize={6}
			      	    	pointBorderWidth={1}
			      	    	pointBorderColor={{
								from: 'color',
								modifiers: [['darker', 0.3]],
							}}
							pointLabelYOffset={-10}
							enableArea={true}			        
							areaOpacity={0.07}
							useMesh={false}
							enableSlices='x'		
							animate={true}
							motionStiffness={300}
							motionDamping={30}
							markers={[
					            {
					                // axis: 'y',
					                // value: widgetState.data[widgetState.source_filter].target,
					                // lineStyle: { stroke: 'hsl(223, 70%, 54%)', strokeWidth: 1, strokeDasharray: "4 4", },
					                // legend: 'Reach target',
					            },
					        ]}
					        sliceTooltip={({ slice }) => {
			                    console.log(slice);
			                    return (
			                        <div
			                            style={{
			                                background: 'white',
			                                padding: '9px 12px',
			                                border: '1px solid #ccc',
			                            }}
			                        >
			                            {slice.points.map((point, i) => (
			                                <div
			                                    key={i}
			                                    style={{
			                                        color: point.serieColor,
			                                        padding: '3px 0',
			                                    }}
			                                >
			                                    <strong>{point.serieId}</strong> {point.data.yFormatted} 
			                                    {
			                                    	point.data.posts && (
			                                    		<div>
			                                    		(Posts: {point.data.posts}, Stories: {point.data.stories})
			                                    		</div>
			                                    	)	
			                                    }
			                                    
			                                </div>
			                            ))}
			                        </div>
			                    )
			                }}
						/>

					</div>	
					<div className="widget-v2-body-legends">

        				<div className="widget-v2-body-legend" style={{color: 'hsl(223, 70%, 54%)'}}>
			            	<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(223, 70%, 54%)'}}></div>
			            	<div className="widget-v2-body-legend-title">Sessions</div>
			            </div>

			            <div className="widget-v2-body-legend" style={{color: 'hsl(339, 70%, 50%)'}}>
			            	<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(339, 70%, 50%)'}}></div>
			            	<div className="widget-v2-body-legend-title">Page views</div>
			            </div>

			            <div className="widget-v2-body-legend" style={{color: '#29D0A9'}}>
			            	<div className="widget-v2-body-legend-dot" style={{backgroundColor: '#29D0A9'}}></div>
			            	<div className="widget-v2-body-legend-title">Omsætning</div>
			            </div>

		            </div>

			</div>						        


	        <div className="widget-v2-footer">
	        	
	        	<div className="widget-timeline-v2-footer-settings">
		        		
	        	</div>
	        </div>

	    </div>	
	); 
};

export default TimelineWebsiteSessions;