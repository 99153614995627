import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

export const ProfileConsent = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done


	// Loading state
	const [consentData, setConsentData] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');

			const profile_id = props.match.params.profile;

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get styles data
				const profileDataResponse = await axios.get(`${api_url}/profiles/${profile_id}`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
				});

				if(profileDataResponse.data.data.profile === null) {
					setWidgetState('error');	
				} else {
					
					setConsentData(profileDataResponse.data.data.profile.consent);

					setWidgetState('done');
				}				
				
			} catch (e) {
	    		setConsentData([]);
	    		console.error('error', e);
	    		setWidgetState('error');
	  		}

		})();		

	}, [
		getAccessTokenSilently, 
	]);


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}


	// Processing consent
	let processing_consent = {
		type: 'Personal Data Processing Consent',
		status: false, method: '', date: '', privacy_policy_version: '', consent_statement_version: '',
	};
	
	const processing_consent_lookup = consentData.findIndex(consent_entry => consent_entry.type === 'Personal Data Processing Consent');
	if(processing_consent_lookup !== -1) {
		processing_consent = {...processing_consent, ...consentData[processing_consent_lookup]};
		processing_consent.status = true;
	}


	// Marketing consent - email
	let email_marketing_consent = {
		type: 'Email Marketing Consent',
		status: false, method: '', date: '', privacy_policy_version: '', consent_statement_version: '',
	};
	
	const email_marketing_consent_lookup = consentData.findIndex(consent_entry => consent_entry.type === 'Email Marketing Consent');
	if(email_marketing_consent_lookup !== -1) {
		email_marketing_consent = {...email_marketing_consent, ...consentData[email_marketing_consent_lookup]};
		email_marketing_consent.status = true;
	}

	// Marketing consent - push
	let push_marketing_consent = {
		type: 'Push Marketing Consent',
		status: false, method: '', date: '', privacy_policy_version: '', consent_statement_version: '',
	};
	
	const push_marketing_consent_lookup = consentData.findIndex(consent_entry => consent_entry.type === 'Push Marketing Consent');
	if(push_marketing_consent_lookup !== -1) {
		push_marketing_consent = {...push_marketing_consent, ...consentData[push_marketing_consent_lookup]};
		push_marketing_consent.status = true;
	}

	// Marketing consent - advertising
	let advertising_marketing_consent = {
		type: 'Advertising Marketing Consent',
		status: false, method: '', date: '', privacy_policy_version: '', consent_statement_version: '',
	};
	
	const advertising_marketing_consent_lookup = consentData.findIndex(consent_entry => consent_entry.type === 'Advertising Marketing Consent');
	if(advertising_marketing_consent_lookup !== -1) {
		advertising_marketing_consent = {...advertising_marketing_consent, ...consentData[advertising_marketing_consent_lookup]};
		advertising_marketing_consent.status = true;
	}

	const consent_array = [
		processing_consent,
		email_marketing_consent,
		push_marketing_consent,
		advertising_marketing_consent			
	]

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">

							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >    
						      <path d="M68.88 103.17c69.53 4.8 137.67-21.1 186.46-70.87a195.545 195.545 0 0 0 86.22 55.44c32.51 8.69 66.14 13.28 101.58 20.1 5.53 40.88 4.23 82.39-3.85 122.84-17.27 103.73-80.71 194-172.45 245.4a18.475 18.475 0 0 1-21.78.33c-113.63-66.69-169.8-167.87-178.94-297.2-1.3-18.28-.12-36.78.31-55.18.16-6.52 1.46-13.04 2.45-20.86m16.68 19.7c0 19.23-1.11 37.56.19 55.73 3.19 112.25 62.68 215.34 158.27 274.26 7.06 5.52 17 5.41 23.93-.26 87.25-53.48 144.53-144.64 154.85-246.46 3.59-27.26 3-55.07 4.37-83.39-62.9.43-123.85-21.86-171.62-62.79a260.335 260.335 0 0 1-170.09 62.88"/>
						      <path d="M407.21 139.74c2.36 123.52-37.51 224.16-143.46 292.71-5.4 3.88-12.8 3.4-17.65-1.16-82.25-51.71-134.47-139.96-140.2-236.94-1.72-17.65-.24-35.6-.24-54.57a252.03 252.03 0 0 0 149.82-55.7 253.934 253.934 0 0 0 151.73 55.66"/>
							</svg>
						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Consent Status</h2>	
		        		<div className="widget-v3-subtitle">GDPR Documentation</div>
		        	</div>
		            
		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="profile-consent-list-wrapper">

								<ul className="profile-consent-list">
								
									{

										consent_array.map((consent_entry, i) => {
											if (consent_entry.status) {
												return (
													<li key={i} className="profile-consent-list-item">
														<div className="profile-consent-list-item-icon profile-consent-list-item-icon--checked">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
															  <path d="M255.84 512.45C114.3 512.45-.44 397.72-.45 256.18c0-67.98 27-133.17 75.07-181.23 100.15-100.02 262.42-99.92 362.44.23s99.91 262.42-.24 362.44a256.25 256.25 0 0 1-81.22 54.68 254.756 254.756 0 0 1-99.76 20.15zm-84.41-290.31-32.96 32.96 58.05 58.05 32.96 32.93 139.99-139.99-32.93-32.96-107.06 107.06-58.05-58.05z" />
															</svg>
														</div>
														<div className="profile-consent-list-item-label">{consent_entry.type}</div>
														<div className="profile-consent-list-item-value">
															Consent given via <span>{consent_entry.method}</span> on <span>{moment(consent_entry.date).format('MMMM DD, YYYY')}</span><br/>
															Privacy Policy Version: <span>{consent_entry.privacy_policy_version}</span><br/>
															Consent statement Version: <span>{consent_entry.consent_statement_version}</span>
														</div>
													</li>	
												)
											} else {
												return (
													<li key={i} className="profile-consent-list-item">
														<div className="profile-consent-list-item-icon profile-consent-list-item-icon--unchecked">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
				    											<path d="M437.06 75.18C337.04-24.97 174.77-25.07 74.62 74.94A256.302 256.302 0 0 0-.45 256.18c.01 141.54 114.75 256.28 256.29 256.27 34.27.07 68.2-6.79 99.76-20.15a256.362 256.362 0 0 0 81.22-54.68c100.15-100.02 100.25-262.29.24-362.44zm-66.03 253.84-42.71 42.75-69.43-69.43-69.43 69.43-42.75-42.71 69.45-69.45-69.45-69.45 42.75-42.71 69.43 69.43 69.43-69.43 42.71 42.76-69.41 69.41 69.41 69.4z" />
				  											</svg>
														</div>
														<div className="profile-consent-list-item-label">{consent_entry.type}</div>
														<div className="profile-consent-list-item-value">
															No consent recorded
														</div>
													</li>	
												)
												
											}
											
										})
									}

									
								</ul>

							</div>

						)
					}
				</div>

			      {/*
			        <div className="widget-v3-footer">
			        	
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							) 

						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter widget-footer-filter--active">All channels</li>
									<li className="widget-v3-footer-filter">OnlinePOS</li>
									<li className="widget-v3-footer-filter">HeapsGO</li>
					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        */}

		    </div>
		</div>	
	); 
};

export default ProfileConsent;

