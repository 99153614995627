import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";
import Redacted from "../../Redacted";

export const OrdersTableProfile = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	const order = props.order;

	if(!order._id) {
		return (
			<div className="order-details"></div>
		)
	}

	const order_subtotal = (order.subtotal.amount * 1.25) / 100 || 0;
	const order_total_discount = ((order.subtotal.amount - order.total.amount) * 1.25) / 100 || 0;
	const order_total = (order.total.amount * 1.25) / 100 || 0;

	return (
		<div className="order-details">

			<div className="order-details-field">
				<div className="order-details-field-label">Order ID</div>
				<div className="order-details-field-value">{order._id}</div>
			</div>

			{
				order.profile && order.profile.first_name && order.profile.last_name && (
					<div className="order-details-field">
						<div className="order-details-field-label">Customer</div>
						<Link to={`/marketing/profiles/${order.profile._id}`}>
			        		
			        		<div className="order-details-field-value">
				        		{
			        				order.profile.first_name === 'REDACTED' ? (
			        					<Redacted text="Redacted Name"/>
			        				) : (
			        					<div>{order.profile.first_name} {order.profile.last_name}</div>
			        				)
				        		}
			        		</div>
			        	
			        	</Link>						
					</div>
				)
			}
			
			<div className="order-details-field">
				<div className="order-details-field-label">Department</div>
				<div className="order-details-field-value">{order.department.name}</div>
			</div>

			<div className="order-details-field">
				<div className="order-details-field-label">Source</div>
				<div className="order-details-field-value">{order.source}</div>
			</div>

			<div className="order-details-field">
				<div className="order-details-field-label">Order Status</div>
				<div className="order-details-field-value">{order.state}</div>
			</div>

			<div className="order-details-field">
				<div className="order-details-field-label">Delivery Type</div>
				<div className="order-details-field-value">{order.delivery_type}</div>
			</div>

			<div className="order-details-field">
				<div className="order-details-field-label">Order Type</div>
				<div className="order-details-field-value">{order.order_type}</div>
			</div>


			<div className="order-details-field">
				<div className="order-details-field-label">Ordered At</div>
				<div className="order-details-field-value">{moment(order.date).format('MM.DD.YYYY HH:mm')}</div>
			</div>

			<div className="order-details-table">
				<div className="order-details-field-label">Order</div>				

				{
					order.line_items.length > 0 ? (
						<div>
						{
							order.line_items.map((line_item, i) => {

								const line_total = (line_item.subtotal.amount * 1.25) / 100 || 0;

								return (

									<div className="order-details-table-row">
										<div className="order-details-table-line-item">
											<div className="order-details-table-line-item-quanity">{line_item.quantity}<span className="order-details-line-x">×</span></div>
											<div className="order-details-table-line-item-name">{line_item.name}</div>
											<div className="order-details-table-line-item-total">
												{line_total.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}
											</div>

										</div>

										{
											line_item.options.map((option, i) => {
												const option_total = (option.subtotal.amount * 1.25) / 100 || 0;
												return (
													<div className="order-details-table-option">
														<div className="order-details-table-option-quanity">{option.quantity}<span className="order-details-line-x">×</span></div>
														<div className="order-details-table-option-name">{option.name}</div>
														<div className="order-details-table-option-total">
															({option_total.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})})
														</div>
													</div>	
												)
											})
										}


									</div>
								)
							})
						}
						</div>
					) : (
						<div className="order-details-table-no-lines">No lines on order</div>
					)
				}


				<div className="order-details-table-subtotal-row">
					<div className="order-details-table-subtotal-row-label">Subtotal</div>
					<div className="order-details-table-subtotal-row-value">
						{order_subtotal.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}
					</div>
				</div>
				
				{
					order_total_discount !== 0 && (
						<div className="order-details-table-discount-row">
							<div className="order-details-table-discount-row-label">Discount</div>
							<div className="order-details-table-discount-row-value">
								-{order_total_discount.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}
							</div>

						</div>
					)
				}
				

				<div className="order-details-table-total-row">
					<div className="order-details-table-total-row-label">Total</div>
					<div className="order-details-table-total-row-value">
						{order_total.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}
					</div>

				</div>



			</div>

		   
		</div>	
	); 
};

export default OrdersTableProfile;