import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

/**
 * Get profiles Customer Count and Spent
 */
const getProfilesCustomerCounts = async (access_token) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-customer-counts/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`}
	});

	return axiosPromise;		
}

/**
 * Get profiles Count
 */
const getProfilesCount = async (access_token, filter) => {


	let params = {};

	if(filter === 'Email Marketing Consent')  {
		params = {
			filter: 'Email Marketing Consent'
		}
	}

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-count/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: params,

	});

	return axiosPromise;		
}

/**
 * Get profiles Count
 */
const getActiveProfilesCount = async (access_token, start_date, end_date) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profiles-active-count/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: {
            start_date: moment(start_date).format('YYYY-MM-DD 00:00'),
            end_date: moment(end_date).format('YYYY-MM-DD 23:59'),
		}
	});

	return axiosPromise;		
}


export const ProfilesKPIsSidebar = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Widget data state
	const [widgetDataState, setWidgetDataState] = useState({
		profile_count: 0,
		accepted_marketing_count: 0,
		customer_count: 0,
		customer_lifetime_value: 0,
		active_profiles_count: 0,
	});

	/**
	 * Get data from DB
	 */
	useEffect(() => {
	

		const loadAllData = async () => {

			setWidgetState('loading');

			// Get access token
			const access_token = await getAccessTokenSilently();
			
			const profilesCount = getProfilesCount(access_token);
			const profilesCustomerCounts = getProfilesCustomerCounts(access_token);
			
			const start_date = moment().subtract(30, 'days');
			const end_date = moment();
			const activeProfilesCount = getActiveProfilesCount(access_token, start_date, end_date);

			const profilesMarketingCount = getProfilesCount(access_token, 'Email Marketing Consent');

			Promise.all([profilesCount, profilesCustomerCounts, activeProfilesCount, profilesMarketingCount])
				.then( values => {

					const profilesCount = values[0].data.data;
					const profilesCustomerCountsData = values[1].data.data;
					const activeProfilesCountData = values[2].data.data;
					const profilesMarketingCount = values[3].data.data;
					

					const clv = (profilesCustomerCountsData.total_spent / profilesCustomerCountsData.customer_count) / 100 || 0;

					setWidgetDataState(prevState => ({
						...prevState,
						profile_count: profilesCount.count,
						accepted_marketing_count: profilesMarketingCount.count,
						customer_count: profilesCustomerCountsData.customer_count,
						customer_lifetime_value: clv,
						active_profiles_count: activeProfilesCountData.count,
					}));

					setWidgetState('done');
				})
				.catch(error => {
					setWidgetDataState(prevState => ({ 
						...prevState,
						profile_count: 0,
						accepted_marketing_count: 0,
						customer_count: 0,
						customer_lifetime_value: 0,
						active_profiles_count: 0,
					}));
					
					setWidgetState('error');
					
					console.log(error);
				});

		}
		loadAllData();

	}, [
		getAccessTokenSilently, 
	]);


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	const customer_percentage = ( widgetDataState.customer_count / widgetDataState.profile_count) * 100 || 0;
	const active_percentage = ( widgetDataState.active_profiles_count / widgetDataState.profile_count) * 100 || 0;
	const marketing_percentage = ( widgetDataState.accepted_marketing_count / widgetDataState.profile_count) * 100 || 0;


	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
					<div className="widget-v3-header-icon-container">
						<div className="widget-v3-header-icon">
							{/*
							!!!Single profile icon - for use somewhere else!!!
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
    							<path d="M474.93 422.7c-.43-4.39-1.18-8.74-2-11.49-19.95-92.52-79.61-163.08-154.19-188.91 33.12-20.34 55.21-56.48 55.21-97.59 0-63.44-52.55-115.04-117.14-115.04-64.6 0-117.15 51.6-117.15 115.04 0 40.77 21.72 76.66 54.4 97.09-74.8 25.37-134.67 95.95-154.35 187.93-10.61 38.83 12.33 79.07 51.23 89.75 4.19 1.15 8.45 1.9 15.05 2.39l300.34.13c.72 0 1.44-.03 2.19-.1 40.11-3.47 69.91-38.96 66.41-79.2zM189.95 124.71c0-35.71 29.99-64.75 66.86-64.75 36.85 0 66.84 29.04 66.84 64.75 0 35.69-29.99 64.73-66.84 64.73-36.86 0-66.86-29.04-66.86-64.73zm215.1 327-296.78-.03c-1.42-.13-2.83-.36-4.11-.72-12.1-3.31-19.24-15.85-15.6-29.3 20.15-94.07 88.81-159.77 166.97-159.77 78.76 0 147.95 65.76 168.61 161.47.36 1.28.59 2.62.69 3.83 1.05 12.17-7.76 23.01-19.78 24.52z" />
  							</svg>
							*/}
  							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
  							   <path d="M254.74 235.73c36.81 0 66.74-29.37 66.74-65.45s-29.93-65.44-66.74-65.44c-36.81 0-66.74 29.36-66.74 65.44s29.93 65.45 66.74 65.45zm0-109.16c24.81 0 45 19.6 45 43.71s-20.19 43.72-45 43.72-45-19.61-45-43.72 20.18-43.71 45-43.71z" />
  							   <path d="M493.09 342.67c-11.66-28.36-36.48-48.87-66-55.51 24.57-5.09 43.08-26.61 43.08-52.33 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 23.69 15.71 43.82 37.41 50.82a84.468 84.468 0 0 0-39.84 16.22c-24.34-38.38-62.91-62.37-105.31-62.37-40.98 0-78.4 22.75-102.65 59.2-11.48-7.31-24.59-11.87-38.22-13.28 21.33-7.2 36.71-27.15 36.71-50.59 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 26 18.91 47.71 43.89 52.48-29.23 6.72-54.18 27.1-66.24 55.31-4.48 10.72-4.52 22.54-.11 33.28s12.76 19.11 23.5 23.59c5.35 2.22 10.97 3.34 16.43 3.34.51 0 1.04-.01 1.55-.03h80.62c1.65 0 3.2-.38 4.6-1.03 2.93 1.7 6.08 3.03 9.41 3.96 2.38.66 4.82 1.1 8.19 1.35l182.34.07c.32 0 .64-.01.93-.04 6.89-.6 13.22-2.92 18.61-6.5 1.82 1.37 4.07 2.19 6.53 2.19h81.36c5.71 0 11.48-1.15 16.73-3.34 22.09-9.21 32.59-34.72 23.4-56.79zM383.43 234.84c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73c-17.91 0-32.5-14.23-32.5-31.73zm-320.37 0c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73-32.5-14.23-32.5-31.73zm-3.12 146.25c-3.16.19-6.28-.44-9.23-1.66-5.35-2.24-9.53-6.42-11.74-11.79-2.21-5.37-2.19-11.28.02-16.55 11.14-26.1 36.7-43.53 64.81-44.42 12.77.08 25.85 3.81 36.72 10.94-5.81 12.01-10.34 25.07-13.33 38.97-2.28 8.18-1.89 16.68 1 24.49l-68.25.02zm285.03 4.34-180.89-.03c-1.1-.1-2.19-.29-3.27-.59-4.69-1.29-8.6-4.34-10.97-8.57-2.4-4.23-3.01-9.15-1.57-14.46 12.78-59.2 56.23-100.54 105.69-100.54 49.9 0 93.67 41.37 106.6 101.28.3 1.04.51 2.11.59 3.22.84 9.87-6.37 18.62-16.18 19.69zm116.32-6.02c-2.74 1.14-5.79 1.58-8.87 1.67h-71.93a39.601 39.601 0 0 0 2.31-17.18c-.21-2.46-.64-4.91-1.15-6.63-2.75-12.77-6.78-24.82-11.9-36 11.28-9.36 25.32-14.53 39.68-14.61 27.96 1.02 52.9 18.39 63.55 44.33 4.62 11.05-.63 23.81-11.69 28.42z" />
  							 </svg>
						</div>
					</div>

		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Profile KPIs</h2>	
		        		<div className="widget-v3-subtitle">The important profile metrics</div>
		        	</div>
		            
		            {/*
		        	<div className="widget-v3-header-actions">
		        		<div className="widget-v3-action">
		        			Action 1
		        		</div>

		        		<div className="widget-v3-action">
		        			Action 2
		        		</div>
		        	</div>
		        	*/}

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="widget-kpis">
								
								<div className="widget-kpis-main-stat">
									<div className="widget-kpis-main-stat-label">Total Profiles</div>
									<div className="widget-kpis-main-stat-value">{widgetDataState.profile_count.toLocaleString("da-DK")}</div>
								</div>

								<div className="widget-kpis-stats">

									<div className="widget-kpis-stat">
										
										<div className="kpi-card">
											<div className="kpi-card-icon">	
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
												   <path d="M254.74 235.73c36.81 0 66.74-29.37 66.74-65.45s-29.93-65.44-66.74-65.44c-36.81 0-66.74 29.36-66.74 65.44s29.93 65.45 66.74 65.45zm0-109.16c24.81 0 45 19.6 45 43.71s-20.19 43.72-45 43.72-45-19.61-45-43.72 20.18-43.71 45-43.71z" />
												   <path d="M493.09 342.67c-11.66-28.36-36.48-48.87-66-55.51 24.57-5.09 43.08-26.61 43.08-52.33 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 23.69 15.71 43.82 37.41 50.82a84.468 84.468 0 0 0-39.84 16.22c-24.34-38.38-62.91-62.37-105.31-62.37-40.98 0-78.4 22.75-102.65 59.2-11.48-7.31-24.59-11.87-38.22-13.28 21.33-7.2 36.71-27.15 36.71-50.59 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 26 18.91 47.71 43.89 52.48-29.23 6.72-54.18 27.1-66.24 55.31-4.48 10.72-4.52 22.54-.11 33.28s12.76 19.11 23.5 23.59c5.35 2.22 10.97 3.34 16.43 3.34.51 0 1.04-.01 1.55-.03h80.62c1.65 0 3.2-.38 4.6-1.03 2.93 1.7 6.08 3.03 9.41 3.96 2.38.66 4.82 1.1 8.19 1.35l182.34.07c.32 0 .64-.01.93-.04 6.89-.6 13.22-2.92 18.61-6.5 1.82 1.37 4.07 2.19 6.53 2.19h81.36c5.71 0 11.48-1.15 16.73-3.34 22.09-9.21 32.59-34.72 23.4-56.79zM383.43 234.84c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73c-17.91 0-32.5-14.23-32.5-31.73zm-320.37 0c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73-32.5-14.23-32.5-31.73zm-3.12 146.25c-3.16.19-6.28-.44-9.23-1.66-5.35-2.24-9.53-6.42-11.74-11.79-2.21-5.37-2.19-11.28.02-16.55 11.14-26.1 36.7-43.53 64.81-44.42 12.77.08 25.85 3.81 36.72 10.94-5.81 12.01-10.34 25.07-13.33 38.97-2.28 8.18-1.89 16.68 1 24.49l-68.25.02zm285.03 4.34-180.89-.03c-1.1-.1-2.19-.29-3.27-.59-4.69-1.29-8.6-4.34-10.97-8.57-2.4-4.23-3.01-9.15-1.57-14.46 12.78-59.2 56.23-100.54 105.69-100.54 49.9 0 93.67 41.37 106.6 101.28.3 1.04.51 2.11.59 3.22.84 9.87-6.37 18.62-16.18 19.69zm116.32-6.02c-2.74 1.14-5.79 1.58-8.87 1.67h-71.93a39.601 39.601 0 0 0 2.31-17.18c-.21-2.46-.64-4.91-1.15-6.63-2.75-12.77-6.78-24.82-11.9-36 11.28-9.36 25.32-14.53 39.68-14.61 27.96 1.02 52.9 18.39 63.55 44.33 4.62 11.05-.63 23.81-11.69 28.42z" />
												 </svg>
											</div>

											<div className="kpi-card-content">
												<div className="kpi-card-label">Customer Profiles</div>	
												<div className="kpi-card-value">{widgetDataState.customer_count.toLocaleString("da-DK")} <span className="kpi-card-value-faded">({customer_percentage.toFixed()}%)</span></div>	
											</div>
											<div className="kpi-card-info">
												<div className="kpi-card-info-icon">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
													    <path d="M205.95 52.98c0-28.2 20.44-47.23 50.05-47.23 29.61 0 50.05 19.03 50.05 47.23 0 28.2-20.45 47.23-50.05 47.23-29.61 0-50.05-19.03-50.05-47.23zm7.75 106.45h83.88v346.82H213.7V159.43z" />
													  </svg>
												</div>

												<div className="kpi-card-info-tooltip">Number of profiles who spend money</div>
											</div>
										</div>

									</div>

									<div className="widget-kpis-stat">
										
										<div className="kpi-card">
											<div className="kpi-card-icon">	
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
												   <path d="M254.74 235.73c36.81 0 66.74-29.37 66.74-65.45s-29.93-65.44-66.74-65.44c-36.81 0-66.74 29.36-66.74 65.44s29.93 65.45 66.74 65.45zm0-109.16c24.81 0 45 19.6 45 43.71s-20.19 43.72-45 43.72-45-19.61-45-43.72 20.18-43.71 45-43.71z" />
												   <path d="M493.09 342.67c-11.66-28.36-36.48-48.87-66-55.51 24.57-5.09 43.08-26.61 43.08-52.33 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 23.69 15.71 43.82 37.41 50.82a84.468 84.468 0 0 0-39.84 16.22c-24.34-38.38-62.91-62.37-105.31-62.37-40.98 0-78.4 22.75-102.65 59.2-11.48-7.31-24.59-11.87-38.22-13.28 21.33-7.2 36.71-27.15 36.71-50.59 0-29.48-24.33-53.47-54.23-53.47s-54.23 23.99-54.23 53.47c0 26 18.91 47.71 43.89 52.48-29.23 6.72-54.18 27.1-66.24 55.31-4.48 10.72-4.52 22.54-.11 33.28s12.76 19.11 23.5 23.59c5.35 2.22 10.97 3.34 16.43 3.34.51 0 1.04-.01 1.55-.03h80.62c1.65 0 3.2-.38 4.6-1.03 2.93 1.7 6.08 3.03 9.41 3.96 2.38.66 4.82 1.1 8.19 1.35l182.34.07c.32 0 .64-.01.93-.04 6.89-.6 13.22-2.92 18.61-6.5 1.82 1.37 4.07 2.19 6.53 2.19h81.36c5.71 0 11.48-1.15 16.73-3.34 22.09-9.21 32.59-34.72 23.4-56.79zM383.43 234.84c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73c-17.91 0-32.5-14.23-32.5-31.73zm-320.37 0c0-17.5 14.58-31.73 32.5-31.73s32.5 14.23 32.5 31.73-14.58 31.73-32.5 31.73-32.5-14.23-32.5-31.73zm-3.12 146.25c-3.16.19-6.28-.44-9.23-1.66-5.35-2.24-9.53-6.42-11.74-11.79-2.21-5.37-2.19-11.28.02-16.55 11.14-26.1 36.7-43.53 64.81-44.42 12.77.08 25.85 3.81 36.72 10.94-5.81 12.01-10.34 25.07-13.33 38.97-2.28 8.18-1.89 16.68 1 24.49l-68.25.02zm285.03 4.34-180.89-.03c-1.1-.1-2.19-.29-3.27-.59-4.69-1.29-8.6-4.34-10.97-8.57-2.4-4.23-3.01-9.15-1.57-14.46 12.78-59.2 56.23-100.54 105.69-100.54 49.9 0 93.67 41.37 106.6 101.28.3 1.04.51 2.11.59 3.22.84 9.87-6.37 18.62-16.18 19.69zm116.32-6.02c-2.74 1.14-5.79 1.58-8.87 1.67h-71.93a39.601 39.601 0 0 0 2.31-17.18c-.21-2.46-.64-4.91-1.15-6.63-2.75-12.77-6.78-24.82-11.9-36 11.28-9.36 25.32-14.53 39.68-14.61 27.96 1.02 52.9 18.39 63.55 44.33 4.62 11.05-.63 23.81-11.69 28.42z" />
												 </svg>
											</div>

											<div className="kpi-card-content">
												<div className="kpi-card-label">Profile CLV</div>	
												<div className="kpi-card-value">{widgetDataState.customer_lifetime_value.toLocaleString("da-DK",{ style: "currency", currency: "DKK"})}</div>	
											</div>
											<div className="kpi-card-info">
												<div className="kpi-card-info-icon">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
													    <path d="M205.95 52.98c0-28.2 20.44-47.23 50.05-47.23 29.61 0 50.05 19.03 50.05 47.23 0 28.2-20.45 47.23-50.05 47.23-29.61 0-50.05-19.03-50.05-47.23zm7.75 106.45h83.88v346.82H213.7V159.43z" />
													  </svg>
												</div>

												<div className="kpi-card-info-tooltip">Customer Lifetime Value based on profiles who spent money</div>
											</div>
										</div>

									</div>

									<div className="widget-kpis-stat">
										
										<div className="kpi-card">
											<div className="kpi-card-icon ">	
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
												   <path d="M292.02 461.89c-3.7 0-7.35-.85-10.74-2.51-5.91-2.89-10.33-7.9-12.44-14.11l-73.36-209.86a31.975 31.975 0 0 1-.1-20.5c2.65-7.97 8.3-14.5 15.88-18.29 7.56-3.77 16.19-4.42 24.21-1.72l209.43 69.8a24.448 24.448 0 0 1 15.83 16.54c3.7 13.02-3.86 26.59-16.81 30.32l-98.34 28.02-29.97 104.52c-2.24 7.67-7.99 13.79-15.47 16.4-2.68.93-5.4 1.39-8.12 1.39zm14.06-29.71.08.22c-.03-.07-.06-.15-.08-.22zm-68.09-194.82 52.64 150.63 23.03-80.39 72.87-20.74-148.54-49.5zm194.39 64.8c.03 0 .05.01.08.01l-.08-.01zm-199.61-79.71.1.31c-.02-.1-.05-.21-.1-.31zM112.84 333.08c-5.06 0-10.1-1.93-13.96-5.78-7.71-7.71-7.71-20.2 0-27.91l33.88-33.88c7.71-7.71 20.2-7.71 27.91 0s7.71 20.2 0 27.91l-33.88 33.88a19.655 19.655 0 0 1-13.95 5.78zm5.11-99.76H70.07c-10.9 0-19.74-8.84-19.74-19.74s8.84-19.74 19.74-19.74h47.89c10.9 0 19.74 8.84 19.74 19.74s-8.85 19.74-19.75 19.74zm171.55-66.4c-5.04 0-10.1-1.93-13.96-5.77-7.71-7.7-7.71-20.2 0-27.91l33.85-33.9c7.71-7.72 20.2-7.72 27.91-.03s7.71 20.2 0 27.91l-33.85 33.9c-3.85 3.87-8.89 5.8-13.95 5.8zm-142.12 0c-5.06 0-10.1-1.93-13.96-5.78l-33.88-33.9c-7.71-7.71-7.71-20.22 0-27.93 7.74-7.7 20.23-7.69 27.91.01l33.88 33.9c7.71 7.71 7.71 20.22 0 27.93a19.72 19.72 0 0 1-13.95 5.77zm66.42-29.43c-10.9 0-19.74-8.84-19.74-19.74v-47.9c0-10.9 8.84-19.74 19.74-19.74s19.74 8.84 19.74 19.74v47.9c0 10.9-8.84 19.74-19.74 19.74z" />
												</svg>
											</div>

											<div className="kpi-card-content">
												<div className="kpi-card-label">Active Profiles</div>	
												<div className="kpi-card-value">{widgetDataState.active_profiles_count.toLocaleString("da-DK")} <span className="kpi-card-value-faded">({active_percentage.toFixed()}%)</span></div>	
											</div>
											<div className="kpi-card-info">
												<div className="kpi-card-info-icon">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
													    <path d="M205.95 52.98c0-28.2 20.44-47.23 50.05-47.23 29.61 0 50.05 19.03 50.05 47.23 0 28.2-20.45 47.23-50.05 47.23-29.61 0-50.05-19.03-50.05-47.23zm7.75 106.45h83.88v346.82H213.7V159.43z" />
													  </svg>
												</div>

												<div className="kpi-card-info-tooltip">Number of profiles who had activity in the last 30 days</div>
											</div>
										</div>

									</div>

									<div className="widget-kpis-stat">
										
										<div className="kpi-card">
											<div className="kpi-card-icon kpi-card-icon--small">	
												<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16.2 59v267.2c0 10.4 4.4 20.4 12.1 27.5 6.9 6.3 15.9 9.8 25.2 9.8 1.1 0 2.2 0 3.3-.1 14.8-1.3 27.2-11.7 31.9-25.4l157.5-38.8v.9H276v47.7h24.6v107c0 10.4 4.4 20.4 12.1 27.5 6.9 6.3 15.9 9.8 25.2 9.8 1.1 0 2.2 0 3.3-.1 19-1.7 33.9-18.2 33.9-37.6V347.7h24.6V300h29.8v-46c36.3-1 65.5-30.7 65.5-67.2s-29.2-66.3-65.5-67.2v-40H246.2v.7L88.5 46.8c-4.8-13.5-17-23.7-31.7-25-10.4-.9-20.8 2.6-28.5 9.6-7.7 7.1-12.1 17.1-12.1 27.6zm383.2 211H276.2V109.6h123.2V270zm30-46v-74.4c19.7.9 35.5 17.3 35.5 37.2S449.1 223 429.4 224zm-98.9 123.7H345v106.4c0 4-2.9 7.4-6.6 7.7-2.8.2-4.7-1.1-5.6-1.9-.9-.8-2.4-2.6-2.4-5.4V347.7zM306 300h63.6v17.7H306V300zm-59.8-31.8L90.7 306.5V78l155.5 32.9v157.3zM46.2 59c0-2.8 1.5-4.5 2.4-5.4.9-.8 2.8-2.1 5.6-1.9 3.7.3 6.6 3.7 6.6 7.7v266.2c0 4-2.9 7.4-6.6 7.7-2.8.3-4.7-1.1-5.6-1.9-.9-.8-2.4-2.6-2.4-5.4V59z"/><path d="M319.7 143.9h36.2c8.3 0 15 6.7 15 15s-6.7 15-15 15h-36.2c-8.3 0-15-6.7-15-15s6.7-15 15-15zM319.7 202.3h36.2c8.3 0 15 6.7 15 15s-6.7 15-15 15h-36.2c-8.3 0-15-6.7-15-15s6.7-15 15-15z"/></svg>
											</div>

											<div className="kpi-card-content">
												<div className="kpi-card-label">Accepted Marketing</div>	
												<div className="kpi-card-value">{widgetDataState.accepted_marketing_count.toLocaleString("da-DK")} <span className="kpi-card-value-faded">({marketing_percentage.toFixed()}%)</span></div>	
											</div>
											<div className="kpi-card-info">
												<div className="kpi-card-info-icon">
													<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
													    <path d="M205.95 52.98c0-28.2 20.44-47.23 50.05-47.23 29.61 0 50.05 19.03 50.05 47.23 0 28.2-20.45 47.23-50.05 47.23-29.61 0-50.05-19.03-50.05-47.23zm7.75 106.45h83.88v346.82H213.7V159.43z" />
													  </svg>
												</div>

												<div className="kpi-card-info-tooltip">Number of profiles who accepted email marketing</div>
											</div>
										</div>

									</div>
								
								</div>

							</div>

						)
					}
				</div>

			     
			        <div className="widget-v3-footer">
			        	
						<div></div>

			        	<div className="widget-v3-footer-settings">
				        	<Link to="/marketing/profile-insights">
				        		<div className="widget-v3-footer-link">
					        		<div className="widget-v3-footer-link-label">Profile Insights</div>
					        		<div className="widget-v3-footer-link-icon">
						        		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
						        		    <path d="m166.48 502.4-67.32-67.32L278.2 255.99 99.16 76.92 166.48 9.6l246.36 246.39z" />
						        		</svg>
					        		</div>
				        		</div>
				        	</Link>				        	
				        	
			        	</div>
							
			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesKPIsSidebar;

