import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

export const SalesByChannel = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,
		open_ids: [],

		totalRevenueExclVAT: 0,
		orderCount: 0,
		
		totalRevenueOrderYOYOExclVAT:0, 
		orderCountOrderYOYO:0, 
		orderCountByTypeOrderYOYO: {}, 

		totalRevenueWoltExclVAT:0, 
		orderCountWolt: 0, 
		orderCountByTypeWolt: {}, 


		totalRevenueHeapsExclVAT:0, 
		orderCountHeaps: 0, 
		orderCountByTypeHeaps: {}, 
		
	});

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		const aggregatedRevenuePromise = loadAggregatedRevenue();

		Promise.all([aggregatedRevenuePromise])
			.then( values => {

				setWidgetState({ 
					...widgetState,
					error: false,
					totalRevenueExclVAT: !isNaN(values[0].data.totals.revenue_excl_vat) ? parseInt(values[0].data.totals.revenue_excl_vat / 100) : 0,
					orderCount: values[0].data.totals.order_count,
					
					totalRevenueOrderYOYOExclVAT: !isNaN(values[0].data.totals.revenue_excl_vat_orderyoyo) ? parseInt(values[0].data.totals.revenue_excl_vat_orderyoyo / 100) : 0,
					orderCountOrderYOYO: !isNaN(values[0].data.totals.order_count_orderyoyo) ? values[0].data.totals.order_count_orderyoyo : 0,
					orderCountByTypeOrderYOYO: values[0].data.totals.order_type_count_orderyoyo,

					totalRevenueWoltExclVAT: !isNaN(values[0].data.totals.revenue_excl_vat_wolt) ? parseInt(values[0].data.totals.revenue_excl_vat_wolt / 100) : 0,
					orderCountWolt: !isNaN(values[0].data.totals.order_count_wolt) ? values[0].data.totals.order_count_wolt : 0,
					orderCountByTypeWolt: values[0].data.totals.order_type_count_wolt,

					totalRevenueHeapsExclVAT: !isNaN(values[0].data.totals.revenue_excl_vat_heaps) ? parseInt(values[0].data.totals.revenue_excl_vat_heaps / 100) : 0,
					orderCountHeaps: !isNaN(values[0].data.totals.order_count_heaps) ? values[0].data.totals.order_count_heaps : 0,
					orderCountByTypeHeaps: values[0].data.totals.order_type_count_heaps,
				});
				
				setIsLoading(false);
			})
			.catch(error => {
				setWidgetState({
					...widgetState,
					error: true,
					totalRevenueExclVAT: 0,
					orderCount: 0,
					totalRevenueOrderYOYOExclVAT:0, 
					orderCountOrderYOYO:0, 
					orderCountByTypeOrderYOYO: {}, 

					totalRevenueWoltExclVAT:0, 
					orderCountWolt: 0, 
					orderCountByTypeWolt: {}, 
				});
				setIsLoading(false);
				console.log(error);
			}
		);

	}

	/**
	* Load sales data
	*/
	const loadAggregatedRevenue = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/revenue/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: 'year', // hour, day, month or year
				// compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
    			// compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}


	/** 
	 * Calculate channels
	 */
	const calculateChannels = () => {


		const totalRevenueExclVAT = widgetState.totalRevenueExclVAT;
		const totalRevenueExclVATOrderYOYO = widgetState.totalRevenueOrderYOYOExclVAT;
		const totalRevenueExclVATWolt = widgetState.totalRevenueWoltExclVAT;
		const totalRevenueExclVATHeaps = widgetState.totalRevenueHeapsExclVAT;

		const orderDeliveryCountOrderYOYO = widgetState.orderCountByTypeOrderYOYO.delivery || 0;
		const orderTakeawayCountOrderYOYO = widgetState.orderCountByTypeOrderYOYO.takeaway || 0;
		const orderDeliveryCountWolt = widgetState.orderCountByTypeWolt.delivery || 0;
		const orderTakeawayCountWolt = widgetState.orderCountByTypeWolt.takeaway || 0;
		
		const orderDeliveryCountHeaps = widgetState.orderCountByTypeHeaps.delivery || 0;
		const orderTakeawayCountHeaps = widgetState.orderCountByTypeHeaps.takeaway || 0;


		// OrderYOYO Fee calculations
		const OrderYOYOCommission = ((totalRevenueExclVATOrderYOYO * 1.25)/100) * 10; // 7% of the revenue including tax
		const OrderYOYODeliveryFee = orderDeliveryCountOrderYOYO * 35; // 35 kr per delivery including tax
		const orderYOYOFee = (OrderYOYOCommission + OrderYOYODeliveryFee) * 0.8; // Total fee excluding tax

		// Wolt Fee calculations
		const WoltCommission = ((totalRevenueExclVATWolt * 1.25) / 100) * 26; // 26% of revenue including tax
		const WoltFee = WoltCommission; // Excluding tax

		// Heaps 
		const HeapsCommission = ((totalRevenueExclVATHeaps) / 100) * 5.5; // 5% of revenue including tax
		const HeapsFee = HeapsCommission; // Excluding tax		

		const channelData = [
			{
				source: "OnlinePOS",
				revenue: totalRevenueExclVAT,
				fee: 0,
				order_count: widgetState.orderCount,
				order_count_by_type: [],
			},
			
			{
				source: "GRØD App",
				revenue: totalRevenueExclVATHeaps,
				fee: HeapsFee,
				order_count: widgetState.orderCountHeaps, 
				order_count_by_type: [
					{
						type: 'Levering',
						// count: orderDeliveryCountHeaps,
						count: 0,
					},
					{
						type: 'Afhentning',
						//count: orderTakeawayCountHeaps,
						count: widgetState.orderCountHeaps,
					},
				]
			},

			{
				source: "Wolt",
				revenue: totalRevenueExclVATWolt,
				fee: WoltFee,
				order_count: widgetState.orderCountWolt, 
				order_count_by_type: [
					{
						type: 'Levering',
						count: orderDeliveryCountWolt,
					},
					{
						type: 'Afhentning',
						count: orderTakeawayCountWolt,
					},
				]
			},
			{
				source: "OrderYOYO",
				revenue: totalRevenueExclVATOrderYOYO,
				fee: orderYOYOFee,
				order_count: widgetState.orderCountOrderYOYO,
				order_count_by_type: [
					{
						type: 'Levering',
						count: orderDeliveryCountOrderYOYO,
					},
					{
						type: 'Afhentning',
						count: orderTakeawayCountOrderYOYO,
					},
				]
			},

		]

		return channelData;
	}

	/**
	 * Open details panel
	 */
	const openDetails = (i) => {
		
		// Get current open id's
		const open_ids = widgetState.open_ids;

		let new_ids = open_ids;

		// Check if the clicked id is in there. If it is, remove it, otherwise add it. (only use this if we want to be able to open multiple)
		if (open_ids.includes(i)) {
			
			// remove it,
			const index = new_ids.indexOf(i);
			new_ids.splice(index, 1);
			
		} else {
			new_ids.push(i);
		}

		setWidgetState({
			...widgetState,
			open_ids: new_ids
		});
	}


	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	const channels = calculateChannels();
	let total_revenue = channels.reduce(function(accumulator, currentValue, currentIndex, array) {
		return accumulator + currentValue.revenue;
	}, 0);

	return (
		<div className={`widget-v2 ${loadingClass}`}>
	        
	        <div className="widget-v2-header">
	        	<h2 className="widget-v2-title">Omsætning per kanal</h2>	

	        	<div className="widget-v2-header-legends">

					<div className="widget-v2-header-legend" style={{color: "#2771D5"}}>
						<div className="widget-v2-header-legend-dot" style={{backgroundColor: "#2771D5"}}></div>
						<div className="widget-v2-header-legend-title">Omsætning minus gebyr</div>
					</div>

					<div className="widget-v2-header-legend" style={{color: "#DB7272"}}>
						<div className="widget-v2-header-legend-dot" style={{backgroundColor: "#DB7272"}}></div>
						<div className="widget-v2-header-legend-title">gebyr</div>
					</div>

	            </div>
	        </div>
			
			<div className="widget-v2-body">
					{
						widgetState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot <span role="img">❤️</span></p>
						)
					}			
						
					{
						isLoading === true ? (
							<div className="responsive-line-container">
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							</div>
						) : (
							<div>
								{
									channels.map((channel, i) => {

										const revenue_minus_fee = channel.revenue - channel.fee;
										const revenue_minus_fee_percentage = parseFloat((revenue_minus_fee / total_revenue) * 100) || 0;

										const revenue_percentage = parseFloat((channel.revenue / total_revenue) * 100) || 0;

										const fee_percentage = parseFloat((channel.fee / total_revenue) * 100) || 0;

										const order_count = channel.order_count;
										const basket_size = channel.revenue / channel.order_count || 0;


										// Handle opening/closing class
										let detailsClass = '';
										if (widgetState.open_ids.includes(i)) {
											detailsClass = 'sales-by-channel-details--open';	
										}


										return (
											<div key={i} className="sales-by-channel-item" onClick={() => openDetails(i)}>

												<div className="sales-by-channel-main">
													<div className="sales-by-channel-header">
														<h2 className="sales-by-channel-title">{channel.source}</h2>
														<div className="sales-by-channel-subtitle">{channel.revenue.toLocaleString("da-DK")}</div>
													</div>
													
													<div className="sales-by-channel-bar-container">
														<div className="sales-by-channel-bar">
															<div className="sales-by-channel-bar-fill" style={{width: revenue_minus_fee_percentage + "%"}}>
																<div className="sales-by-channel-bar-fill-text">{Math.floor(revenue_minus_fee).toLocaleString("da-DK")}</div>
															</div>
															
															<div className="sales-by-channel-bar-fill2" style={{width: fee_percentage + "%"}}>
																<div className="sales-by-channel-bar-fill-text">{Math.floor(channel.fee).toLocaleString("da-DK")}</div>
															</div>
														</div>
													</div>
													<div className="sales-by-channel-bar-percentage">{revenue_percentage.toFixed(1)}%</div>
												</div>

												<div className={`sales-by-channel-details ${detailsClass}`}>
													<div className="sales-by-channel-details-inner">
														<div className="sales-by-channel-details-container">
															<div className="sales-by-channel-details-pre-padding"></div>
															<div className="sales-by-channel-details-content">
																
																<div className="sales-by-channel-details-content-kpis">
																	<div className="sales-by-channel-details-content-kpi">
																		<div className="sales-by-channel-details-content-kpi-label">Omsætning</div>
																		<div className="sales-by-channel-details-content-kpi-value">{channel.revenue.toLocaleString("da-DK").toLocaleString("da-DK")} kr.</div>
																	</div>

																	<div className="sales-by-channel-details-content-kpi">
																		<div className="sales-by-channel-details-content-kpi-label">Gebyr</div>
																		<div className="sales-by-channel-details-content-kpi-value">{Math.floor(channel.fee).toLocaleString("da-DK")} kr.</div>
																	</div>

																	<div className="sales-by-channel-details-content-kpi">
																		<div className="sales-by-channel-details-content-kpi-label">Ordrer</div>
																		<div className="sales-by-channel-details-content-kpi-value">{order_count}</div>
																	</div>

																	<div className="sales-by-channel-details-content-kpi">
																		<div className="sales-by-channel-details-content-kpi-label">Basket Size</div>
																		<div className="sales-by-channel-details-content-kpi-value">{parseFloat(basket_size).toFixed(2)} kr.</div>
																	</div>
																</div>

																{
																	channel.order_count_by_type.length > 0 && (

																		<div className="orders-by-type-container">

																			<div className="orders-by-type-bar">
																		
																				{
																					channel.order_count_by_type.map((type, i) => {

																						const percent = type.count / order_count * 100;

																						return (
																							<div key={i} className="orders-by-type-bar-section" style={{width: percent + '%'}}>
																								<div className="orders-by-type-bar-section-label">{type.type}</div>
																								<div className="orders-by-type-bar-section-value">{type.count} ordrer</div>
																							</div>
																						)
																					})	
																				}
																				
																			</div>
																		</div>

																	)


																}

															</div>
															<div className="sales-by-channel-details-post-padding"></div>
														</div>
													</div>
												</div>


											</div>

										)
									})
								}	

							</div>
						)

					}

			</div>						        

	    </div>
	); 
}

export default SalesByChannel;
