import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import { Paper } from '@material-ui/core';
import MaterialTable from 'material-table';

export const CompetitionTable = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,
		table_data: [],
		products_by_department: [],
		revenue_by_department: [],
		categories_by_department: [],
	});

	/**
	 * Set frequency options
	 */
	useEffect(() => {
		loadAllData();	
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);


	useEffect(() => {
		formatTableData();	
	}, [widgetState.products_by_department]);

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);


		const aggregatedProductsByDepartmentPromise = loadAggregatedProductsByDepartment();

		const loadAggregatedCategoriesByDepartmentPromise = loadAggregatedCategoriesByDepartment();

		const loadAggregatedDepartmentDataPromis = loadAggregatedDepartmentData();
      
		Promise.all([aggregatedProductsByDepartmentPromise, loadAggregatedCategoriesByDepartmentPromise, loadAggregatedDepartmentDataPromis])
			.then( values => {
			
				setWidgetState({ 
					...widgetState,
					error:  false,
					products_by_department: values[0].data.products_by_department,
					revenue_by_department: values[2].data.departments,
					categories_by_department: values[1].data.categories_by_department,
				});

				setIsLoading(false);


			})
			.catch(error => {
				setWidgetState({ 
					...widgetState,
					error:  true,
					products_by_department: [],
					revenue_by_department: [],
					categories_by_department: [],
				});

				setIsLoading(false);
				console.log(error);
			}
		);
	}




	/**
	 * Load sales data
	 */
	const loadAggregatedProductsByDepartment = async () => {
		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/products-by-departments/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
                product_name: [
                	'Grøds Rødgrødkompot Glas',
                ],
			}
		});

		return axiosPromise;
	}



	/**
	 * Load Aggregated revenue by department
	 */
	const loadAggregatedRevenueByDepartment = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/revenue-by-departments/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;
	}


	/**
	 * Load sales data
	 */
	const loadAggregatedDepartmentData = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/department-data/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;
	}

	/**
	 * Load Aggregated categories by department
	 */
	const loadAggregatedCategoriesByDepartment = async () => {


		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/categories-by-departments/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}



	/**
	 * Format table data
	 */
	const formatTableData = () => {

		const products_by_department = widgetState.products_by_department;
        const revenue_by_department = widgetState.revenue_by_department;
        //const categories_by_department = this.state.categories_by_department;
                	
        const products = products_by_department.filter(product => product.product_name === 'Grøds Rødgrødkompot Glas');

        //console.log(products);

        let table_data = [];

        for (const product of products) {

            for (const department of product.sales) {
                    
                const department_order_count = revenue_by_department.find(dep => dep.department_name === department.department_name);

                const index = table_data.findIndex(row => row.department === department.department_name);

                let row;

                if (index === -1) {
            	     row = {
                            department: department.department_name, 
                            count: department.item_count, 
                            order_count: department_order_count.onlinepos.order_count,
                            revenue: (department.revenue_excl_vat / 100).toFixed(0),
                            index: 0,
                    }

                    table_data.push(row);
                    row.index = parseFloat(row.count / row.order_count * 10000).toFixed(1);

                } else {
                        
                    table_data[index] = {
                            department: department.department_name,
                            count: parseInt(department.item_count + table_data[index].count),
                            order_count: table_data[index].order_count,
                            revenue: parseInt(department.revenue_excl_vat / 100) + parseInt(table_data[index].revenue),
                            index: 0,
                    }

                    table_data[index].index = parseFloat(table_data[index].count / table_data[index].order_count * 10000).toFixed(1);

                }

            }

        }


        // Sort array of objects
        table_data.sort((a, b) => (parseFloat(a.index) > parseFloat(b.index) ) ? -1 : 1);

        let count = 1;

        table_data.map(item => {
        	item.place = '#' + count;
        	count++;
        	return item;
        })

        setWidgetState({ 
			...widgetState,
			table_data: table_data,
		});


	}


	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className={`widget-v2 ${loadingClass}`}>
		    <div className="widget-v2-header">
		    	<h2 className="widget-v2-title">Leaderboard - Rødgrødkompot Glas</h2>
		    </div>
			
			<div className="widget-v2-body">
					{
						widgetState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot ❤️</p>
						)
					}

					{
						isLoading === true ? (
							<div className="responsive-line-container">
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							</div>
						) : (
							<MaterialTable
								title="Leaderboard"
								columns={[
                                    { title: 'Afdeling', field: 'department' },
                                    { title: 'Antal solgte', field: 'count', type: 'numeric' },
                                    { title: 'Antal Ordrer', field: 'order_count', type: 'numeric' },
                                    //{ title: 'Omsætning', field: 'revenue', type: 'numeric' },
                                    //{ title: 'Index', field: 'index', type: 'numeric' },
                                    { title: 'Placement', field: 'place', type: 'numeric' },
                                ]}
								data={widgetState.table_data}
								components={{ Container: props => <Paper {...props} elevation={0}/> }} // This removes the drop shadow 
								options={{
										search: false,
										pagination: false,
										pageSize: 10,
										pageSizeOptions: [5, 9, 10, 20],
										//exportButton: true,
										//filtering: true,
										toolbar: false,
								}}

								/>
						)
					}
					
			</div>						        

			{/*
		    <div className="widget-v2-footer">
		    	footer
		    </div>
		    */}

		</div>
	); 
};

export default CompetitionTable;
