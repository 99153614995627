import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import Select from 'react-select';
import { ResponsiveLine } from '@nivo/line';


export const TimelineBasketsize = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [timelineState, setTimelineState] = useState({
		error: false,

		source_filter: 'all',
		timeline_combined: true,
		onlinepos: [],
		orderyoyo: [],
		wolt: [],
		heaps: [],
		legends: [],
	});


	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * Automatically detect what frequency to use
	 */
	const getFrequency = () => {

		let frequency = '';
		if (props.frequency.value) {
			frequency = props.frequency.value.value;
		}

		return frequency;
	}

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setTimelineState({
			...timelineState,
			source_filter: new_mode
		});
	}

	/**
	 * Toggle accumulator
	 */
	const toggleTimelineCombined = () => {

		const current = timelineState.timeline_combined;

		setTimelineState({
			...timelineState,
			timeline_combined: !current,
		})
	}

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		const aggregatedOrdersPromise = loadAggregatedOrdersByDepartmentsAndTime();

		Promise.all([aggregatedOrdersPromise])
			.then( values => {

				setTimelineState({ 
					...timelineState,
					onlinepos: values[0].data.onlinepos,
					orderyoyo: values[0].data.orderyoyo,
					wolt: values[0].data.wolt,
					heaps: values[0].data.heaps,
				});
				setIsLoading(false);
			})
			.catch(error => {
				setTimelineState({
					...timelineState, 
					error: true,
					onlinepos: [],
					orderyoyo: [],
					wolt: [],
					heaps: [],
				});
				setIsLoading(false);
				console.log(error);
			}
		);

	}

	/**
	 * Load sales data
	 */
	const loadAggregatedOrdersByDepartmentsAndTime = async () => {
	
		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/orders/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: getFrequency(), // hour, day, month or year
				// compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
    			// compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}

	/** 
	 * Setup default data with no actual input.
	 */
	const getDefaultTimelineData = () => {

		const frequency = getFrequency();
		const start_date = props.start_date;
		const end_date = props.end_date;
		
		// Hourly
		let default_hourly = [];
		default_hourly = [
			{ x: '00.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '01.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '02.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '03.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '04.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '05.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '06.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '07.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '08.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '09.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '10.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '11.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '12.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '13.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '14.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '15.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '16.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '17.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '18.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '19.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '20.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '21.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '22.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
			{ x: '23.00', y: 0, ordercount: 0, revenue_excl_vat: 0 },
		];


		if (frequency === 'hour') {
			
			return default_hourly;
		}


		else if (frequency === 'day') {
			
			// Daily
			let default_daily = [];
			let daily_temp_date = moment(start_date);
			
			while(daily_temp_date.format('YYYY-MM-DD') <= moment(end_date).format('YYYY-MM-DD')) {

				default_daily.push({
					x: daily_temp_date.format('YYYY-MM-DD'),
					y: 0,
					ordercount: 0,
					revenue_excl_vat: 0,
				});

				daily_temp_date.add(1, 'days');
			}

			return default_daily;

		}

		else if (frequency === 'week') {
			
			// Weekly
			let default_weekly = [];
			let weekly_temp_date = moment(start_date);

			while(weekly_temp_date.format('YYYY-WW') <= moment(end_date).format('YYYY-WW')) {

				default_weekly.push({
					x: weekly_temp_date.format('W'),
					y: 0,
					ordercount: 0,
					revenue_excl_vat: 0,
				});

				weekly_temp_date.add(1, 'weeks');
			}

			console.log('w', default_weekly)
			return default_weekly;

		}

		else if (frequency === 'month') {
			// Monthly
			let default_monthly = [];
			let monthly_temp_date = moment(start_date);
			
			while(monthly_temp_date.format('YYYY-MM') <= moment(end_date).format('YYYY-MM')) {

				default_monthly.push({
					x: moment( monthly_temp_date ).format('YYYY-MM'),
					y: 0,
					ordercount: 0,
					revenue_excl_vat: 0,
				});

				monthly_temp_date.month(monthly_temp_date.month() + 1);
			}
			return default_monthly;
		}

		else if (frequency === 'year') {
			
			// Yearly
			let default_yearly = [];
			let yearly_temp_date = moment(start_date);
			
			while(yearly_temp_date.format('YYYY') <= moment(end_date).format('YYYY')) {

				default_yearly.push({
					x: moment( yearly_temp_date ).format('YYYY'),
					y: 0,
					ordercount: 0,
					revenue_excl_vat: 0,
				});

				yearly_temp_date.year(yearly_temp_date.year() + 1);
			}

			return default_yearly;
		
		} else {
			return default_hourly;
		}

	}


	/**
	 * Prepare the sales data for the timeline
	 */
	const prepareData = () => {

		const frequency = getFrequency();

		// Setup default data with no input
		let data = [{
			"id": "No data",
			"color": "hsl(223, 70%, 54%)",
			"data": getDefaultTimelineData(),
		}];

		// Go through each source, and add their value to Y based on selected source
		const orders_data_onlinepos = timelineState.onlinepos;
		const orders_data_orderyoyo = timelineState.orderyoyo;
		const orders_data_wolt = timelineState.wolt;
		const orders_data_heaps = timelineState.heaps;

		const source_filter = timelineState.source_filter;

		if (source_filter === 'all' || source_filter === 'onlinepos') {
			data = populateData(data, orders_data_onlinepos, 'onlinepos');
		}
		if (source_filter === 'all' || source_filter === 'orderyoyo') {
			data = populateData(data, orders_data_orderyoyo, 'orderyoyo');
		}
		if (source_filter === 'all' || source_filter === 'wolt') {
			data = populateData(data, orders_data_wolt, 'wolt');
		}
		if (source_filter === 'all' || source_filter === 'heapsgo') {
			data = populateData(data, orders_data_heaps, 'heaps');
		}

		// Combine all entries by key, to ensure that only one of each key exists.
		const combined_by_key = [];
		for (const dataset of data) {

			const dataset_index = combined_by_key.findIndex(el => el.id === dataset.id);
			if (dataset_index !== -1) {
				for (const piece of dataset.data) {
					const piece_index = combined_by_key[dataset_index].data.findIndex(d => d.x === piece.x);
					if (piece_index !== -1) {
						combined_by_key[dataset_index].data[piece_index].y += piece.y;
						combined_by_key[dataset_index].data[piece_index].ordercount += piece.ordercount;
						combined_by_key[dataset_index].data[piece_index].revenue_excl_vat += piece.revenue_excl_vat;
					}
				}
			} else {
				combined_by_key.push(dataset);
			}
		}
		data = combined_by_key;
		// Remove the initial "No data" object
		if (data.length > 1) {
			const nodata_obj_index = data.findIndex(data_obj => data_obj.id === "No data");
			data.splice(nodata_obj_index, 1);
		}

		// Combining all values
		if (timelineState.timeline_combined) {
			
			const combined_data = getDefaultTimelineData();
			for (const element of data) {
				
				for (const dataset of element.data) {
					
					const dataset_index = combined_data.findIndex(entry => entry.x === dataset.x);
					if (dataset_index !== -1) {
						combined_data[dataset_index].y += dataset.y;
						combined_data[dataset_index].ordercount += dataset.ordercount;
						combined_data[dataset_index].revenue_excl_vat += dataset.revenue_excl_vat;
					} else {
						combined_data.push(dataset);
					}
				}
			}


			const combined_timeline = {
				"id": 'Basket size',
				"color": '#4D80FF',
				"data": combined_data,
			}

			data = [combined_timeline];

		} 

		// Map colors
		let colors = ['#4D80FF', '#29D0A9', '#E33F3F', '#B44DFF', '#CBCB4E', '#CC8632', "#886330", "#333088", "#790074", "#057E05"];

		data = data.map((element, i) => {
			let colorIndex = (i % 10);
			element.color = colors[colorIndex];
			return element;
		})

		//Format date label and y-value
		data = data.map((element, i) => {
			
			if (frequency === 'hour') {
				element.data = element.data.map( entry => { 

					const revenue = parseInt( entry.revenue_excl_vat / 100 );
					let basketSize = 0;
					const try_basketsize = revenue / entry.ordercount

					if (!isNaN(try_basketsize) && isFinite(try_basketsize)) {
						basketSize = try_basketsize
					}

					return { x: entry.x, y: basketSize.toFixed(2) } 

				});	
			}

			else if (frequency === 'day') {
				element.data = element.data.map( entry => { 
					//console.log(entry);
					const revenue = parseInt( entry.revenue_excl_vat / 100 );
					let basketSize = 0;
					const try_basketsize = revenue / entry.ordercount

					if (!isNaN(try_basketsize) && isFinite(try_basketsize)) {
						basketSize = try_basketsize
					}

					return { x: moment( entry.x).format('dddd DD/MM'), y: basketSize.toFixed(2) } 
				});
			}

			else if (frequency === 'week') {
				element.data = element.data.map( entry => { 
					//console.log(entry);
					const revenue = parseInt( entry.revenue_excl_vat / 100 );
					let basketSize = 0;
					const try_basketsize = revenue / entry.ordercount

					if (!isNaN(try_basketsize) && isFinite(try_basketsize)) {
						basketSize = try_basketsize
					}

					return { x: entry.x, y: basketSize.toFixed(2) } 
				});
			}

			else if (frequency === 'month') {

				element.data = element.data.map( entry => { 
					//console.log(entry);
					const revenue = parseInt( entry.revenue_excl_vat / 100 );
					let basketSize = 0;
					const try_basketsize = revenue / entry.ordercount

					if (!isNaN(try_basketsize) && isFinite(try_basketsize)) {
						basketSize = try_basketsize
					}

					return { x: moment( entry.x).format('MM/YYYY'), y: basketSize.toFixed(2) } 
				});
			}

			else if (frequency === 'year') {

				element.data = element.data.map( entry => { 
					//console.log(entry);
					const revenue = parseInt( entry.revenue_excl_vat / 100 );
					let basketSize = 0;
					const try_basketsize = revenue / entry.ordercount

					if (!isNaN(try_basketsize) && isFinite(try_basketsize)) {
						basketSize = try_basketsize
					}

					return { x: moment( entry.x).format('YYYY'), y: basketSize.toFixed(2) } 
				});

			}
			
			return element;
		})

		// Order by name
		data.sort((a,b) => a.id > b.id ? 1: -1);

		console.log(data);

		return data;	
	}

	const populateData = (original_data, new_data, source_name) => {

		const frequency = getFrequency();
		
		for( const department of new_data) {
			
			const timeline_data = getDefaultTimelineData();				

			for (const ordercount of department.ordercounts) {

				if (frequency === 'hour') {
				
					// Get the hour number
					let hour_number = moment.utc(ordercount.date).format('HH.00');
					// if (source_name === 'wolt') {
					// 	hour_number = moment(ordercount.date).format('HH.00');
					// }

					// Find index for current hour
					const x_index = timeline_data.findIndex(hour => hour.x === hour_number );
					
					// If the number exists, sum ordercount
					if (x_index !== -1) {
						timeline_data[x_index].y +=	0;
						timeline_data[x_index].ordercount += ordercount.ordercount;
						timeline_data[x_index].revenue_excl_vat +=ordercount.revenue_excl_vat; 
					}

				}

				if (frequency === 'day') {

					// Get comparable date string 
					const date_string = moment(ordercount.date).format('YYYY-MM-DD');
					
					// Find index for current hour
					const x_index = timeline_data.findIndex( date => date.x === date_string );
					
					// If the number exists, sum the revenue
					if (x_index !== -1) {
						timeline_data[x_index].y +=	0;
						timeline_data[x_index].ordercount += ordercount.ordercount;
						timeline_data[x_index].revenue_excl_vat +=ordercount.revenue_excl_vat; 
					}

				}

				if (frequency === 'week') {
					
					// Get comparable date string 
					const date_string = moment(ordercount.date, 'YYYY-W').format('W');
					
					// Find index for current hour
					const x_index = timeline_data.findIndex( date => date.x === date_string );
					
					//console.log(ordercount.ordercount, ordercount.revenue_excl_vat);
					// If the number exists, sum the revenue
					if (x_index !== -1) {
						timeline_data[x_index].y +=	0;
						timeline_data[x_index].ordercount += ordercount.ordercount;
						timeline_data[x_index].revenue_excl_vat +=ordercount.revenue_excl_vat; 
					}

				}

				if (frequency === 'month') {

					// Get comparable date string 
					const date_string = moment(ordercount.date).format('YYYY-MM');
					
					// Find index for current hour
					const x_index = timeline_data.findIndex( date => date.x === date_string );
					
					// If the number exists, sum the revenue
					if (x_index !== -1) {
						timeline_data[x_index].y +=	0;
						timeline_data[x_index].ordercount += ordercount.ordercount;
						timeline_data[x_index].revenue_excl_vat +=ordercount.revenue_excl_vat; 
					}

				}

				if (frequency === 'year') {

					// Get comparable date string 
					const date_string = moment(ordercount.date).format('YYYY');
					
					// Find index for current hour
					const x_index = timeline_data.findIndex( date => date.x === date_string );
					
					// If the number exists, sum the revenue
					if (x_index !== -1) {
						timeline_data[x_index].y +=	0;
						timeline_data[x_index].ordercount += ordercount.ordercount;
						timeline_data[x_index].revenue_excl_vat +=ordercount.revenue_excl_vat; 
					}

				}

			}

			const department_obj = {
				"id": department.department_name,
				"color": "hsl(223, 70%, 54%)",
				"data": timeline_data
			}

			original_data.push(department_obj);

		}
		
		return original_data;
	}

	const data = prepareData();

	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className={`widget-v2 ${loadingClass}`}>
	        <div className="widget-v2-header">
	        				      
      			<Select 
              		className="timeline-selector" 
              		options={props.select.options} 
              		value={props.select.value}
              		onChange={props.select.onChange}
              	/>

              	
              	<Select 
              		className="timeline-selector" 
              		options={props.frequency.options} 
              		value={props.frequency.value}
              		onChange={props.frequency.onChange}
              	/>
        

	        </div>
			
			<div className="widget-v2-body">
					{
						timelineState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot <span role="img" aria-label="heart">❤️</span></p>
						)
					}

					<div className="responsive-line-container">
						
						{
							isLoading === true ? (
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							) : (
								<ResponsiveLine
									data={prepareData()}
									colors={d => d.color}
									margin={{ top: 50, right: 50, bottom: 70, left: 90 }}
									xScale={{ type: "point"}}
									yScale={{ type: "linear", stacked: false, min: "auto", max: "auto" }}
									curve="monotoneX"
									axisTop={null}
									axisRight={null}
									axisBottom={{
										orient: "bottom",
										tickSize: 5,
										tickPadding: 5,
										tickRotation: -40,
										legend: "",
										legendOffset: 36,
										legendPosition: "middle"
									}}
									axisLeft={{
										orient: "left",
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendOffset: -40,
										legendPosition: "middle",
										format: value => `${Number(value).toLocaleString('da-DK')}`,
									}}



									yFormat={value => {
										return `${Number(value).toLocaleString('da-DK')}`;
									}}

					      	    	enablePointLabel={true}
					      	    	pointSize={6}
					      	    	pointBorderWidth={1}
					      	    	pointBorderColor={{
										from: 'color',
										modifiers: [['darker', 0.3]],
									}}
									pointLabelYOffset={-10}
									enableArea={false}			        
									areaOpacity={0.07}
									useMesh={false}
									enableSlices='x'		
									animate={true}
									motionStiffness={300}
									motionDamping={30}
								/>
							)

						}

						
					</div>	
					 <div className="widget-v2-body-legends">
	        				
	        				{
	        					data.map((dataset, i) => {

	        						return (
	        							<div key={i} className="widget-v2-body-legend" style={{color: dataset.color}}>
	        								<div className="widget-v2-body-legend-dot" style={{backgroundColor: dataset.color}}></div>
	        								<div className="widget-v2-body-legend-title">{dataset.id}</div>
	        							</div>
	        						)

	        					})
	        				}

			            </div>
			</div>						        


	        <div className="widget-v2-footer">
	        	<ul className="widget-footer-filters">
	        		{
						timelineState.source_filter === 'all' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('all')}>Samlet</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('all')}>Samlet</li>
						)
					}

					{
						timelineState.source_filter === 'onlinepos' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						)
					}

					{
						timelineState.source_filter === 'heapsgo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						)
					}

					{
						timelineState.source_filter === 'orderyoyo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						)
					}

					{
						timelineState.source_filter === 'wolt' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						)
					}
	        	</ul>
	        	
	        	<div className="widget-timeline-v2-footer-settings">
		        	{
						timelineState.timeline_combined ? (
							<button className="widget-timeline-v2-footer-button" onClick={() => toggleTimelineCombined()}>Split afdelinger</button>
						) : (
							<button className="widget-timeline-v2-footer-button widget-timeline-v2-footer-button--active" onClick={() => toggleTimelineCombined()}>Split afdelinger</button>
						)
						
					}
	        	</div>
	        </div>

	    </div>
	); 
};

export default TimelineBasketsize;