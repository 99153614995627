import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Spinner } from "../../Spinner";

// Charts
import SimpleBars from '../../charts/SimpleBars';

export const ProfilesAttribution = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Loading state
	//const [profilesData, setProfilesData] = useState([]);

	// First entry is always the master
	const [profileAttributionData, setProfileAttributionData] = useState([]);


	// First entry is always the master
	const [barData, setBarData] = useState([]);

	const colors = ['#2771D5', '#181B5F', 'red', 'green', 'blue', 'orange'];



	/**
	 * Get data from DB
	 */
	useEffect(() => {
		
		(async () => {
			setWidgetState('loading');

			try {
				// Get access token
				const access_token = await getAccessTokenSilently();

				// Get styles data
				const profilesDataResponse = await axios.get(`${api_url}/profiles-attribution`, { 
					headers: { 'Authorization': `Bearer ${access_token}`},
					params: {
					    //start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
					}
				});

				setProfileAttributionData(profilesDataResponse.data.data);

				setWidgetState('done');
				
			} catch (e) {
        		console.error('error', e);

        		setProfileAttributionData([]);
        		
        		setWidgetState('error');
      		}

		})();

	}, [
		getAccessTokenSilently, 
	]);


	useEffect(() => {

		const total = profileAttributionData.reduce( (previousValue, currentValue) => {
			return previousValue + currentValue.count;
		}, 0)

		const bars = profileAttributionData.map(entry => {

			const obj = {
				
					label: entry.attribution,
					value: entry.count,
					total:  total,
					data: [
						{
							data_name: 'Profiles',
							color: '#2771D5',
							value: entry.count,
						},
					]
				};
			

			return obj;
		})


		setBarData(bars);

	}, [
		profileAttributionData
	]);



	



	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}

	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Attribution</h2>	
		        		<div className="widget-v3-subtitle">Where the profiles are coming</div>
		        	</div>

		        </div>
				
				<div className="widget-v3-body">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 

					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (
							<SimpleBars
								data={barData}
								showLegends={false}
							 />
						)
					}

				</div>
		    </div>
		</div>	
	); 
};

export default ProfilesAttribution;

