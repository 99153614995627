import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';


/**
 * Load sales data
 */
const loadAggregatedRevenue = async (access_token, department_ids, start_date, end_date, compared_start_date, compared_end_date) => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_URL}/aggregation/revenue/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: {
			department_ids: department_ids,
            start_date: moment(start_date).format('YYYY-MM-DD 00:00'),
            end_date: moment(end_date).format('YYYY-MM-DD 23:59'),
			frequency: 'year', // hour, day, month or year
			compared_start_date: moment(compared_start_date).format('YYYY-MM-DD 00:00'),
            compared_end_date: moment(compared_end_date).format('YYYY-MM-DD 23:59'),
		}
	});

	return axiosPromise;		
}

/**
 * Load Salary data
 */
const loadSalary = async (access_token, department_ids, start_date, end_date) => {
	
	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_URL}/aggregation/salary/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: {
			department_ids: department_ids,
            start_date: moment(start_date).format('YYYY-MM-DD 00:00'),
            end_date: moment(end_date).format('YYYY-MM-DD 23:59'),
			frequency: 'year', // hour, day, month or year
			// compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
			// compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
		}
	});

	return axiosPromise;

}

/**
 * MainStats
 */
export const MainStats = (props) => {

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		totalRevenue: 0,
		totalCost: 0,
		orderCount: 0,
		basket_size: 0,
		cogs: 0,
		contribution_margin: 0,
		salary: 0,
		salary_percentage: 0,
		beverageRatio: 0,
		revenue_by_category: [],
	});

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {

		/**
		 * Handle data load
		 */
		const loadAllData = async () => {
			
			setIsLoading(true);

			const access_token = await getAccessTokenSilently();

			const aggregatedRevenuePromise = loadAggregatedRevenue(access_token, props.department_ids, props.start_date, props.end_date, props.compared_start_date, props.compared_end_date);

			const salaryPromise = loadSalary(access_token, props.department_ids, props.start_date, props.end_date,);

			Promise.all([aggregatedRevenuePromise, salaryPromise])
				.then( values => {

					console.log('d', values[0]);

					const revenue_onlinepos = !isNaN(values[0].data.totals.revenue_excl_vat) ? parseInt(values[0].data.totals.revenue_excl_vat / 100) : 0;
					const revenue_wolt = !isNaN(values[0].data.totals.revenue_excl_vat_wolt) ? parseInt(values[0].data.totals.revenue_excl_vat_wolt / 100) : 0;
					const revenue_orderyoyo = !isNaN(values[0].data.totals.revenue_excl_vat_orderyoyo) ? parseInt(values[0].data.totals.revenue_excl_vat_orderyoyo / 100) : 0;
					const revenue_heaps = !isNaN(values[0].data.totals.revenue_excl_vat_heaps) ? parseInt(values[0].data.totals.revenue_excl_vat_heaps / 100) : 0;
					const total_revenue = revenue_onlinepos + revenue_wolt + revenue_orderyoyo + revenue_heaps;

					const total_cost_onlinepos = !isNaN(values[0].data.totals.cost) ? parseInt(values[0].data.totals.cost / 100) : 0;

					const orders_onlinepos = !isNaN(values[0].data.totals.order_count) ? values[0].data.totals.order_count : 0;
					const orders_wolt = !isNaN(values[0].data.totals.order_count_wolt) ? values[0].data.totals.order_count_wolt : 0;
					const orders_orderyoyo = !isNaN(values[0].data.totals.order_count_orderyoyo) ? values[0].data.totals.order_count_orderyoyo : 0;
					const orders_heaps = !isNaN(values[0].data.totals.order_count_heaps) ? values[0].data.totals.order_count_heaps : 0;
					const total_orders = orders_onlinepos + orders_wolt + orders_orderyoyo + orders_heaps;

					let basket_size = isFinite(total_revenue / total_orders) ? (total_revenue / total_orders) : 0;

					//Calculate contribution margin
					let contributionMarginPercentage = (total_revenue - total_cost_onlinepos) / total_revenue;
					
					if (isFinite(contributionMarginPercentage)) {
						contributionMarginPercentage = contributionMarginPercentage * 100;
						contributionMarginPercentage = parseInt(contributionMarginPercentage);	
					} else {
						contributionMarginPercentage = 0;
					}

					// Calculate salary ratio
					const total_salary = parseInt( (values[1].data.totals.salary * 1.155) / 100);
					let salary_ratio = (total_salary / total_revenue) * 100;
					if (isFinite(salary_ratio)) {
						salary_ratio = parseInt(salary_ratio);
					} else {
						salary_ratio = 0;
					}

					setWidgetState(prevState => ({
						...prevState,
						totalRevenue: total_revenue,
						totalCost: total_cost_onlinepos,
						orderCount: total_orders,
						basket_size: basket_size,
						cogs: total_cost_onlinepos,
						contribution_margin: contributionMarginPercentage,
						salary: total_salary,
						salary_percentage: salary_ratio,
						beverageRatio: 0,
						revenue_by_category: values[0].data.revenue_by_category,
						
					}))

					setIsLoading(false);

				})
				.catch(error => {
					setWidgetState(prevState => ({ 
						...prevState,
						totalRevenueExclVAT: 0,
						totalRevenueInclVAT: 0,
						totalCost: 0,
						orderCount: 0,
						revenue_by_category: [],
						salary: 0,
						salary_percentage: 0,
					}));

					setIsLoading(true);
					console.log(error);
				}
			);

		}

		loadAllData();

	}, [
		getAccessTokenSilently,
		props.start_date,
		props.end_date,
		props.compared_start_date,
		props.compared_end_date,
		props.department_ids
	]);


	/**
	 * Claculate beverage ration, whenever theres changes to revenue by category
	 */
	useEffect(() => {
		const category_objects = widgetState.revenue_by_category;

		let totalBeverages = category_objects.reduce(function(accumulator, currentValue) {
			
			if (currentValue.category === 'Varme Drikke' || currentValue.category === 'Varme Drikke TO GO' || currentValue.category === 'Kolde Drikke' || currentValue.category === 'Kolde Drikke TO GO') {
				return accumulator + currentValue.item_count;	
			} else {
				return accumulator;
			}
		}, 0);


		let totalFood = category_objects.reduce(function(accumulator, currentValue) {
			if (currentValue.category === 'Morgen' || currentValue.category === 'Morgen TO GO' || currentValue.category === 'Frokost/Aften' || currentValue.category === 'Frokost/Aften TO GO' ) {
				return accumulator + currentValue.item_count;	
			} else {
				return accumulator;
			}
		}, 0);


		let beverageRatio = (totalBeverages / totalFood) * 100
		
		beverageRatio = !isNaN(beverageRatio) ? parseInt(beverageRatio) : 100;

		setWidgetState(prevState => ({
			...prevState,
			// totalBeverages: totalBeverages,
			// totalFood: totalFood,
			beverageRatio: beverageRatio,
		}));

	}, [widgetState.revenue_by_category]);

	return (
		<div className="widget-main-stats">
			{
				isLoading ? (
					<div className="main-stats">
						<div className="spinner-height-wrapper">
							<div className="spinner-centered spinner--white">
								<div className="spinner"></div>
							</div>
						</div>
					</div>
				) : (

					<div className="main-stats">
					
						<div className="primary-stats">

							<div className="stat-line-primary">
								<div className="stat-line-label">Omsætning</div>
								<div className="stat-line-value">{widgetState.totalRevenue.toLocaleString("da-DK")} kr.</div>
							</div>

						</div>

						<div className="secondary-stats">
							<div className="stat-line-secondary">
								<div className="stat-line-label">Antal ordrer</div>
								<div className="stat-line-value">{widgetState.orderCount}</div>
							</div>

							<div className="stat-line-secondary">
								<div className="stat-line-label">Basket Size</div>
								<div className="stat-line-value">{widgetState.basket_size.toFixed(2)} kr.</div>
							</div>

							<div className="stat-line-secondary">
								<div className="stat-line-label">Vareforbrug (DB)</div>
								<div className="stat-line-value">{widgetState.cogs.toLocaleString("da-DK")} kr. ({widgetState.contribution_margin}%)</div>
							</div>

							<div className="stat-line-secondary">
								<div className="stat-line-label">Løn (%)</div>
								<div className="stat-line-value">{widgetState.salary.toLocaleString("da-DK")} ({widgetState.salary_percentage}%)</div>
							</div>

							<div className="stat-line-secondary">
								<div className="stat-line-label">Drikkevareprocent</div>
								<div className="stat-line-value">{widgetState.beverageRatio}%</div>
							</div>
						</div>
						

					</div>
				)
			}

		</div>		
	); 
};

export default MainStats;
