import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';

import Select from 'react-select';
import { ResponsiveLine } from '@nivo/line';


export const TimelineDiff = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [timelineState, setTimelineState] = useState({
		error: false,
		diffs: [],
	});

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);


	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		const diffs = loadDiffs();

		Promise.all([diffs])
			.then( values => {

				setTimelineState({ 
					...timelineState,
					diffs: values[0].data.diffs,

				});

				setIsLoading(false);
			})
			.catch(error => {
				setTimelineState({ 
					...timelineState,
					diffs: [],
				})
				console.log(error);
			}
		);

	}

	/**
	 * Prepare the sales data for the timeline
	 */
	const prepareData = () => {

		let start_date = moment(props.start_date);
		let end_date = moment(props.end_date);
		const diff_data = timelineState.diffs;

		const date_diff = end_date.diff(start_date, 'days');
		
		if (date_diff < 6) {
			start_date = moment(props.end_date).subtract(6, 'd');
		}


		// Setup data feed
		let data = [];

		let temp_date = new Date(start_date);
		while(temp_date <= end_date) {

			data.push({
				x: moment( temp_date ).format('YYYY-MM-DD'),
				y: 0
			});

			temp_date.setDate(temp_date.getDate() + 1);
		}


		// Add diff values
		for(const diff of diff_data) {

			// Get comparable date string 
			const date_string = moment(diff.end_date).format('YYYY-MM-DD');

			// Find index for current date
			const x_index = data.findIndex( date => date.x === date_string );

			// If the number exists, sum the revenue
			if (x_index !== -1) {
				data[x_index].y += diff.diff_value;
			}
		}

		// Format cents to dollar (kroner)
		const formatted_data = data.map( date => { return { x: moment(date.x).format('dddd DD/MM'), y: parseFloat(date.y / 100).toFixed(2) } });


		let line_data = [{
			"id": "Diff",
			"color": "hsl(223, 70%, 54%)",
			"data": formatted_data
		}];

		return line_data;
	}


	/**
	 * Load sales data
	 */
	const loadDiffs = async () => {

		let start_date = moment(props.start_date);
		let end_date = moment(props.end_date);
		
		const date_diff = end_date.diff(start_date, 'days');
		
		if (date_diff < 7) {
			start_date = moment(props.end_date).subtract(7, 'd');
		}

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/sales/diff/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
				start_date: start_date.format('YYYY-MM-DD 00:00'),
				end_date: end_date.format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;
	}

	const diff_data = prepareData()[0].data;

	//find Biggest possitive value.
	const highest = Math.abs( diff_data.sort((a, b) => b.y - a.y)[0].y );
	const lowest = Math.abs( diff_data.sort((a, b) => a.y - b.y)[0].y );
	const max = Math.max(highest, lowest, 500);
	//console.log(max);

	const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
	  <g>
	    <circle fill="#fff" r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
	    <circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
	  </g>
	);


	return (
		<div className="widget-v2">
	        <div className="widget-v2-header">
	        				      
      			<Select 
              		className="timeline-selector" 
              		options={props.select.options} 
              		value={props.select.value}
              		onChange={props.select.onChange}
              	/>

	        </div>
			
			<div className="widget-v2-body">
					<div className="responsive-line-container">
						
							{
								isLoading === true ? (
									<div className="spinner-centered">
										<div className="spinner"></div>
									</div>
								) : (
									<ResponsiveLine
										data={prepareData()}
										colors={d => d.color}
										margin={{ top: 50, right: 50, bottom: 70, left: 90 }}
										xScale={{ type: "point"}}
										yScale={{ type: 'linear', stacked: false, min: - max, max: max }}
										x-curve="monotoneX"
										axisTop={null}
										axisRight={null}
										axisBottom={{
											orient: "bottom",
											tickSize: 5,
											tickPadding: 5,
											tickRotation: -40,
											legend: "",
											legendOffset: 36,
											legendPosition: "middle"
										}}
										axisLeft={{
											orient: "left",
											tickSize: 5,
											tickPadding: 5,
											tickRotation: 0,
											legend: "",
											legendOffset: -40,
											legendPosition: "middle",
											format: value => `${Number(value).toLocaleString('da-DK')}`,
										}}

										yFormat={value => {
											return `${Number(value).toLocaleString('da-DK')}`;
										}}

						      	    	enablePointLabel={true}
						      	    	pointSize={6}
						      	    	pointBorderWidth={1}
						      	    	pointBorderColor={{
											from: 'color',
											modifiers: [['darker', 0.3]],
										}}
										pointLabelYOffset={-10}
										enableArea={true}			        
										areaOpacity={0.07}
										useMesh={false}
										enableSlices='x'		
										animate={true}
										motionStiffness={300}
										motionDamping={30}
										pointSymbol={CustomSymbol}
										pointSize={16}
									/>
								)

							}
						
					</div>	
					<div className="widget-v2-body-legends">
        				<div className="widget-v2-body-legend" style={{color: 'hsl(223, 70%, 54%)'}}>
			            	<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(223, 70%, 54%)'}}></div>
			            	<div className="widget-v2-body-legend-title">Kassedifference</div>
			            </div>
		            </div>

			</div>						        


	        <div className="widget-v2-footer">
	        	&nbsp;
	        	<div className="widget-timeline-v2-footer-settings">
		        		
	        	</div>
	        </div>

	    </div>	
	); 
};

export default TimelineDiff;