import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment';
import axios from 'axios';
import { Link } from "react-router-dom";
import Select, { components } from "react-select";
import { ResponsiveLine } from '@nivo/line';

import { Spinner } from "../../Spinner";

const { SingleValue, Option } = components;

const IconValue = props => {
	return (
	  <SingleValue {...props}>
	 	<div className="widget-3-select-single-value-with-icon">
		    <div className="widget-v3-select-icon">
		    	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve">
					<path d="M426.23 492.25H90.27c-42.46 0-76.99-34.53-76.99-76.99V101.71c0-42.45 34.53-76.99 76.99-76.99h335.96c42.46 0 76.99 34.54 76.99 76.99v313.55c0 42.46-34.53 76.99-76.99 76.99zM90.27 66.71c-19.3 0-35 15.69-35 35v313.55c0 19.3 15.69 35 35 35h335.96c19.3 0 35-15.69 35-35V101.71c0-19.3-15.69-35-35-35H90.27z"/>
		    		<path d="M121.62 382.69c-2.16 0-4.37-.33-6.53-1.04-11.03-3.61-17.03-15.47-13.42-26.49l55.06-167.98c2.76-8.39 10.46-14.18 19.29-14.45 8.46-.78 16.88 4.99 20.16 13.19l36.5 91.23 36.5-91.23a20.981 20.981 0 0 1 18.62-13.19c8.87-.07 15.97 4.2 19.66 11.59l35.28 70.57 28.22-69.12c4.37-10.72 16.64-15.94 27.38-11.5 10.73 4.39 15.88 16.64 11.5 27.38L364.1 313.63a20.972 20.972 0 0 1-18.65 13.04c-8.2.85-15.9-4.24-19.58-11.59l-35.08-70.16-38.63 96.57c-6.37 15.97-32.62 15.97-38.99 0l-34.54-86.34-37.06 113.08c-2.9 8.85-11.12 14.46-19.95 14.46z" />
		    	</svg>
		    </div>
		    <div className="widget-v3-select-label">
		    	{props.data.label}
			</div>    
		</div>
	  </SingleValue>
	)
};

const TagValue = props => {
	return (
	  <SingleValue {...props}>
	 	
	 	{
	 		props.data.tag ? (
			 	<div className="widget-3-select-single-value-with-tag">
		    		<div className="widget-v3-select-tag">
		    			{props.data.tag}
		    		</div>		    
				    <div className="widget-v3-select-label">
				    	{props.data.label}
					</div>    
				</div>

			) : (
			 	<div className="widget-3-select-single-value-with-tag">
	    		    <div className="widget-v3-select-icon">
	    		    	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
							<path d="M298.82 501c-4.4 0-8.74-1.01-12.77-2.98-7.03-3.44-12.28-9.39-14.79-16.77l-87.21-249.48a38.006 38.006 0 0 1-.12-24.37c3.15-9.47 9.87-17.24 18.88-21.74 8.99-4.48 19.25-5.25 28.78-2.04l248.97 82.98a29.07 29.07 0 0 1 18.82 19.66c4.4 15.48-4.59 31.61-19.98 36.04l-116.91 33.3-35.63 124.25c-2.66 9.12-9.5 16.39-18.39 19.5-3.18 1.1-6.42 1.65-9.65 1.65zm16.71-35.32.1.26c-.04-.08-.07-.18-.1-.26zm-80.94-231.6 62.58 179.07 27.38-95.57 86.63-24.66-176.59-58.84zm231.09 77.03c.04 0 .06.01.1.01l-.1-.01zm-237.3-94.75.12.37c-.02-.13-.06-.26-.12-.37zM85.81 347.87c-6.02 0-12.01-2.29-16.6-6.87-9.17-9.17-9.17-24.01 0-33.18l40.28-40.28c9.17-9.17 24.01-9.17 33.18 0 9.17 9.17 9.17 24.01 0 33.18L102.4 341a23.38 23.38 0 0 1-16.59 6.87zm6.08-118.59H34.97c-12.96 0-23.47-10.51-23.47-23.47s10.51-23.47 23.47-23.47H91.9c12.96 0 23.47 10.51 23.47 23.47s-10.52 23.47-23.48 23.47zm203.93-78.94c-5.99 0-12.01-2.29-16.6-6.86-9.17-9.15-9.17-24.01 0-33.18L319.47 70c9.17-9.18 24.01-9.18 33.18-.04s9.17 24.01 0 33.18l-40.24 40.3c-4.58 4.61-10.57 6.9-16.59 6.9zm-168.95 0c-6.02 0-12.01-2.29-16.6-6.87L70 103.17c-9.17-9.17-9.17-24.04 0-33.2 9.2-9.15 24.05-9.14 33.18.01l40.28 40.3c9.17 9.17 9.17 24.04 0 33.2-4.4 4.4-10.37 6.87-16.59 6.86zm78.96-34.98c-12.96 0-23.47-10.51-23.47-23.47V34.95c0-12.96 10.51-23.47 23.47-23.47 12.96 0 23.47 10.51 23.47 23.47v56.94c0 12.96-10.51 23.47-23.47 23.47z" />
						</svg>
	    		    </div>
		    		
				    <div className="widget-v3-select-label">
				    	{props.data.label}
					</div>    
				</div>
			)
		}

	  </SingleValue>
	)
};

const TagOption = props => {
	
	let selectedClass = ''

	if(props.isSelected) {
		selectedClass = 'widget-v3-select-option-is-selected';
	}

	return (
	  <Option {...props}>
	 	 	{
	 	 		props.data.tag ? (
	 			 	<div className={`widget-3-select-single-value-with-tag ${selectedClass}`}>
	 		    		<div className="widget-v3-select-tag">
	 		    			{props.data.tag}
	 		    		</div>		    
	 				    <div className="widget-v3-select-label">
	 				    	{props.data.label}
	 					</div>    
	 				</div>

	 			) : (
	 			 	<div className={`widget-3-select-single-value-with-tag ${selectedClass}`}>
	 	    		    <div className="widget-v3-select-icon">
	 	    		    	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
	 							<path d="M298.82 501c-4.4 0-8.74-1.01-12.77-2.98-7.03-3.44-12.28-9.39-14.79-16.77l-87.21-249.48a38.006 38.006 0 0 1-.12-24.37c3.15-9.47 9.87-17.24 18.88-21.74 8.99-4.48 19.25-5.25 28.78-2.04l248.97 82.98a29.07 29.07 0 0 1 18.82 19.66c4.4 15.48-4.59 31.61-19.98 36.04l-116.91 33.3-35.63 124.25c-2.66 9.12-9.5 16.39-18.39 19.5-3.18 1.1-6.42 1.65-9.65 1.65zm16.71-35.32.1.26c-.04-.08-.07-.18-.1-.26zm-80.94-231.6 62.58 179.07 27.38-95.57 86.63-24.66-176.59-58.84zm231.09 77.03c.04 0 .06.01.1.01l-.1-.01zm-237.3-94.75.12.37c-.02-.13-.06-.26-.12-.37zM85.81 347.87c-6.02 0-12.01-2.29-16.6-6.87-9.17-9.17-9.17-24.01 0-33.18l40.28-40.28c9.17-9.17 24.01-9.17 33.18 0 9.17 9.17 9.17 24.01 0 33.18L102.4 341a23.38 23.38 0 0 1-16.59 6.87zm6.08-118.59H34.97c-12.96 0-23.47-10.51-23.47-23.47s10.51-23.47 23.47-23.47H91.9c12.96 0 23.47 10.51 23.47 23.47s-10.52 23.47-23.48 23.47zm203.93-78.94c-5.99 0-12.01-2.29-16.6-6.86-9.17-9.15-9.17-24.01 0-33.18L319.47 70c9.17-9.18 24.01-9.18 33.18-.04s9.17 24.01 0 33.18l-40.24 40.3c-4.58 4.61-10.57 6.9-16.59 6.9zm-168.95 0c-6.02 0-12.01-2.29-16.6-6.87L70 103.17c-9.17-9.17-9.17-24.04 0-33.2 9.2-9.15 24.05-9.14 33.18.01l40.28 40.3c9.17 9.17 9.17 24.04 0 33.2-4.4 4.4-10.37 6.87-16.59 6.86zm78.96-34.98c-12.96 0-23.47-10.51-23.47-23.47V34.95c0-12.96 10.51-23.47 23.47-23.47 12.96 0 23.47 10.51 23.47 23.47v56.94c0 12.96-10.51 23.47-23.47 23.47z" />
	 						</svg>
	 	    		    </div>
	 		    		
	 				    <div className="widget-v3-select-label">
	 				    	{props.data.label}
	 					</div>    
	 				</div>
	 			)
	 		}

	  </Option>
	)
};


/**
 * Load profiles event Count
 */
const getProfilesEventCount = async (access_token, start_date = new Date(), end_date = new Date(), frequency = 'hour', source = false, event_name = false) => {

	let params = {
		start_date: moment(start_date).format('YYYY-MM-DD 00:00'),
		end_date: moment(end_date).format('YYYY-MM-DD 23:59'),
		frequency: frequency, // hour, day, month or year
	}

	if(source && event_name) {
		params.source = source;
		params.event_name = event_name;
	}

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profile-events-count/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: params
	});

	return axiosPromise;		
}

/**
 * Load profiles event types
 */
const getProfilesEventTypes = async (access_token, start_date = new Date(), end_date = new Date(), frequency = 'hour') => {

	// Get departments data
	const axiosPromise = await axios.get(`${process.env.REACT_APP_GROED_API_V2_URL}/profile-events-types/`, { 
		headers: { 'Authorization': `Bearer ${access_token}`},
		params: {
		    start_date: moment(start_date).format('YYYY-MM-DD 00:00'),
		    end_date: moment(end_date).format('YYYY-MM-DD 23:59'),
		}
	});

	return axiosPromise;		
}

export const ProfilesEventTimeline = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_V2_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Widget state
	const [widgetState, setWidgetState] = useState('done'); // loading, error, done

	// Profiles Events data
	const [profilesEventsData, setProfilesEventsData] = useState([]);
	const [profilesEventTypes, setProfilesEventTypes] = useState([]);

	// Line chart data
	const [lineChartData, setLineChartData] = useState([]);

	// Source Filter
	const [sourceFilter, setSourceFilter] = useState('All');
	const [sourceOptions, setSourceOptions] = useState(['All']);

	// Frequency options and defaults
	const [selectedFrequency, setSelectedFrequency] = useState({ value: 'hour', label: 'Hour' });
	const [frequencyOptions, setFrequencyOptions] = useState([]);

	// Frequency options and defaults
	const [selectedEventType, setSelectedEventType] = useState({ value: 'All events', label: 'All Events', tag: '' });
	const [eventTypeOptions, setEventTypeOptions] = useState([]);

	/**
	 * Get data from DB
	 */
	useEffect(() => {

		const loadAllData = async () => {

			setWidgetState('loading');

			// Get access token
			const access_token = await getAccessTokenSilently();

			let source = '';
			let event_name = '';

			if(selectedEventType !== '') {
				source = selectedEventType.tag;
				event_name = selectedEventType.label;
			}

			const profilesEventsCount = getProfilesEventCount(access_token, props.start_date, props.end_date, selectedFrequency.value, source, event_name);
			
			const profilesEventTypes = getProfilesEventTypes(access_token, props.start_date, props.end_date, selectedFrequency.value);
			

			Promise.all([profilesEventsCount, profilesEventTypes])
				.then( values => {

					const profilesEventsCount = values[0].data.data;
					const profilesEventTypes = values[1].data.data;
					
					setProfilesEventsData(profilesEventsCount);

					setProfilesEventTypes(profilesEventTypes);
					
					setWidgetState('done');

				})
				.catch(error => {
					setProfilesEventsData([]);
					setWidgetState('error');
					console.log(error);
				});

		}

		loadAllData();	

	}, [
		getAccessTokenSilently,
		selectedFrequency,
		selectedEventType,
		props.start_date,
		props.end_date,
	]);


	/**
	 * Setup sourceFilter
	 */
	useEffect(() => {
		let options = profilesEventsData.map(dataset => {
			return dataset.platform;
		});
		
		options = ['All', ...options];
		
		setSourceOptions(options);
		
	}, [
		profilesEventsData,
	]);

	/**
	 * Setup Event types select
	 */
	useEffect(() => {
		
		console.log(profilesEventTypes);

		let options = profilesEventTypes.map(dataset => {
			return { 
				value: dataset.event_name, 
				label: dataset.event_name, 
				tag: dataset.source 
			}
		});
		
		options = [
			{ value: 'All events', label: 'All Events', tag: '' },
			...options
		];
		
		setEventTypeOptions(options);
		
	}, [
		profilesEventTypes,
	]);

	/**
	 * Setup linedata
	 */
	useEffect(() => {
		
		// Setup defaults
		let timeline = getDefaultTimelineData();

		const frequency = selectedFrequency.value;

		let raw_data = profilesEventsData;

		// Empty array with data entries
		let data = [];

		// If we're filtering, only grab that data.
		if(sourceFilter !== 'All') {
			raw_data = raw_data.filter(data_set => data_set.platform === sourceFilter);
		}

		// Otherwise cobine all data entries
		for(const platform of raw_data) {
			data = [...data, ...platform.data];
		}


		// Add data to timeline
		data.map(data_entry => {

			// formats for frequency
			let date_string = moment(data_entry.date).format('HH.00');
			if (frequency === 'day') { date_string = moment(data_entry.date).format('YYYY-MM-DD'); }
			if (frequency === 'week') {date_string = moment(data_entry.date, 'YYYY-W').format('W'); }
			if (frequency === 'month') { date_string = moment(data_entry.date).format('YYYY-MM'); }
			if (frequency === 'year') { date_string = moment(data_entry.date).format('YYYY-MM'); }

			// Find index for current frequency
			const x_index = timeline.findIndex( date => date.x === date_string );
			
			// If the number exists, sum the revenue
			if (x_index !== -1) {
				timeline[x_index].y +=	data_entry.count;
			}								
		})

		let line_data = {
			"id": selectedEventType.label,
			"color": "hsl(223, 70%, 54%)",
			"data": timeline,
		}


		setLineChartData([line_data])
		
	}, [
		profilesEventsData, 
		sourceFilter,
		selectedFrequency,
	]);

	/**
	 * Set frequency options
	 */
	useEffect(() => {
		updateFrequencyOptions();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	const updateFrequencyOptions = () => {

		const start_date = moment(props.start_date);
		const end_date = moment(props.end_date);

		let f_options = [];
		let f_selected = '';

		// Check if hour should be an option
		if (start_date.format('YYYY-MM-DD') === end_date.format('YYYY-MM-DD')) {
			f_options.push({ value: 'hour', label: 'Hour' });
			f_options.push({ value: 'day', label: 'Day' });
			f_selected = 'hour';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'days') >= 1 && end_date.diff(start_date, 'days') <= 31 ) {
			f_options.push({ value: 'day', label: 'Day' });
			f_options.push({ value: 'week', label: 'Week' });
			f_options.push({ value: 'month', label: 'Month' });
			f_selected = 'day';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'months') >= 1 && end_date.diff(start_date, 'months') < 24) {
			f_options.push({ value: 'day', label: 'Day' });
			f_options.push({ value: 'week', label: 'Week' });
			f_options.push({ value: 'month', label: 'Month' });
			f_options.push({ value: 'year', label: 'Year' });
			f_selected = 'month';
		}

		// Check if day should be an option
		else if (end_date.diff(start_date, 'months') >= 24) {
			f_options.push({ value: 'day', label: 'Day' });
			f_options.push({ value: 'week', label: 'Week' });
			f_options.push({ value: 'month', label: 'Month' });
			f_options.push({ value: 'year', label: 'Year' });
			f_selected = 'year';
		}

		setFrequencyOptions(f_options);

		let currentSelectionExists = false;
		if (selectedFrequency && selectedFrequency.value) {
			currentSelectionExists = f_options.find(option => option.value === selectedFrequency.value);	
		}

		if (!currentSelectionExists) {
			setSelectedFrequency(f_options.find(option => option.value === f_selected));	
		}

	}

	/** 
	 * Setup default data with no actual input.
	 */
	const getDefaultTimelineData = () => {

		const frequency = selectedFrequency.value;
		const start_date = props.start_date;
		const end_date = props.end_date;
		
		// Hourly
		let default_hourly = [];
		default_hourly = [
			{ x: '00.00', y: 0 },
			{ x: '01.00', y: 0 },
			{ x: '02.00', y: 0 },
			{ x: '03.00', y: 0 },
			{ x: '04.00', y: 0 },
			{ x: '05.00', y: 0 },
			{ x: '06.00', y: 0 },
			{ x: '07.00', y: 0 },
			{ x: '08.00', y: 0 },
			{ x: '09.00', y: 0 },
			{ x: '10.00', y: 0 },
			{ x: '11.00', y: 0 },
			{ x: '12.00', y: 0 },
			{ x: '13.00', y: 0 },
			{ x: '14.00', y: 0 },
			{ x: '15.00', y: 0 },
			{ x: '16.00', y: 0 },
			{ x: '17.00', y: 0 },
			{ x: '18.00', y: 0 },
			{ x: '19.00', y: 0 },
			{ x: '20.00', y: 0 },
			{ x: '21.00', y: 0 },
			{ x: '22.00', y: 0 },
			{ x: '23.00', y: 0 },
		];

		if (frequency === 'hour') {

			return default_hourly;
		}


		else if (frequency === 'day') {
			
			// Daily
			let default_daily = [];
			let daily_temp_date = moment(start_date);
			
			while(daily_temp_date.format('YYYY-MM-DD') <= moment(end_date).format('YYYY-MM-DD')) {

				default_daily.push({
					x: daily_temp_date.format('YYYY-MM-DD'),
					y: 0,
				});

				daily_temp_date.add(1, 'days');
			}

			return default_daily;

		}

		else if (frequency === 'week') {
			
			// Weekly
			let default_weekly = [];
			let weekly_temp_date = moment(start_date);

			while(weekly_temp_date.format('YYYY-WW') <= moment(end_date).format('YYYY-WW')) {

				default_weekly.push({
					x: weekly_temp_date.format('W'),
					y: 0,
				});

				weekly_temp_date.add(1, 'weeks');
			}

			return default_weekly;

		}

		else if (frequency === 'month') {
			
			// Monthly
			let default_monthly = [];
			let monthly_temp_date = moment(start_date);
			
			while(monthly_temp_date.format('YYYY-MM') <= moment(end_date).format('YYYY-MM')) {

				default_monthly.push({
					x: moment( monthly_temp_date ).format('YYYY-MM'),
					y: 0
				});

				monthly_temp_date.month(monthly_temp_date.month() + 1);
			}
			return default_monthly;
		}

		else if (frequency === 'year') {
			
			// Yearly
			let default_yearly = [];
			let yearly_temp_date = moment(start_date);
			
			while(yearly_temp_date.format('YYYY') <= moment(end_date).format('YYYY')) {

				default_yearly.push({
					x: moment( yearly_temp_date ).format('YYYY'),
					y: 0
				});

				yearly_temp_date.year(yearly_temp_date.year() + 1);
			}

			return default_yearly;
		} else {
			return default_hourly;
		}

	}


	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setSourceFilter(new_mode);
	}

	// Handle frequency change
	const handleFrequencyChange = (selectedFrequency) => {
		setSelectedFrequency(selectedFrequency);
	}

	// Handle frequency change
	const handleEventTypeChange = (selectedEventType) => {
		setSelectedEventType(selectedEventType);
	}


	let stateClass = "widget--loading";

	if (widgetState === 'loading') {
		stateClass = "widget--loading";			
	} else {
		stateClass = "";
	}


	return (
		<div className="widget-v3-container">
		    <div className={`widget-v3 ${stateClass}`}>
		        <div className="widget-v3-header">
		        	
		        	<div className="widget-v3-header-title-group">
		        		<h2 className="widget-v3-title">Profile Events</h2>
		        		<div className="widget-v3-subtitle">Events over time</div>
		        	</div>


		        	<div className="widget-v3-header-actions">
			        	<div className="widget-v3-header-action">
				        	<Select 
				        		className="widget-v3-action-extra-extra-large" 
				        		options={eventTypeOptions} 
				        		value={selectedEventType}
				        		onChange={handleEventTypeChange}
				        		components={{
				        		    IndicatorSeparator: () => null,
				        		    SingleValue: TagValue,
				        		    Option: TagOption
				        		}}
				        	/>
				        </div>
				        <div className="widget-v3-header-action">
				        	<Select 
				        		className="widget-v3-action-medium" 
				        		options={frequencyOptions} 
				        		value={selectedFrequency}
				        		onChange={handleFrequencyChange}
				        		components={{
				        		    IndicatorSeparator: () => null,
				        		    SingleValue: IconValue
				        		}}
				        	/>
				        </div>
		        	</div>
		        </div>
				
				<div className="widget-v3-body widget-v3-body--profiles-event-timeline">
						
					{
						widgetState === 'loading' && (
							<div className="rene-spinner-centered">
								<Spinner />
							</div>
						) 
					}

					{
						widgetState === 'error' && (
							<div className="widget-v3-error">
								Couldn't load data
							</div>
						) 
					}

					{
						widgetState === 'done' && (

							<div className="widget-v3-responsive-line-container">
								<ResponsiveLine
									data={lineChartData}
									colors={d => d.color}
									margin={{ top: 40, right: 30, bottom: 70, left: 65 }}
									xScale={{ type: "point"}}
									yScale={{ type: "linear", stacked: false, min: 0, max: "auto" }}
									curve="monotoneX"
									axisTop={null}
									axisRight={null}
									axisBottom={{
										orient: "bottom",
										tickSize: 5,
										tickPadding: 5,
										tickRotation: -40,
										legend: "",
										legendOffset: 36,
										legendPosition: "middle"
									}}
									axisLeft={{
										orient: "left",
										tickSize: 5,
										tickPadding: 5,
										tickRotation: 0,
										legend: "",
										legendOffset: -40,
										legendPosition: "middle",
										format: value => `${Number(value).toLocaleString('da-DK')}`,
									}}

									yFormat={value => {
										if (props.salaryPercentageMode) {
											return `${value}%`;
										} else {
											return `${Number(value).toLocaleString('da-DK')}`;
										}
										
									}}

					      	    	enablePointLabel={true}
					      	    	pointSize={6}
					      	    	pointBorderWidth={1}
					      	    	pointBorderColor={{
										from: 'color',
										modifiers: [['darker', 0.3]],
									}}
									pointLabelYOffset={-10}
									enableArea={true}			        
									areaOpacity={0.07}
									useMesh={false}
									enableSlices='x'		
									animate={true}
									motionStiffness={300}
									motionDamping={30}
									enableGridX={true}
									enableGridY={true}
								/>	
							</div>

						)
					}
				</div>

			      
			        <div className="widget-v3-footer">
						{
							widgetState === 'loading' && (
								<ul className="widget-v3-footer-filters">	
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
									<li className="widget-v3-footer-filter">
										<div className="widget-v3-footer-filter-skeleton"></div>
									</li>
					        	</ul>
							)
						}

						{
							widgetState === 'error' && (
								<ul className="widget-v3-footer-filters">
									<li className="widget-v3-footer-filter">&nbsp;</li>
								</ul>
							) 
						}

						{
							widgetState === 'done' && (
								<ul className="widget-v3-footer-filters">	
									{

										sourceOptions.map((source, i) => {

											let activeClass = '';
											if (sourceFilter === source) {
												activeClass = 'widget-footer-filter--active';
											}

											return (
												<li key={i} className={`widget-v3-footer-filter ${activeClass}`} onClick={() => changeSourceFilter(source)}>{source}</li>
											)
										})
									}

					        	</ul>
							) 
						}


						{
							widgetState === 'done' && (
					        	<div className="widget-v3-footer-settings">
						        	
						        	<div className="widget-v3-footer-settings-three-dots">
						        		<svg
						        		    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" xmlSpace="preserve" >
						        		    <circle cx={393.29} cy={256} r={37.44} /><circle cx={256} cy={256} r={37.44} /><circle cx={118.71} cy={256} r={37.44} />
						        		</svg>	
						        	</div>
						        	
					        	</div>
							) 
						}

			        </div>
		        

		    </div>
		</div>	
	); 
};

export default ProfilesEventTimeline;