import React, {useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import moment from 'moment';

import Loading from "../../components/Loading";
import Navigation from "../../components/Navigation";
import BrandBar from "../../components/BrandBar";

// Widgets
import ProfilesKPIs from "../../components/widgets/profiles/ProfilesKPIs";
import ProfilesPerPlatform from "../../components/widgets/profiles/ProfilesPerPlatform";
import ProfilesAttribution from "../../components/widgets/profiles/ProfilesAttribution";
import ProfilesGender from "../../components/widgets/profiles/ProfilesGender";
import ProfilesAgeRange from "../../components/widgets/profiles/ProfilesAgeRange";
import ProfilesTableTop10 from "../../components/widgets/profiles/ProfilesTableTop10";
import ProfilesLifetimeSpendRange from "../../components/widgets/profiles/ProfilesLifetimeSpendRange";
import ProfilesLifetimeOrderRange from "../../components/widgets/profiles/ProfilesLifetimeOrderRange";



export const ProfileInsights = (props) => {

	return (
		<div className="app-container">
			<div className="app-sidebar">
				<BrandBar/>
				<Navigation page_title="Profiles"/>
			</div>
			
			<div className="app-content">
				<div className="page-content">

					<div className="page-content-inner">
						<div className="page-content-inner-centering--wide">

							<div className="page-content-header">
								
								<div className="page-content-header-titel-block">
									<h1 className="page-title">Profile Insights</h1>

									<div className="page-description">
										<p>Profiles represent profile-data on people who interacted with your brand in a way that collected their data</p>
									</div>
								</div>

							</div>
							


							<div className="page-charts">

								<ProfilesKPIs />

								<ProfilesPerPlatform />

								<ProfilesAttribution />

								<ProfilesTableTop10 />

								<ProfilesLifetimeSpendRange />

								<ProfilesLifetimeOrderRange />

								<ProfilesGender />

								<ProfilesAgeRange />

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	); 
};

export default withAuthenticationRequired(ProfileInsights, {
  onRedirecting: () => <Loading />,
});
