import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const SimpleBars = (props) => {

	// Widget state
	const [simpleBarsState, setSimpleBarsState] = useState({
		total: 100,
		legends: [],
		data: [],
	}); // loading, error, done


	/**
	 * Format data
	 */
	useEffect(() => {
		formatLegends(props.data);
		
		setSimpleBarsState(prevState => ({
			...prevState,
			data: props.data			
		}))

	}, [
		props.data
	]);

	
	/**
	 * Format legend data
	 */
	const formatLegends = (data) =>  {
		
		let legends = [];		
		
		data.map(dataset => {
			dataset.data.map(entry => {

				const exists = legends.findIndex(legend => legend.label === entry.data_name);
				if(exists === -1) {
					legends.push({
						label: entry.data_name,
						color: entry.color,
					})
				}
			})
		})

		setSimpleBarsState(prevState => ({
			...prevState,
			legends: legends,
			
		}))
	}	


	return (
	<div>
		{
			props.data.length == 0 && (
				<div className="widget-v3-error">
					<div>No data</div>
				</div>
			)
		}

		{
			props.data.length > 0 && (
				<div className="simple-bars"> {/* <table> */}
					<div className="simple-bars-list"> {/* <tbody> */}

						{ 
							simpleBarsState.data.map((bar, i) => {
								const percentage = bar.value / bar.total * 100;

								return (
									<div key={i} className="simple-bars-list-item" title={`${bar.label} (${bar.value})`}> {/* <tr> */}
										
										<div className="simple-bars-list-item-label"> {/* <td> */}
											<div className="simple-bars-list-item-label-group">
												{
													bar.tag && (
														<div className="simple-bars-list-item-label-tag">{bar.tag}</div>
													)
												}
												
												<div className="simple-bars-list-item-label-title">{bar.label}</div>
											</div>
										</div> 
										<div className="simple-bars-list-item-bar"> {/* <td> */}
												
											<div className="simple-bars-list-item-bar-track">
												
												{
													bar.data.map((data_entry, i) => {
														const percentage = data_entry.value / bar.total * 100;

														return (
															<div key={i} className="simple-bars-list-item-bar-fill" style={{backgroundColor: data_entry.color, width: percentage + '%'}}></div>
														)
													})
												}

											</div>

										</div>
										<div className="simple-bars-list-item-value" >{/*bar.value*/}{percentage.toFixed()}%</div>{/* <td> */}
									</div>
								)
							})
						}
						
					</div>

					

						{
							props.showLegends && simpleBarsState.legends.length > 0 && (
								<div className="simple-bars-footer">
									<ul className="simple-bars-legends">
										{
										simpleBarsState.legends.map((legend, i) => {

											return (
												<li key={i} className="simple-bars-legend" style={{color: legend.color}}>
													<div className="simple-bars-legend-dot" style={{backgroundColor: legend.color}}></div>
													<div className="simple-bars-legend-label">{legend.label}</div>
												</li>
											)

										})

										}
									</ul>
								</div>
							)
						}

					

				</div>
			)
		}
</div>
		
	); 
};

export default SimpleBars;

