import React, {useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from 'axios';
import moment from 'moment';
import { ResponsivePie } from '@nivo/pie';

export const PieProductCategory = (props) => {

	// Store GRØD api URL
	const api_url = process.env.REACT_APP_GROED_API_URL;

	// Variables from Auth0
	const { getAccessTokenSilently } = useAuth0();

	// Loading state
	const [isLoading, setIsLoading] = useState(true);

	// Loading state
	const [widgetState, setWidgetState] = useState({
		error: false,
		revenue_by_category: [],
		revenue_by_category_orderyoyo: [],
		revenue_by_category_wolt: [],
		revenue_by_category_heaps: [],
		source_filter: 'onlinepos',
	});

	/**
	 * Loaddata on mount
	 */
	useEffect(() => {
		loadAllData();
	}, [
		props.start_date, 
		props.end_date, 
		props.department_ids
	]);

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeSourceFilter = (new_mode) =>  {
		setWidgetState({
			...widgetState,
			source_filter: new_mode
		});
	}

	/**
	 * Handle data load
	 */
	const loadAllData = () => {
		
		setIsLoading(true);

		const aggregatedRevenuePromise = loadAggregatedRevenue();

		Promise.all([aggregatedRevenuePromise])
			.then( values => {

				setWidgetState({ 
					...widgetState,
					revenue_by_category: values[0].data.revenue_by_category,
					revenue_by_category_orderyoyo: values[0].data.revenue_by_category_orderyoyo,
					revenue_by_category_wolt: values[0].data.revenue_by_category_wolt,
					revenue_by_category_heaps: values[0].data.revenue_by_category_heaps,
				});
				setIsLoading(false);
			})
			.catch(error => {
				setWidgetState({
					...widgetState,
					error: true,
					revenue_by_category: [],
					revenue_by_category_orderyoyo: [],
					revenue_by_category_wolt: [],
					revenue_by_category_heaps: [],
				});
				setIsLoading(false);
				console.log(error);
			}
		);

	}

	/**
	* Load sales data
	*/
	const loadAggregatedRevenue = async () => {

		// Get access token
		const access_token = await getAccessTokenSilently();

		// Get departments data
		const axiosPromise = await axios.get(`${api_url}/aggregation/revenue/`, { 
			headers: { 'Authorization': `Bearer ${access_token}`},
			params: {
				department_ids: props.department_ids,
                start_date: moment(props.start_date).format('YYYY-MM-DD 00:00'),
                end_date: moment(props.end_date).format('YYYY-MM-DD 23:59'),
				frequency: 'year', // hour, day, month or year
				// compared_start_date: moment(props.compared_start_date).format('YYYY-MM-DD 00:00'),
    			// compared_end_date: moment(props.compared_end_date).format('YYYY-MM-DD 23:59'),
			}
		});

		return axiosPromise;

	}


	/**
	 * Prepare the sales data for chart
	 */
	const prepareCategoryPieData = () => {

		// Get data from state
		let category_objects = [];
		if (widgetState.source_filter === 'onlinepos') {
			category_objects = widgetState.revenue_by_category;	
		} else if (widgetState.source_filter === 'orderyoyo') {
			category_objects = widgetState.revenue_by_category_orderyoyo;	
		} else if (widgetState.source_filter === 'wolt') {
			category_objects = widgetState.revenue_by_category_wolt;	
		} else if (widgetState.source_filter === 'heapsgo') {
			category_objects = widgetState.revenue_by_category_heaps;	
		}

		// setup main data variable
		let categories = [];

		// This is used to calculate percentage out of total
		let total_by_categories = 0;

		// Sum all revenue based on hour of sale
		for (const category_object of category_objects) {
			
			// Get the category id
			let category_name = category_object.category;

			// Combine to go and to stay categories by removeing " TO GO" from category names that has " TO GO" in it.
			category_name = category_name.replace(' TO GO', '');

			// Only include positive numbers in total. Discount lines fucks up percentages.
			if (category_object.revenue_excl_vat > 0) {
				total_by_categories += category_object.revenue_excl_vat;
			}

			// Find category index
			const cat_index = categories.findIndex(category => category.label === category_name );

			// If the number exists, sum the revenue
			if (cat_index !== -1) {
				categories[cat_index].value += category_object.revenue_excl_vat;
			} else {

				categories.push({
					"id": category_name,
					"label": category_name,
					"value": category_object.revenue_excl_vat,
					"color": '#aeaeae',
				})
			}
		}

		// Map colors
		const colors = ['#4D80FF', '#29D0A9', '#E33F3F', '#B44DFF', '#CBCB4E', '#CC8632', '#CBCB4E'];
		categories = categories.map((option, i) => {
			let colorIndex = (i % 6);
			option.color = colors[colorIndex];

			return option;
		})

		// Format cents to dollar (kroner)
		let formatted_categories = categories.map( category => { 
			category.value = parseInt(category.value/100);
			category.pie_total = parseInt(total_by_categories / 100);;
			return category;
		});

		// Remove categories with 0 revenue
		formatted_categories = formatted_categories.filter(category => category.value > 0 );

		if (formatted_categories.length === 0) {
			formatted_categories = [{ id: 'No Data', label: 'No Data', value: 100, "color": '#aeaeae' }];
		}

		return formatted_categories;

	}

	let loadingClass = "widget--loading";

	if (isLoading) {
		loadingClass = "widget--loading";			
	} else {
		loadingClass = "";
	}

	return (
		<div className={`widget-v2 ${loadingClass}`}>
	        
	        <div className="widget-v2-header">
	        	<h2 className="widget-v2-title">Omsætning per kategori</h2>	
	        </div>
			
			<div className="widget-v2-body">
					{
						widgetState.error && (
							<p className="responsive-line-error">Wups! Der skete en fejl! Send Malthe et screenshot ❤️</p>
						)
					}			
						
					{
						isLoading === true ? (
							<div className="responsive-line-container">
								<div className="spinner-centered">
									<div className="spinner"></div>
								</div>
							</div>
						) : (
							<div className="pie-container">
								<ResponsivePie
							        data={prepareCategoryPieData()}
							        margin={{
							            "top": 40,
							            "right": 180,
							            "bottom": 80,
							            "left": 80
							        }}
							        innerRadius={0.5}
							        padAngle={0.7}
							        cornerRadius={0}
							        x-colors={['hsl(223, 70%, 60%)', 'hsl(223, 70%, 65%)', 'hsl(223, 70%, 70%)', 'hsl(223, 70%, 75%)', 'hsl(223, 70%, 80%)', 'hsl(223, 70%, 85%)', 'hsl(223, 70%, 90%)']}
							        colors={d => d.color}
							        borderWidth={1}
							        borderColor="inherit:darker(0.2)"
							        radialLabelsSkipAngle={10}
					                radialLabelsTextXOffset={6}
					                radialLabelsTextColor="#333333"
					                radialLabelsLinkOffset={0}
					                radialLabelsLinkDiagonalLength={16}
					                radialLabelsLinkHorizontalLength={24}
					                radialLabelsLinkStrokeWidth={1}
					                radialLabelsLinkColor={{ from: 'color' }}
					                slicesLabelsSkipAngle={10}
					                slicesLabelsTextColor="#333333"
							        tooltipFormat={value =>`${Number(value).toLocaleString('da-DK')}`}
							        sliceLabel={d => `${ Math.round( Number(d.value) / d.pie_total * 100 ) }%`}
							        animate={true}
							        motionStiffness={90}
							        motionDamping={15}
							        defs={[
							            {
							                "id": "dots",
							                "type": "patternDots",
							                "background": "inherit",
							                "color": "rgba(255, 255, 255, 0.3)",
							                "size": 4,
							                "padding": 1,
							                "stagger": true
							            },
							            {
							                "id": "lines",
							                "type": "patternLines",
							                "background": "inherit",
							                "color": "rgba(255, 255, 255, 0.3)",
							                "rotation": -45,
							                "lineWidth": 6,
							                "spacing": 10
							            }
							        ]}
							        legends={[
					                    {
					                        anchor: 'right',
					                        direction: 'column',
					                        translateY: 0,
					                        translateX: 100,
					                        itemWidth: 100,
					                        itemHeight: 48,
					                        itemTextColor: '#999',
					                        symbolSize: 18,
					                        symbolShape: 'circle',
					                        fontSize: '18px',
					                        effects: [
					                            {
					                                on: 'hover',
					                                style: {
					                                    itemTextColor: '#000'
					                                }
					                            }
					                        ]
					                    }
					                ]}
							    />
							</div>

						)

					}

			</div>						        

			<div className="widget-v2-footer">
	        	<ul className="widget-footer-filters">
	        		

					{
						widgetState.source_filter === 'onlinepos' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('onlinepos')}>OnlinePOS</li>
						)
					}

					{
						widgetState.source_filter === 'heapsgo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('heapsgo')}>GRØD App</li>
						)
					}

					{
						widgetState.source_filter === 'orderyoyo' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						) : (
							<li className="widget-footer-filter" onClick={() => changeSourceFilter('orderyoyo')}>OrderYOYO</li>
						)
					}

					{
						widgetState.source_filter === 'wolt' ? (
							<li className="widget-footer-filter widget-footer-filter--active" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						) : (
						 	<li className="widget-footer-filter" onClick={() => changeSourceFilter('wolt')}>Wolt</li>
						 )
					}
	        	</ul>
	        	
	        </div>
	    </div>
	); 
}

export default PieProductCategory;