import React, { useState, useEffect } from "react";
import moment from 'moment';

import { ResponsiveLine } from '@nivo/line';
import channel_data from '../../../data/channel_data.json';

export const TimelineSocialMedia = (props) => {

	// Loading state
	const [widgetState, setWidgetState] = useState({
		metric_filter: 'post_reach',
		metric_label: '-',
		metrics: [],
		error: false,
		data: [],
		markers: []
	});


	// Set default filter
	useEffect(() => {
		// Filter posts (this would be equivalent to a database query)
		const channel = channel_data.channels_by_week.find(channel => channel.channel === props.channel && channel.profile === props.profile);

		if (!channel) {
			return;
		}

		// Setup metric options based on lates entry
		const newest_entry = channel.data[0];
		const metrics = newest_entry.metrics.map(m => { return { key: m.key, label: m.label }})
		const default_metric = metrics[0].key;

		// Update state
		setWidgetState(prevState => ({
			...prevState,
			metric_filter: default_metric,
		}))

	}, []);

	/**
	 * Get data
	 */
	useEffect(() => {
	
		// Filter posts (this would be equivalent to a database query)
		const channel = channel_data.channels_by_week.find(channel => channel.channel === props.channel && channel.profile === props.profile);

		if (!channel) {
			return;
		}

		// Setup metric options based on lates entry
		const newest_entry = channel.data[0];
		const metrics = newest_entry.metrics.map(m => {
			return {
				key: m.key,
				label: m.label
			}
		})

		// Find metric label
		const metric_label = metrics.find(m => m.key === widgetState.metric_filter);

		if (!metric_label) {
			return;
		}

		// Setup line
		let graph_data = {
			id: metric_label.label,
			color: "hsl(223, 70%, 54%)",
			data: []
		};

		// Add data points
		graph_data.data = channel.data.map(entry => {
			const date = entry.week;
			const metric = entry.metrics.find(m => m.key === widgetState.metric_filter) || 0;

			const obj = {
				x: date, 
				y: metric.value,
			}
			return obj;
		})

		// Reverse to get cronological order.. not sure why this is needed ?
		graph_data.data.reverse();


		// Setup markers
		let metric_target = -1;
		let markers = [];

		if (channel.targets) {
			metric_target = channel.targets.find(t => t.key === widgetState.metric_filter);	
		
			if (metric_target) {
				markers.push({
					axis: 'y',
					value: metric_target.value,
					lineStyle: { stroke: 'hsl(223, 70%, 54%)', strokeWidth: 1, strokeDasharray: "4 4", },
					legend: metric_target.label,
				})
			}
		}

		// Update state
		setWidgetState(prevState => ({
			...prevState,
			metrics: metrics,
			metric_label: metric_label.label,
			data: [graph_data],
			markers: markers,
		}))

	}, [widgetState.metric_filter]);

	/**
	 * This affects what data source should be displayed in the timeline. 
	 */
	const changeMetricFilter = (new_mode) =>  {
		setWidgetState(prevState => ({
			...prevState,
			metric_filter: new_mode
		}));
	}
		

	return (
		<div className="widget-v2">
	        <div className="widget-v2-header">
				Insights
	        </div>
			
			<div className="widget-v2-body">
					<div className="responsive-line-container">
						
						<ResponsiveLine
							data={widgetState.data}
							colors={d => d.color}
							margin={{ top: 50, right: 50, bottom: 70, left: 90 }}
							xScale={{ type: "point"}}
							yScale={{ type: "linear", stacked: false, min: 0, max: 'auto'}}
							curve="monotoneX"
							axisTop={null}
							axisRight={null}
							axisBottom={{
								orient: "bottom",
								tickSize: 5,
								tickPadding: 5,
								tickRotation: -40,
								legend: "",
								legendOffset: 36,
								legendPosition: "middle"
							}}
							axisLeft={{
								orient: "left",
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: "",
								legendOffset: -40,
								legendPosition: "middle",
								format: value => `${Number(value).toLocaleString('da-DK')}`,
							}}



							yFormat={value => {
								if (props.salaryPercentageMode) {
									return `${value}%`;
								} else {
									return `${Number(value).toLocaleString('da-DK')}`;
								}
								
							}}

			      	    	enablePointLabel={true}
			      	    	pointSize={6}
			      	    	pointBorderWidth={1}
			      	    	pointBorderColor={{
								from: 'color',
								modifiers: [['darker', 0.3]],
							}}
							pointLabelYOffset={-10}
							enableArea={true}			        
							areaOpacity={0.07}
							useMesh={false}
							enableSlices='x'		
							animate={true}
							motionStiffness={300}
							motionDamping={30}
							markers={widgetState.markers}
					        sliceTooltip={({ slice }) => {
			                    return (
			                        <div
			                            style={{
			                                background: 'white',
			                                padding: '9px 12px',
			                                border: '1px solid #ccc',
			                            }}
			                        >
			                            {slice.points.map((point, i) => (
			                                <div
			                                    key={i}
			                                    style={{
			                                        color: point.serieColor,
			                                        padding: '3px 0',
			                                    }}
			                                >
			                                    <strong>{point.serieId}</strong> {point.data.yFormatted} 
			                                    {
			                                    	point.data.posts && (
			                                    		<div>
			                                    		(Posts: {point.data.posts}, Stories: {point.data.stories})
			                                    		</div>
			                                    	)	
			                                    }
			                                    
			                                </div>
			                            ))}
			                        </div>
			                    )
			                }}
						/>

					</div>	
					<div className="widget-v2-body-legends">
        				<div className="widget-v2-body-legend" style={{color: 'hsl(223, 70%, 54%)'}}>
			            	<div className="widget-v2-body-legend-dot" style={{backgroundColor: 'hsl(223, 70%, 54%)'}}></div>
			            	<div className="widget-v2-body-legend-title">{widgetState.metric_label}</div>
			            </div>
		            </div>

			</div>						        


	        <div className="widget-v2-footer">
	        	<ul className="widget-footer-filters">
	        		{
						widgetState.metrics.map((m, i) => {
							return (
								m.key === widgetState.metric_filter ? (
									<li key={i} className="widget-footer-filter widget-footer-filter--active" onClick={() => changeMetricFilter(m.key)}>{m.label}</li>
								) : (
									<li key={i} className="widget-footer-filter" onClick={() => changeMetricFilter(m.key)}>{m.label}</li>
								)
							)
						})	
					}

				</ul>
	        	<div className="widget-timeline-v2-footer-settings">
		        		
	        	</div>
	        </div>

	    </div>
	)
};

export default TimelineSocialMedia;
